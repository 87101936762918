import styled from 'styled-components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

export const DBCardsParent = styled(Box)`
&& {
    display:flex;
    padding:2rem 2rem 1rem 2rem;
}`

export const DBCardGrid = styled(Grid)`
&& {

}`

export const DBCardItem = styled(Grid)`
&& {
    padding:1rem;
}`

export const CardBoxTotal = styled(Box)`
&& {
    height:6.5rem;
    background: #FAFAFA;
    box-shadow: 1px 3px 8px rgba(50, 50, 71, 0.17);
    border-radius: 10px;
    background-color:#391085;
}`

export const CardBoxChild = styled(Box)`
&& {
    height:6.3rem;
    background: #FAFAFA;
    box-shadow: 1px 3px 8px rgba(50, 50, 71, 0.17);
    border-radius: 10px;
    background-color:white;
    padding:0.5rem 0.8rem;
    display:flex;
    flex-direction:column;
}`

export const CardBoxExpected = styled(Box)`
&& {
    height:6.5rem;
    background: #FAFAFA;
    box-shadow: 1px 3px 8px rgba(50, 50, 71, 0.17);
    border-radius: 10px;
    background-color:#096DD9;
}`

export const CardBoxDepart = styled(Box)`
&& {
    height:6.5rem;
    background: #FAFAFA;
    box-shadow: 1px 3px 8px rgba(50, 50, 71, 0.17);
    border-radius: 10px;
    background-color:#FAAD14;
}`

export const CardBoxCurrent = styled(Box)`
&& {
    height:6.5rem;
    background: #FAFAFA;
    box-shadow: 1px 3px 8px rgba(50, 50, 71, 0.17);
    border-radius: 10px;
    background-color:#237804;
}`

export const CBHeader = styled(Box)`
&& {
    display:flex;
    justify-content:space-between;
}`

export const CBHeaderLeft = styled(Box)`
&& {
    display:flex;
    flex-direction:column;
}`

export const CBHeaderRight = styled(Box)`
&& {
    display:flex;
    background-image: url(styles/assets/cards.svg);
    height:30px;
    width:30px;
}`

export const CBHeaderName = styled(Typography)`
&& {
    color:#052C65;
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.25px;
}`

export const CBHeaderCount = styled(Typography)`
&& {
    color:#052C65;
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 1.3rem;
    letter-spacing: 0.25px;
}`

export const CBFooter = styled(Box)`
&& {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    padding-right:0.5rem;
}`

export const CBFooterName = styled(Box)`
&& {
    color:#052C65;
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 0.85rem;
    line-height: 16px;
    letter-spacing: 0.25px;
}`

export const CBFooterCount = styled(Box)`
&& {
    color:#052C65;
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 0.85rem;
    line-height: 16px;
    letter-spacing: 0.25px;
}`