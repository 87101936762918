import styled from "styled-components";
import Box from '@mui/material/Box';
import { Button } from "@mui/material";
import NavigationIcon from '@mui/icons-material/Navigation';

export const AGGridWrapperBox = styled(Box)`
&& {
    height:100%;
}`

export const ActionButtonParent = styled(Box)`
&& {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    
}`

export const ActionButton = styled(Button)`
&& {

}`

export const ActionButtonsDrpDown = styled(Box)`
&& {

}`

export const ActionButtonItem = styled(Box)`
&& {
    
}`

export const ActionIcon = styled(NavigationIcon)`
&& {
    font-size: 1.25rem;
    transform: rotate(90deg);
    margin-left: 10px;
}`