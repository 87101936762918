import React, { useState, useEffect , useRef , forwardRef, useImperativeHandle } from "react";
import dayjs from 'dayjs';
import { useSelector } from "react-redux";

// Mui and Sytle js
import * as HSStyles from '../../../styles/HotelInfo/hourlyStayStyle';
import * as RuleStyle from '../../../styles/HotelInfo/hotelRulesStyle';

//Internal Imports

const HourlyStayInfo  = forwardRef((props,ref) => {


    const singleTarrif3 = useRef(null);
    const doubleTarrif3 = useRef();
    const tripleTarrif3 = useRef();
    const singleTarrif6 = useRef();
    const doubleTarrif6 = useRef();
    const tripleTarrif6 = useRef();
    const singleTarrif9 = useRef();
    const doubleTarrif9 = useRef();
    const tripleTarrif9 = useRef();

    const [hasHourlyStay, setHourlyStay] = useState(false);

    const [ tarrifObj, setTarrifObj] = useState({});

    const [ errorObj, setErrorObj] = useState({});

    const [ showError , setShowError] = useState(false);

    const roomDetails = useSelector(state => state.hotelDetails.hotelInfo.roomDetails);

    const getCheckInTIme = () => {

        let key = Object.keys(roomDetails)[0];
        let roominfo = roomDetails[key].attributes;
        let time = null;
        if(roominfo.hourlyStayObj?.hasHourlyStay) {
            time = roominfo.hourlyStayObj['check-in'];
        }

        const defaultNoonTime = new Date();
        defaultNoonTime.setHours(10);
        defaultNoonTime.setMinutes(0);
        defaultNoonTime.setSeconds(0);
        defaultNoonTime.setMilliseconds(0);
        if(time) 
            return dayjs(time);
        else 
            return dayjs(defaultNoonTime);
    }

    const getCheckOutTIme = () => {

        let key = Object.keys(roomDetails)[0];
        let roominfo = roomDetails[key].attributes;
        let time = null;
        if(roominfo.hourlyStayObj?.hasHourlyStay) {
            time = roominfo.hourlyStayObj['check-out'];
        }

        const defaultNoonTime = new Date();
        defaultNoonTime.setHours(19);
        defaultNoonTime.setMinutes(0);
        defaultNoonTime.setSeconds(0);
        defaultNoonTime.setMilliseconds(0);
        if(time) 
            return dayjs(time);
        else 
            return dayjs(defaultNoonTime);
    }

    useImperativeHandle(ref, () => ({
        getTarrifObj : () => {
            return {
                tarrifObj,
                hasHourlyStay
            }
        },
        setErrorObj: (errorOjb) => {
            let errLength = Object.keys(errorOjb).length;
            setErrorObj((prevObj) => ({
                ...prevObj,
                errorOjb
            }));
            setShowError(errLength > 0 ? true : false);
        }
    }))

    const toggleHandler = (checked,name) => {
        const newObj = { ...tarrifObj };
        switch(name) {
            case 'three':
                newObj['stay3'] = checked;
                break;
            case 'six':
                newObj['stay6'] = checked;
                break;
            case 'nine':
                newObj['stay9'] = checked;
                break;
        }
        if(!checked) {
            switch(name) {
                case 'three':
                    delete newObj['tarrif3'];
                    break;
                case 'six':
                    delete newObj['tarrif6'];
                    break;
                case 'nine':
                    delete newObj['tarrif9'];
                    break;
            }
        }
        setTarrifObj((prevObj) => ({
            ...prevObj,
            ...newObj
        }));
    }

    const onChange = (event,key) => {
        let newObj = { ...tarrifObj };
        if(!newObj[key]) {
            newObj[key] = {};
        }
        newObj[key][event.target.name] = event.target.value;
        setTarrifObj((prevObj) => ({
            ...prevObj,
            ...newObj
        }));
    }

    useEffect(() => {
        if(singleTarrif3.current)
            singleTarrif3.current.value = tarrifObj.tarrif3?.singleTarrif || '';
        if(doubleTarrif3.current)
            doubleTarrif3.current.value = tarrifObj.tarrif3?.doubleTarrif || '';
        if(tripleTarrif3.current)
            tripleTarrif3.current.value = tarrifObj.tarrif3?.tripleTarrif || '';
        if(singleTarrif6.current)
            singleTarrif6.current.value = tarrifObj.tarrif6?.singleTarrif || '';
        if(doubleTarrif6.current)
            doubleTarrif6.current.value = tarrifObj.tarrif6?.doubleTarrif || '';
        if(tripleTarrif6.current)
            tripleTarrif6.current.value = tarrifObj.tarrif6?.tripleTarrif || '';
        if(singleTarrif9.current)
            singleTarrif9.current.value = tarrifObj.tarrif9?.singleTarrif || '';
        if(doubleTarrif9.current)
            doubleTarrif9.current.value = tarrifObj.tarrif9?.doubleTarrif || '';
        if(tripleTarrif9.current)
            tripleTarrif9.current.value = tarrifObj.tarrif9?.tripleTarrif || '';
        return () => {

        }
    },[tarrifObj])

    useEffect(() => {
        let key = Object.keys(roomDetails)[0];
        let roominfo = roomDetails[key].attributes;
        if(roominfo.hourlyStayObj?.hasHourlyStay) {
            let tarrifObj = roominfo.hourlyStayObj.tarrifObj;
            let newTarrifObj = {}
            if(tarrifObj) {
                if(tarrifObj.stay3) {
                    newTarrifObj.tarrif3 = {};
                    newTarrifObj.stay3 = tarrifObj.stay3;
                    newTarrifObj.tarrif3.singleTarrif = tarrifObj.tarrif3.singleTarrif || ''
                    newTarrifObj.tarrif3.doubleTarrif = tarrifObj.tarrif3.doubleTarrif || ''
                    newTarrifObj.tarrif3.tripleTarrif = tarrifObj.tarrif3.tripleTarrif || ''
                }
                if(tarrifObj.stay6) {
                    newTarrifObj.tarrif6 = {};
                    newTarrifObj.stay6 = tarrifObj.stay6;
                    newTarrifObj.tarrif6.singleTarrif = tarrifObj.tarrif6.singleTarrif || ''
                    newTarrifObj.tarrif6.doubleTarrif = tarrifObj.tarrif6.doubleTarrif || ''
                    newTarrifObj.tarrif6.tripleTarrif = tarrifObj.tarrif6.tripleTarrif || ''
                }
                if(tarrifObj.stay9) {
                    newTarrifObj.tarrif9 = {};
                    newTarrifObj.stay9 = tarrifObj.stay9;
                    newTarrifObj.tarrif9.singleTarrif = tarrifObj.tarrif9.singleTarrif || ''
                    newTarrifObj.tarrif9.doubleTarrif = tarrifObj.tarrif9.doubleTarrif || ''
                    newTarrifObj.tarrif9.tripleTarrif = tarrifObj.tarrif9.tripleTarrif || ''
                }
                setTarrifObj((prevObj) => ({
                    ...prevObj,
                    ...newTarrifObj
                }))
                setHourlyStay(roominfo.hourlyStayObj?.hasHourlyStay);
            }
        }
        return () => {
            if(singleTarrif3.current)
                singleTarrif3.current.removeEventListener('keydown', onkeydown);
            if(doubleTarrif3.current)
                doubleTarrif3.current.removeEventListener('keydown', onkeydown);
            if(tripleTarrif3.current)
                tripleTarrif3.current.removeEventListener('keydown', onkeydown);
            if(singleTarrif6.current)
                singleTarrif6.current.removeEventListener('keydown', onkeydown);
            if(doubleTarrif6.current)
                doubleTarrif6.current.removeEventListener('keydown', onkeydown);
            if(tripleTarrif6.current)
                tripleTarrif6.current.removeEventListener('keydown', onkeydown);
            if(singleTarrif9.current)
                singleTarrif9.current.removeEventListener('keydown', onkeydown);
            if(doubleTarrif9.current)
                doubleTarrif9.current.removeEventListener('keydown', onkeydown);
            if(tripleTarrif9.current)
                tripleTarrif9.current.removeEventListener('keydown', onkeydown);
        }
    },[])

    const checkIfError = (name) => {
        if(showError && (name.current.value.length == 0))
            return {
                error:true
            }
    }

    const helperText = name => {
        if(showError && (name.current.value.length == 0))
            return errorObj[name];
    }

    const hourlyProp = () => {
        if(hasHourlyStay) {
            return {
                hasHourlyStay:true
            }
        }
        else {
            return {
                noHourlyStay:true
            }
        }
    }

    onkeydown = (event) => {
        if(event.target.className.indexOf('keydownclass') > -1) {
            if(event.target.value.indexOf('.') > -1 && (event.keyCode === 190 || event.keyCode === 46)){
                event.preventDefault(); // Prevent the keypress
                return false;
            }
            let keyCode = event.keyCode || event.which;
            if (
                (keyCode >= 48 && keyCode <= 57) || // Standard numeric keys (0-9)
                (keyCode >= 96 && keyCode <= 105) || // Numpad numeric keys (0-9)
                keyCode === 46 || // Decimal point
                keyCode === 190 || // Decimal point
                keyCode === 8 || // Backspace
                keyCode === 9 || // Tab
                keyCode === 37 || // Left arrow
                keyCode === 39 || // Right arrow
                keyCode === 36 || // Home
                keyCode === 35 // End
            ) {
                return true; // Allow the keypress
            } else {
                event.preventDefault(); // Prevent the keypress
                return false;
            }
        }
    }

    const timeChangeHandler = (event) => {
        
    }

    return(
        <>
            <HSStyles.HourlyInfoBox>
                <HSStyles.BreakfastRow>
                    <HSStyles.BreakfastLabel>Do you offer hourly stay?</HSStyles.BreakfastLabel>
                    <HSStyles.BreakfastButtonsBox>
                        <HSStyles.BreakfastYesButton onClick = { () => { setHourlyStay(true)} } { ...hourlyProp() }  variant = 'contained'>Yes</HSStyles.BreakfastYesButton>
                        <HSStyles.BreakfastNoButton onClick = { () => { setHourlyStay(false)} } { ...hourlyProp() }  variant = 'contained'>No</HSStyles.BreakfastNoButton>
                    </HSStyles.BreakfastButtonsBox>
                </HSStyles.BreakfastRow>
                { hasHourlyStay &&
                    <HSStyles.CheckInOutTime>
                        <HSStyles.AvailLable>Select  availability for hourly booking</HSStyles.AvailLable>
                        <HSStyles.TimePickerParent>
                            <RuleStyle.CheckTimePicker onChange={ timeChangeHandler } views={['hours']} ampm = 'true' value= { getCheckInTIme() } size = 'small'></RuleStyle.CheckTimePicker>
                            <RuleStyle.CheckTimePicker onChange={ timeChangeHandler } views={['hours']} ampm = 'true' value= { getCheckOutTIme()  } 
                                size = 'small'> 
                            </RuleStyle.CheckTimePicker>
                        </HSStyles.TimePickerParent>
                    </HSStyles.CheckInOutTime>
                }
                { hasHourlyStay &&
                    <HSStyles.Note>
                       Note : Price including taxes.
                    </HSStyles.Note>
                }
                { hasHourlyStay &&
                    <HSStyles.TarrifParentBox container spacing={2}>
                        <HSStyles.TarrifBox3 item lg = {4} xs = {12}>
                            <HSStyles.Tarrif3Parent>
                                <HSStyles.RuleIDBox>
                                    <HSStyles.RuleLabel > Do you offer upto 3 hour stay ?</HSStyles.RuleLabel>
                                    <HSStyles.RuleSwitch onChange = {(checked) =>  toggleHandler(checked,'three') } checked = { tarrifObj['stay3'] || false }  ></HSStyles.RuleSwitch>
                                </HSStyles.RuleIDBox>
                                { tarrifObj['stay3'] && 
                                    <HSStyles.TariffInputParent>
                                        <HSStyles.RoomTarrifInput 
                                            size = 'small'
                                            { ...checkIfError(singleTarrif3)}
                                            helperText = { helperText(singleTarrif3)}
                                            label="Room Tariff Single Occupancy"
                                            name='singleTarrif'
                                            onBlur = { (event) => { onChange(event,'tarrif3') }}
                                            inputRef = { singleTarrif3}
                                            onKeyDown={onkeydown}
                                            className="keydownclass"
                                            minValue
                                            InputProps={{
                                                step: 'any'
                                            }}
                                        >
                                        </HSStyles.RoomTarrifInput>
                                        <HSStyles.RoomTarrifInput 
                                            size = 'small'
                                            { ...checkIfError(doubleTarrif3)}
                                            helperText = { helperText(doubleTarrif3)}
                                            label="Room Tariff Double Occupancy"
                                            name='doubleTarrif'
                                            onBlur = { (event) => { onChange(event,'tarrif3') }}
                                            inputRef = { doubleTarrif3  }
                                            minValue
                                            onKeyDown={onkeydown}
                                            className="keydownclass"
                                            InputProps={{
                                                inputProps : {
                                                    min:0,
                                                    step:0
                                                }
                                            }}
                                        >
                                        </HSStyles.RoomTarrifInput>
                                        <HSStyles.RoomTarrifInput 
                                            size = 'small'
                                            label="Room Tariff Triple Occupancy"
                                            { ...checkIfError(tripleTarrif3)}
                                            helperText = { helperText(tripleTarrif3)}
                                            name='tripleTarrif'
                                            onBlur = { (event) => { onChange(event,'tarrif3') }}
                                            inputRef = { tripleTarrif3  }
                                            minValue
                                            onKeyDown={onkeydown}
                                            className="keydownclass"
                                            InputProps={{
                                                inputProps : {
                                                    min:0,
                                                    step:0
                                                }
                                            }}
                                        >
                                        </HSStyles.RoomTarrifInput>
                                    </HSStyles.TariffInputParent>
                                }
                            </HSStyles.Tarrif3Parent>
                        </HSStyles.TarrifBox3>
                        <HSStyles.TarrifBox6 item lg = {4} xs = {12}>
                            <HSStyles.Tarrif6Parent>
                                <HSStyles.RuleIDBox>
                                    <HSStyles.RuleLabel > Do you offer 3-6 hour stay ?</HSStyles.RuleLabel>
                                    <HSStyles.RuleSwitch onChange = {(checked) =>  toggleHandler(checked,'six') } checked = { tarrifObj['stay6'] || false } ></HSStyles.RuleSwitch>
                                </HSStyles.RuleIDBox>
                                { tarrifObj['stay6'] && 
                                    <HSStyles.TariffInputParent>
                                        <HSStyles.RoomTarrifInput 
                                            size = 'small'
                                            label="Room Tariff Single Occupancy"
                                            name='singleTarrif'
                                            { ...checkIfError(singleTarrif6)}
                                            helperText = { helperText(singleTarrif6)}
                                            inputRef = { singleTarrif6 }
                                            onBlur = { (event) => { onChange(event,'tarrif6')  }}
                                            onKeyDown={onkeydown}
                                            className="keydownclass"
                                            minValue
                                            InputProps={{
                                                inputProps : {
                                                    min:0,
                                                    step:0
                                                }
                                            }}
                                        >
                                        </HSStyles.RoomTarrifInput>
                                        <HSStyles.RoomTarrifInput 
                                            size = 'small'
                                            label="Room Tariff Double Occupancy"
                                            name='doubleTarrif'
                                            { ...checkIfError(doubleTarrif6)}
                                            helperText = { helperText(doubleTarrif6)}
                                            inputRef = { doubleTarrif6 }
                                            onBlur = { (event) => { onChange(event,'tarrif6')  }}
                                            onKeyDown={onkeydown}
                                            className="keydownclass"
                                            minValue
                                            InputProps={{
                                                inputProps : {
                                                    min:0,
                                                    step:0
                                                }
                                            }}
                                        >
                                        </HSStyles.RoomTarrifInput>
                                        <HSStyles.RoomTarrifInput 
                                            size = 'small'
                                            label="Room Tariff Triple Occupancy"
                                            name='tripleTarrif'
                                            { ...checkIfError(tripleTarrif6)}
                                            helperText = { helperText(tripleTarrif6)}
                                            inputRef = { tripleTarrif6 }
                                            onBlur = { (event) => { onChange(event,'tarrif6')  }}
                                            onKeyDown={onkeydown}
                                            className="keydownclass"
                                            minValue
                                            InputProps={{
                                                inputProps : {
                                                    min:0,
                                                    step:0
                                                }
                                            }}
                                        >
                                        </HSStyles.RoomTarrifInput>
                                    </HSStyles.TariffInputParent>
                                }
                            </HSStyles.Tarrif6Parent>
                        </HSStyles.TarrifBox6>
                        <HSStyles.TarrifBox9 item lg = {4} xs = {12} >
                            <HSStyles.Tarrif9Parent>
                                <HSStyles.RuleIDBox>
                                    <HSStyles.RuleLabel > Do you offer 6-9 hour stay ?</HSStyles.RuleLabel>
                                    <HSStyles.RuleSwitch onChange = {(checked) =>  toggleHandler(checked,'nine') }checked = { tarrifObj['stay9'] || false }  ></HSStyles.RuleSwitch>
                                </HSStyles.RuleIDBox>
                                { tarrifObj['stay9'] && 
                                    <HSStyles.TariffInputParent>
                                        <HSStyles.RoomTarrifInput 
                                            size = 'small'
                                            { ...checkIfError(singleTarrif9)}
                                            helperText = { helperText(singleTarrif9)}
                                            label="Room Tariff Single Occupancy"
                                            name='singleTarrif'
                                            inputRef = { singleTarrif9 }
                                            onBlur = { (event) => { onChange(event,'tarrif9') }}
                                            onKeyDown={onkeydown}
                                            className="keydownclass"
                                            minValue
                                            InputProps={{
                                                inputProps : {
                                                    min:0,
                                                    step:0
                                                }
                                            }}
                                        >
                                        </HSStyles.RoomTarrifInput>
                                        <HSStyles.RoomTarrifInput 
                                            size = 'small'
                                            { ...checkIfError(doubleTarrif9)}
                                            helperText = { helperText(doubleTarrif9)}
                                            label="Room Tariff Double Occupancy"
                                            name='doubleTarrif'
                                            inputRef = { doubleTarrif9 }
                                            onBlur = { (event) => { onChange(event,'tarrif9') }}
                                            onKeyDown={onkeydown}
                                            className="keydownclass"
                                            minValue
                                            InputProps={{
                                                inputProps : {
                                                    min:0,
                                                    step:0
                                                }
                                            }}
                                        >
                                        </HSStyles.RoomTarrifInput>
                                        <HSStyles.RoomTarrifInput 
                                            size = 'small'
                                            { ...checkIfError(tripleTarrif9)}
                                            helperText = { helperText(tripleTarrif9)}
                                            label="Room Tariff Triple Occupancy"
                                            name='tripleTarrif'
                                            inputRef = { tripleTarrif9 }
                                            onBlur = { (event) => { onChange(event,'tarrif9') }}
                                            onKeyDown={onkeydown}
                                            className="keydownclass"
                                            minValue
                                            InputProps={{
                                                inputProps : {
                                                    min:0,
                                                    step:0
                                                }
                                            }}
                                        >
                                        </HSStyles.RoomTarrifInput>
                                    </HSStyles.TariffInputParent>
                                }
                            </HSStyles.Tarrif9Parent>
                        </HSStyles.TarrifBox9>
                    </HSStyles.TarrifParentBox>
                }
            </HSStyles.HourlyInfoBox>
        </>
    )
});

export default HourlyStayInfo;