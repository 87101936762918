import styled from 'styled-components'
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Autocomplete, Typography } from "@mui/material";
import { Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Switch } from 'antd';

export const RoomInfoBoxParent = styled(Box)`
&& {
    display:flex;
    @media (max-width: 576px) {
        flex-direction: column;
    }
}`

export const RoomInfoBox = styled(Box)`
&& {
    display:flex;
    @media (max-width: 576px) {
        flex-direction: column;
    }
}`

export const RoomInfoParent = styled(Box)`
&& {
    display:flex;
    flex-direction:column;
    flex-basis:50%;
}`

export const DetailsBox = styled(Box)`
&& {
    margin:1rem 10rem;
    flex-grow:1;
    border:1px solid #CFE2FF;
    border-radius:8px;
}`

export const UserSalution = styled(Box)`
&& {
    margin:0 10rem;
    font-family:'Red Hat Display';
    & p {
        margin:0px;
    }
}`

export const UserWelcome = styled.p`
&& {
    color : #084298;
    font-size:1.20rem;
    margin-top:1rem !important;
    font-weight:700;
}`

export const ProperyInfoMsg = styled.p`
&& {
    color:#343A40;
    font-size:1rem;
    margin-top:0.5rem !important;
}`

export const ProperyInfoChangeMsg = styled.p`
&& {
    color:#FD7E14;
    font-size:0.8rem;
    margin-top:0.5rem !important;
}`

export const DetailHeader = styled(Box)`
&& {
    height:50px;
    background-color:#EFF3FA;
    color:#084298;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display:flex;
    align-items:center;
    padding-left:20px;
    font-weight: 600;
}
`

export const DetailRow = styled(Box)`
&& {
    display:flex;
    margin:1rem 0;
}`

export const DetialColumn = styled(Box)`
&& {
    padding-left:2rem;
    flex-grow:1;
}`

export const RoomButtonBar = styled(Box)`
&& {
    flex-grow:1;
    right:-50px;
    display: flex;
    justify-content: center;
    @media(max-width:526px) {
        position:static;
    }
}`

export const DetailColumnLabel = styled(Box)`
&& {
    display: flex;
    font-size: 1rem;
    font-weight:500;
    height: 34px;
    align-items: center;
    color:black;
    & p {
        color:red;
        font-size: 0.8rem;
    }
    & span {
        font-size:0.8rem;
    };
    @media (max-width: 576px) {
        height:auto;
        margin-bottom:0.5rem;
    }

}`

export const InputField = styled(TextField)`
&& {
    width: 90%;
    padding: 0px;
    & textarea {
        padding:8px;
        color:#718BAE;
    };
    & .MuiInputBase-input {
        color:#4D4C4C;
    };
}`

export const Dropdown = styled(Autocomplete)`
&& {
    width: 90%;
    padding: 0px;
    & .MuiInputBase-input {
        color:#4D4C4C;
    };
}`

export const MultiLIneInputField = styled(TextField)`
&& {
    width: 85%;
    padding: 10px;
    & input {
        padding:8px;
        color:#718BAE;
    }
}`



export const LocationLabel = styled(Box)`
&& {
    color:#084298;
    font-size:1rem;
    font-weight:600;
    padding-left:2rem;
    margin-top:1rem;
}`

export const SaveFooter = styled(Box)`
&& {
    background-color:#F9F9F9;
    display:flex;
    justify-content:space-between;
    height:70px;
    margin-top:2rem;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-items:center;
    padding:0 3rem 0 2.2rem;
}`

export const SaveButton = styled(Button)`
&& {
    text-transform:initial;
    height:35px;
}`

export const BackButton = styled(Box)`
&& {
    cursor:pointer;
    display:flex;
    align-items:center;
    height:27px;
    font-family:'Red Hat Display';
    & p{
        color: #052C65;
        font-size:0.8rem;
        padding-left:10px;
    }
    & svg {
        font-size:1rem;
    }
    border:1px solid #052C65;
    border-radius:6px;
    padding:0 15px 0 8px;
}`

export const RoomImageBox = styled(Box)`
&& {
    display:flex;
    flex-grow:1;
    flex-direction:column
}`

export const RoomSizeBox = styled(Box)`
&& {
    display:flex;
    width:90%;

    & .MuiOutlinedInput-root {
        flex-basis:35%;
        margin-right:10px;
    }
}`

export const AddRoom = styled(Button)`
&& {
    text-transform:initial;
    height:30px;
    padding:0 1.5rem;
    font-weight:500;
    font-family: Red Hat Display;
    background:black;
    &:hover{
        background:black!important;
    }
}`

export const CancelRoom = styled(Button)`
&& {
    margin-right:2rem;
    text-transform:initial;
    height:30px;
    padding:0 1.5rem;
    font-weight:700;
    color:rgba(0, 0, 0, 0.26);
    background-color:rgba(0, 0, 0, 0.12);
    &:hover {
        color:rgba(0, 0, 0, 0.26);
        background-color:rgba(0, 0, 0, 0.12);
    }
}`

export const AddedRoomsLabel = styled(Typography)`
&& {
    color:black;
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    letter-spacing: 0.01em;
    margin-top: 0.5rem;
    margin-left: 2rem;
}`

export const ARGrid = styled(Grid)`
&& {

}`

export const ARGridItem = styled(Grid)`
&& {

}`

export const GridItemBox = styled(Box)`
&& {
    background: #FFFFFF;
    box-shadow: 1px 3px 8px rgb(50 50 71 / 17%);
    border-radius: 10px;
    display: flex;
    height: 45px;
    align-items: center;
}`

export const ARName = styled(Typography)`
&& {
    flex-grow:1;
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 0.85rem;
    color:black;
    padding-left:1rem;
}`

export const AREditIcon = styled(EditIcon)`
&& {
    margin: 0 0.5rem;
    cursor:pointer;
    color:black;
}`

export const ARDeleteIcon = styled(DeleteIcon)`
&& {
    margin: 0 0.5rem;
    cursor:pointer;
    color:#DC3545;
}`

export const ARGridContainer = styled(Box)`
&& {
    margin:1rem 2rem 0 2rem;
}`

export const AddedRoomsContainer = styled(Box)`
&& {

}`

/* Meal Info start */
export const MealInfoBody = styled(Box)`
&& {
    display:flex;
    flex-grow:1;
    & .Mui-disabled .MuiOutlinedInput-notchedOutline {
        border-color:rgba(0, 0, 0, 0.10);
    };
    & .Mui-disabled {
        background-color:#bfbebe;
    };
    @media(max-width:526px) {
        flex-direction:column;
    };
    & .MuiFormLabel-root {
        color: rgba(0, 0, 0, 0.6) !important;
    }
}`

export const Note = styled(Typography)`{
    padding-bottom:2rem;
    padding-left:20px;
}`

export const MealInfoHeader = styled(Typography)`
&& {
    margin:1rem;
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #343A40;
}`

export const MealInfoData = styled(Box)`
&& {
    width:50%;
     @media (max-width: 576px) {
        width:100%;
    }
}`

export const BreakfastParent = styled(Box)`
&& {
    width: 50%;
    padding-left: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-bottom:4rem;
    @media(max-width:526px) {
        width:100%;
        padding-bottom:2rem;
    }
}`

export const LunchParent = styled(Box)`
&& {
    width: 50%;
    padding-left: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-bottom:4rem;
    border-left: 1px solid rgba(77, 76, 76, 0.5);
    @media(max-width:526px) {
        width:100%;
        padding-bottom:2rem;
    }
}`

export const BreakfastRowComp = styled(Box)`
&& {
    display: flex;
    align-items: center;
    gap: 3rem;
    @media(max-width:526px) {
        width:100%;
        align-items:flex-start;
        gap:1rem;
    }
}`

export const BreakfastRow = styled(Box)`
&& {
    display: flex;
    align-items: center;
    gap: 3rem;
    @media(max-width:526px) {
        flex-direction:column;
        width:100%;
        align-items:flex-start;
        gap:1rem;
    }
}`

export const BreakfastLabel = styled(Box)`
&& {
    color: var(--neutral-100, #20102B);
    font-family: Red Hat Display;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}`

export const BreakfastButtonsBox = styled(Box)`
&& {
    display:flex;
    gap:1rem;
}`

export const LunchYesButton = styled(Button)`
&& {
    border-radius: 5px;
    background: ${props => {
        if(props.hasBreakfast)
            return '#141414';
        else 
            return 'white';
    }};
    color: ${props => {
        if(props.hasBreakfast)
            return 'white';
        else 
            return '#141414';
    }};
    font-family: Red Hat Display;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Red Hat Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    &:hover {
        background: ${props => {
            if(props.hasBreakfast)
                return '#141414';
            else 
                return 'white';
        }};
    }
}`

export const LunchNoButton = styled(Button)`
&& {
    border-radius: 5px;
    border: 1px solid var(--black-main, #141414);
    background: ${props => {
        if(props.noBreakfast)
            return '#141414';
        else 
            return 'white';
    }};
    color: ${props => {
        if(!props.noBreakfast)
            return '#141414';
        else 
            return 'white';
    }};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Red Hat Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    &:hover {
        background: ${props => {
            if(props.noBreakfast)
                return '#141414';
            else 
                return 'white';
        }};
    }
}`

export const BreakfastYesButton = styled(Button)`
&& {
    border-radius: 5px;
    background: ${props => {
        if(props.hasBreakfast)
            return '#141414';
        else 
            return 'white';
    }};
    color: ${props => {
        if(props.hasBreakfast)
            return 'white';
        else 
            return '#141414';
    }};
    font-family: Red Hat Display;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Red Hat Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    &:hover {
        background: ${props => {
            if(props.hasBreakfast)
                return '#141414';
            else 
                return 'white';
        }};
    }
}`

export const BreakfastNoButton = styled(Button)`
&& {
    border-radius: 5px;
    border: 1px solid var(--black-main, #141414);
    background: ${props => {
        if(props.noBreakfast)
            return '#141414';
        else 
            return 'white';
    }};
    color: ${props => {
        if(!props.noBreakfast)
            return '#141414';
        else 
            return 'white';
    }};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Red Hat Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    &:hover {
        background: ${props => {
            if(props.noBreakfast)
                return '#141414';
            else 
                return 'white';
        }};
    }
}`

export const BreakfastLabelGray = styled(Box)`
&& {
    color: var(--black-main, #141414);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Red Hat Display;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}`

export const BFSwitch = styled(Switch)`
&& {
    &.ant-switch-checked {
        background-color: #1DC9A0; /* Change this to your desired color */
    };
    &.ant-switch-checked:hover {
        background-color: #1DC9A0; /* Change this to your desired color */
    }
}`

export const BreakfastInput = styled(TextField)`
&& {
    border-radius: 4px;
    background: #FFF;
    width:60%;
    @media(max-width:526px) {
        width:90%;
    }
}`
/* Meal Info end */