import React, { useState } from 'react';
import  { MenuProps, Typography } from 'antd';
import { Button, Dropdown } from 'antd';
import { Fab } from '@mui/material';

//MUI and style imports 
import * as AGGridStyle from '../../styles/DataGrid/aggridStyle';

//Internal Imports

const ActionButtonRenderer = (props) => {

    const handleOptionClick = (option) => {
        props.onClick(option,props.data.generated_booking_id);
    };


    const items = props.options.map((option, index) => {
      return {
        key:option.id,
        label: (
          <Typography onClick = { () => handleOptionClick(option) } >{ option.label}</Typography>
        )  
      }
    })

    return (
        <AGGridStyle.ActionButtonParent>
          <Dropdown menu={{ items }} placement="bottomLeft" arrow={{ pointAtCenter: true }}>
            {/* <Button>bottomLeft</Button> */}
            <Fab variant="extended" size="small">
              <AGGridStyle.ActionIcon sx={{mr:1}}></AGGridStyle.ActionIcon>
            </Fab>
          </Dropdown>
            {/* <AGGridStyle.ActionButton onClick={() => setIsOpen(!isOpen)}>Action</AGGridStyle.ActionButton> */}
            
            {/* {isOpen && (
               
                <AGGridStyle.ActionButtonsDrpDown className="dropdown">
                    {options.map((option, index) => (
                        <AGGridStyle.ActionButtonItem key={index} onClick={() => handleOptionClick(option)}>
                            {option.label}
                        </AGGridStyle.ActionButtonItem>
                    ))}
                </AGGridStyle.ActionButtonsDrpDown>
            )} */}
        </AGGridStyle.ActionButtonParent>
    );
}

export default ActionButtonRenderer;