import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';


import * as CommonStyle from '../../styles/HotelInfo/commonStyle';
import Portal from '@mui/material/Portal'
//Internal Imports
import PropertyDetails from "./PropertyDetails/PropertyDetail";
import Header from '../Header';
import ContactDetails from './ContactDetails/ContactDetails';
import RoomDetails from './RoomDetails/RoomDetails';
import AmenitiesDetails from './AmenitiesDetails/AmenititesDetails';
import { OWNER } from '../../config/constants';
import RoomMealInfo from './RoomMeals/RoomMealInfo';
import HotelRules from './HotelRules/HotelRules';
import GetStarted from './GetStarted';

import API from '../../api/index';
import { UtilsAPI } from '../../api/index';

import { 
    setRefData
} from '../../slices/hotelDetails'
import Confirmation from './ConfirmationPage';
import SetUpMessagePage from './SetUpMessagePage';
import HourlyStay from './HourlyStay/HourlyStay';
import SignUp from '../login/SignUp';

const HotelInfo = () => {
    
    const [ renderStatus, setRenderStatus] = useState(false);
    const [showLoader,setShowLoader] = useState(false);

    const hotelId = useSelector(state => state.hotelDetails.hotelInfo.hotelId);
    const hotelCategoryMaster = useSelector(state => state.hotelDetails.hotelInfo.hotelCategoryMaster);
    const dispatch = useDispatch();

    const getStatesList = (states) => {
        let statesList = [];
        states.map(state => {
            statesList.push(state.state);
        })
        return statesList;
    }

    const getCitiesList = (states) => {
        let statesList = {};
        states.map(state => {
            statesList[state.state] = state.districts;
        })
        return statesList;
    }

    useEffect(() => {

        if(hotelId != -1) {
            let promiseArr = [];
            setShowLoader(true);
            promiseArr.push(API.get(`/getHotelOwnershipTypes`));
            promiseArr.push(API.get(`/getHotelCategoryTypes`));
            promiseArr.push(API.get(`/getHotelStarCategoryTypes`));

            promiseArr.push(API.get(`/getHotelDetailsById?hotel_id=${hotelId}`));
            promiseArr.push(API.get(`/getHotelPhotosByHotelId?hotel_id=${hotelId}`));
            promiseArr.push(API.get(`/getHotelFacilitiesByHotelId?hotel_id=${hotelId}`));
            promiseArr.push(API.get(`/getHotelRulesByHotelId?hotel_id=${hotelId}`));
            promiseArr.push(API.get(`/getHotelFacilitiesByHotelId?hotel_id=${hotelId}`));
            promiseArr.push(API.get(`/getAllFacilities`));

            promiseArr.push(API.get(`/getHotelRoomsByHotelId?hotel_id=${hotelId}`));
            promiseArr.push(API.get(`/getHotelRoomCategoriesByHotelId?hotel_id=${hotelId}`));
            promiseArr.push(API.get(`/getHotelRoomPhotosUsingHotelId?hotel_id=${hotelId}`));
            promiseArr.push(API.get(`/getHotelRoomNumbersUsingHotelId?hotel_id=${hotelId}`));

            promiseArr.push(API.get(`/getAllBedTypes`));
            promiseArr.push(API.get(`getAllDesignationTypes`));
            promiseArr.push(API.get(`/getAllEmployeesForHotel?hotel_id=${hotelId}`));
            promiseArr.push(API.get(`/getContactDetailForHotel?hotel_id=${hotelId}`));
            promiseArr.push(UtilsAPI.get(`/getStatesJSON`));
            promiseArr.push(API.get(`/getAllHotelRules`));
            promiseArr.push(API.get(`/getHotelRulesByHotelId?hotel_id=${hotelId}`))
            promiseArr.push(API.get(`/getMealInfoForHotel?hotel_id=${hotelId}`))

            Promise.all(promiseArr).then((resp) => {
                const roomDetails = generateRoomDetails(resp);
                let propertyDetails = resp[3].data.data[0] || {
                    hotel_nick_name:'',
                    name:'',
                    gstin_number:'',
                    category_type_id:-1,
                    star_category_type_id:-1
                };
                let contactNo = []
                resp[16].data.data.map(contact => {
                    if(contact.contact_type == 'Phone')
                        contactNo.push(contact.contact_value);
                })
                propertyDetails.phoneNumber = contactNo.length > 0 ? contactNo.join(',') : '';
                propertyDetails.hotel_name = propertyDetails.name || '';
                propertyDetails.registered_company_name = propertyDetails.registered_company_name || '';
                if(propertyDetails.nick_name == '{{nick_name}}')
                    propertyDetails.nick_name = '';
                if(propertyDetails.registered_company_name == '{{registered_company_name}}')
                    propertyDetails.registered_company_name = '';

                dispatch(setRefData({
                    countries:['India'],
                    states:getStatesList(resp[17].data.states),
                    uts:['Delhi','Chandigarh'],
                    cities:getCitiesList(resp[17].data.states),
                    // cities:{
                    //     'Haryana':['Gurgaon','Faridabad'],
                    //     'Delhi':['New Delhi'],
                    //     'Uttar Pradesh':['Noida','Greater Noida', 'Ghaziabad']
                    // },
                    amenities:resp[8].data.data,
                    roomCategories:generateRoomCategoriesOptions(resp[9].data.data),
                    bedType:generateBedTypeOptions(resp[13].data.data),
                    designationTypes:resp[14].data.data,
                    ownerShipTypes:resp[0].data.data,
                    hotelCategoryTypes:resp[1].data.data,
                    hotelStarCatTypes:resp[2].data.data,
                    propertyDetails,
                    amenitiesDetails:resp[7].data.data,
                    roomDetails,
                    contactDetails:getHotelContactDetails(resp[15].data.data),
                    savedRoomCategoryTypes:resp[10].data.data,
                    hotelImages:resp[4].data.data,
                    activeTab:parseInt(resp[3].data.data[0].setup_state),
                    hotelRulesMaster:resp[18].data.data,
                    hotelRules:resp[19].data.data,
                    mealDetails: resp[20].data.data
                }))
                setRenderStatus(true);
                setShowLoader(false);
            }).catch((err) => {
                setShowLoader(false);
            });
        }
        else {
            let promiseArr = [];
            setShowLoader(true);
            promiseArr.push(API.get(`/getHotelOwnershipTypes`));
            promiseArr.push(API.get(`/getHotelCategoryTypes`));
            promiseArr.push(API.get(`/getHotelStarCategoryTypes`));
            promiseArr.push(API.get(`/getAllFacilities`));
            promiseArr.push(API.get(`/getAllBedTypes`));
            promiseArr.push(API.get(`getAllDesignationTypes`));
            promiseArr.push(UtilsAPI.get(`/getStatesJSON`));
            promiseArr.push(API.get(`/getAllHotelRules`));

            Promise.all(promiseArr).then((resp) => {
                //const roomDetails = generateRoomDetails(resp);
                let propertyDetails = {
                    hotel_nick_name:'',
                    name:'',
                    gstin_number:'',
                    category_type_id:-1,
                    star_category_type_id:-1,
                    country:'India'
                };
                let contactNo = []
                propertyDetails.phoneNumber = contactNo.length > 0 ? contactNo.join(',') : '';

                dispatch(setRefData({
                    countries:['India'],
                    states:getStatesList(resp[6].data.states),
                    uts:['Delhi','Chandigarh'],
                    cities:getCitiesList(resp[6].data.states),
                    amenities:resp[3].data.data,
                    roomCategories:generateRoomCategoriesOptions([]),
                    bedType:generateBedTypeOptions(resp[4].data.data),
                    designationTypes:resp[5].data.data,
                    ownerShipTypes:resp[0].data.data,
                    hotelCategoryTypes:resp[1].data.data,
                    hotelStarCatTypes:resp[2].data.data,
                    propertyDetails,
                    //:resp[7].data.data,
                    roomDetails:{},
                    contactDetails:[],
                    amenitiesDetails:[],
                    savedRoomCategoryTypes:[],
                    hotelImages:[],
                    activeTab:0,
                    hotelRules:[],
                    hotelRulesMaster:resp[7].data.data,
                    mealDetails:[]
                }))
                setRenderStatus(true);
                setShowLoader(false);
            }).catch((err) => {
                setShowLoader(false);
            });
        }
        return () => {
           
        }
    },[hotelId])

    const getHotelContactDetails = (contactDetails) => {
        let details = []
        if(contactDetails.length == 0) {
            details.push({
                designation_code:OWNER,
                email_id:'',
                employee_name:'',
                mobile_number:''
            });
        }
        else 
            details = [...contactDetails];
        return details;
    }

    const generateRoomCategoriesOptions = (roomCats) => {
        let roomCategories = [];
        let existingRoomCats = []
        roomCats.map((cat) => {
            existingRoomCats.push(cat.name);
            roomCategories.push({
                code:cat.hotel_room_type_id,
                label:cat.name
            })
        })
        hotelCategoryMaster.map(catMaster => {
            if(existingRoomCats.indexOf(catMaster.label) == -1) {
                roomCategories.push({...catMaster})
            }
        })
        return roomCategories;
    }

    const generateBedTypeOptions = (bedTypes) => {
        let bedTypeOptions = [];
        bedTypes.map((bed) => {
            bedTypeOptions.push({
                code:bed.bed_type_id,
                label:bed.bed_type
            })
        });
        return bedTypeOptions;
    }

    const generateRoomDetails = (resp) => {
        const roomDetials = {};
        const roomCategoryCOll = resp[10].data.data;
        let roomCat = {};
        try {
            resp[9].data.data.map(room => {
                let roomNums;
                let roomCategories = [];
                let roomInfo = {
                    imagesList:[]
                };
                let imgListForHotel = resp[11].data.data.filter(img => img.hotel_room_type_id == room.hotel_room_type_id);
                if(imgListForHotel?.length > 0)
                    roomInfo.imagesList = imgListForHotel;

                roomInfo.roomCategory = room.hotel_room_type_id;
                roomInfo.roomCategoryName = room.name;
                roomNums = resp[12].data.data.filter((hotel => hotel.hotel_room_type_id == room.hotel_room_type_id)).map(hotel => hotel.room_number);
                roomInfo.roomNum =roomNums.join(',');
                roomInfo.roomCount = roomNums.length;

                roomCat = roomCategoryCOll.find(roomcategory => roomcategory.hotel_room_type_id == room.hotel_room_type_id);
                if(roomCat) {
                    roomInfo.roomDesc = roomCat.description;
                    roomInfo.minOcc = roomCat.minimum_occupancy;
                    roomInfo.maxOcc = roomCat.maximum_occupancy;
                    roomInfo.bedType = roomCat.bed_type_id;
                    roomInfo.price = roomCat.single_price || roomCat.attributes?.price;
                    roomInfo.doubleOccupancyPrice = roomCat.double_price || roomCat.attributes?.doubleOccupancyPrice;
                    roomInfo.extraBedPrice = roomCat.triple_price || roomCat.attributes?.extraBedPrice;
                    // roomInfo.breakfast = roomCat.attributes?.breakfast;
                    // roomInfo.lunch = roomCat.attributes?.lunch;
                    // roomInfo.dinner = roomCat.attributes?.dinner;
                    roomInfo.attributes = roomCat.attributes;
                }
                //roomCategories = resp[10].data.data.filter(roomCat => roomCat.hotel_room_type_id == room.hotel_room_type_id);
                if(roomCat)
                    roomDetials[room.name] = roomInfo;
            })     
        }
        catch(ex) {

        }
        return roomDetials;
    }

    const activeTab = useSelector(state => {
        return state.hotelDetails.activeTab;
    })



    return(
        <>
            { renderStatus && 
                <>
                    <Header />
                    { 
                        (activeTab + 1 == 1) &&  <SignUp /> 
                    }
                     { 
                        (activeTab + 1 == 2) &&  <PropertyDetails /> 
                    }
                    { 
                        (activeTab + 1 == 8) &&  <ContactDetails /> 
                    }
                    { 
                        (activeTab + 1 == 3) &&  <AmenitiesDetails /> 
                    }
                    { 
                        (activeTab + 1 == 4) &&  <RoomDetails /> 
                    }
                    { 
                        (activeTab + 1 == 6) &&  <HotelRules /> 
                    }
                    { 
                        (activeTab + 1 == 7) &&  <HourlyStay /> 
                    }
                    { 
                        (activeTab + 1 == 5) &&  <RoomMealInfo /> 
                    }
                    { 
                        (activeTab + 1 == 9) &&  <Confirmation /> 
                    }
                    { 
                        (activeTab + 1 == 10) &&  <SetUpMessagePage /> 
                    }
                    { showLoader && 
                        <Portal>
                            <CommonStyle.LoaderBox>
                                <CommonStyle.Loader></CommonStyle.Loader>
                            </CommonStyle.LoaderBox>
                        </Portal>
                    }
                </>
            }
        </>
    )
}

export default HotelInfo;