import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
var numeral = require('numeral');

//Style and Css Imports 
import * as CDStyles from '../../../styles/HotelInfo/contactDetailsStyle';
import * as RDetailStyle from '../../../styles/HotelInfo/roomDetailsStyle';

//Internal Imports 
import SaveDetails from '../SaveDetails';
import API from '../../../api';
import * as HSStyles from '../../../styles/HotelInfo/hourlyStayStyle';

//REdux Imports 
import { 
    setActiveTab,
    setActiveTabAndMealDetails
 } from '../../../slices/hotelDetails';
 import { 
    ROOMONLY,
    ROOM_WITH_BREAKFAST,
    ROOM_WITH_BREAKFAST_PLUS_LUNCH_OR_DINNER
 } from '../../../config/constants';

const RoomMealInfo = () => {


    const breakfastRef = useRef('');
    const dinnerRef = useRef('');
    const lunchRef = useRef('');

    const [mealPrices, setMealPrices] = useState({});
    const [errorObject, setErrorObj] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [showBreakfastPrice,setShowBreakfastPrice] = useState(false);
    const [showDinnerPrice,setShowDinnerPrice] = useState(false);


    const activeTab = useSelector(state => {
        return state.hotelDetails.activeTab;
    })

    let hotelId = useSelector(state => state.hotelDetails.hotelInfo.hotelId);
    
    const roomDetails = useSelector(state => state.hotelDetails.hotelInfo.roomDetails);
    const mealDetails = useSelector(state => state.hotelDetails.hotelInfo.mealDetails);

    const dispatch = useDispatch();

    //Move to Next Tab Button Handler. 
    const moveToTabNextTab = () => {
        if(!validateInfo())
            saveMealPrices();
    }

    const validateInfo = () => {
        let errorObj = {};
        let isNotValid = false;
        if(showBreakfastPrice && !mealPrices.freeBreakfast && !mealPrices['breakfast']) {
            isNotValid = true;
            errorObj['breakfast'] = true;
        }
        if(showDinnerPrice && !mealPrices['lunch']) {
            isNotValid = true;
            errorObj['lunch'] = true;
        }
        if(showDinnerPrice && !mealPrices['dinner']) {
            isNotValid = true;
            errorObj['dinner'] = true;
        }
        setErrorObj((prevObj) => ({
            ...prevObj,
            ...errorObj
        }))
        return isNotValid;
    }

    //Save PRice for Meals.
    let price;
    let mealInfoDetails = [];
    const saveMealPrices = () => {

        if (showBreakfastPrice) {
            mealInfoDetails.push({
                hotel_id: hotelId,
                is_free: mealPrices.freeBreakfast || false,
                meal_type: 1,
                price: mealPrices.breakfast,
            });
        }
        if (showDinnerPrice) {
            mealInfoDetails.push({
                hotel_id: hotelId,
                is_free: false,
                meal_type: 2,
                price: mealPrices.lunch,
            });
            mealInfoDetails.push({
                hotel_id: hotelId,
                is_free: false,
                meal_type: 3,
                price: mealPrices.dinner,
            });
        }
        let promiseArr = [];
        promiseArr.push(API.post('/updateHotelMealDetails',{
            hotel_id:hotelId
        }));
        mealInfoDetails.map(roomCat => {
            promiseArr.push(API.post('/addHotelMealDetails',roomCat))
        });
        if(promiseArr.length > 0){
            Promise.all(promiseArr).then(() => {
                API.post('/updateHotelSetupState',{
                    setup_state:(activeTab + 1),
                    hotel_id:hotelId
                }).then((resp) => {
                    //dispatch(setActiveTab(activeTab + 1));
                    dispatch(setActiveTabAndMealDetails({
                        activeTab:activeTab + 1,
                        mealDetails:[...mealDetails]
                    }));
                    setOpenDialog(false);
                });
            })
        }
        else {
            API.post('/updateHotelSetupState',{
                setup_state:(activeTab + 1),
                hotel_id:hotelId
            }).then((resp) => {
                dispatch(setActiveTab(activeTab + 1));
                setOpenDialog(false);
            });
        }
    }

    //Move to Prev Tab Button Handler. 
    const moveToPrevTab = () => {
        dispatch(setActiveTab(activeTab - 1));
    }

    const openDialogBox = () => {
        if(!validateInfo())
            setOpenDialog(true);
    }

    //Focus out handler.
    const focusOutHandler = (event) => {
        let newPrice = {
            [event.target.name] : (event.target.value.trim() != "" ? parseFloat(event.target.value) : '')
        }
        setMealPrices((prevObj) => ({
            ...prevObj,
            ...newPrice
        }))
        let errObj = {
            [event.target.name] : ((event.target.value).trim() == '' ? `Please enter ${event.target.name} price` : '' )
        }
        setErrorObj((prevObj) => ({
            ...prevObj,
            ...errObj
        }))
    }

    useEffect(() => {
        if(breakfastRef.current) 
            breakfastRef.current.value = (mealPrices['breakfast'] != null && mealPrices['breakfast'] != undefined) ? numeral(mealPrices['breakfast']).format('0,0.00') : '';
        if(lunchRef.current)
            lunchRef.current.value = (mealPrices['lunch'] != null && mealPrices['lunch'] != undefined) ? numeral(mealPrices['lunch']).format('0,0.00') : '';
        if(dinnerRef.current)
            dinnerRef.current.value = (mealPrices['dinner'] != null && mealPrices['dinner'] != undefined) ? numeral(mealPrices['dinner']).format('0,0.00') : '';
    },[mealPrices])

    useEffect(() => {
        let mealObj = {};
        mealDetails.map(mealInfo => {
            switch(mealInfo.meal_type) {
                case 1: 
                    mealObj.breakfast = mealInfo.price;
                    mealObj.freeBreakfast = mealInfo.is_free;
                    mealObj.hasBreakfast = true;
                    break;
                case 2:
                    mealObj.lunch = mealInfo.price;
                    break;
                case 3:
                    mealObj.dinner = mealInfo.price;
                    break;
            }
        })
        setMealPrices((prevObj) => ({
            ...prevObj,
            ...mealObj
        }));
        if (mealObj.hasBreakfast) {
            setShowBreakfastPrice(mealObj.hasBreakfast);
        }
        if (mealObj.lunch || mealObj.dinner) {
            setShowDinnerPrice(true);
        }
    },[])



    const checkIfError = (name) => {
        if(errorObject[name]) // && (!mealPrices[name] || mealPrices[name] == ""))
            return {
                error:true
           }
    }

    const getErrorMessage = (name) => {
        return errorObject[name];
    }

    const breakfastProp = () => {
        if(showBreakfastPrice) {
            return {
                hasBreakfast:true
            }
        }
    }

    const noBreakfast = () => {
        if(!showBreakfastPrice) {
            return {
                noBreakfast:true
            }
        }
    }

    const lunchProp = () => {
        if(showDinnerPrice) {
            return {
                hasBreakfast:true
            }
        }
    }

    const noLunch = () => {
        if(!showDinnerPrice) {
            return {
                noBreakfast:true
            }
        }
    }

    const toggleSwitchHandler = (event) => {
        let obj = {
            breakfast:'',
            freeBreakfast:event
        }
        setMealPrices((prevObj) => ({
            ...prevObj,
            ...obj
        }));
    }
    
    return (
        <CDStyles.ParentBox>
            <CDStyles.DetailsBox>
                 <CDStyles.DetailHeader>
                    Add Pricing for Meals
                </CDStyles.DetailHeader>
                <RDetailStyle.MealInfoBody>
                    <RDetailStyle.BreakfastParent>
                        <RDetailStyle.BreakfastRow>
                            <RDetailStyle.BreakfastLabel>Do you provide breakfast?</RDetailStyle.BreakfastLabel>
                            <RDetailStyle.BreakfastButtonsBox>
                                <RDetailStyle.BreakfastYesButton onClick = { () => { setShowBreakfastPrice(true)} } { ...breakfastProp() } variant = 'contained'>Yes</RDetailStyle.BreakfastYesButton>
                                <RDetailStyle.BreakfastNoButton onClick = { () => { setShowBreakfastPrice(false)} } { ...noBreakfast() } variant = 'contained'>No</RDetailStyle.BreakfastNoButton>
                            </RDetailStyle.BreakfastButtonsBox>
                        </RDetailStyle.BreakfastRow>
                        { showBreakfastPrice && 
                            <>
                                <RDetailStyle.BreakfastRowComp>
                                    <RDetailStyle.BreakfastLabelGray>Is breakfast complementary ?</RDetailStyle.BreakfastLabelGray>
                                    <RDetailStyle.BFSwitch checked = { mealPrices['freeBreakfast'] } onChange = { toggleSwitchHandler } checkedChildren='Yes'></RDetailStyle.BFSwitch>
                                </RDetailStyle.BreakfastRowComp>
                                <RDetailStyle.BreakfastRow>
                                    <RDetailStyle.BreakfastInput
                                        disabled = { (mealPrices['freeBreakfast'] ? true : false) } 
                                        size = 'small' 
                                        label = { mealPrices.freeBreakfast ? 'Complementary Breakfast' : 'Price for Breakfast' }
                                        autoComplete='off' 
                                        { ...checkIfError('breakfast')}
                                        variant="outlined" 
                                        inputRef = { breakfastRef }
                                        onBlur = { focusOutHandler }
                                        name = 'breakfast'
                                        min = {0}
                                        helperText = { getErrorMessage('breakfast') }
                                        className="number-input"
                                        InputProps={{
                                            inputProps: {
                                            inputMode: 'numeric',
                                            },
                                        }}
                                    ></RDetailStyle.BreakfastInput>
                                </RDetailStyle.BreakfastRow>
                            </>
                        }
                    </RDetailStyle.BreakfastParent>           
                    <RDetailStyle.LunchParent>
                        <RDetailStyle.BreakfastRow>
                            <RDetailStyle.BreakfastLabel>Do you provide lunch and dinner?</RDetailStyle.BreakfastLabel>
                            <RDetailStyle.BreakfastButtonsBox>
                                <RDetailStyle.LunchYesButton onClick = { () => { setShowDinnerPrice(true)} } { ...lunchProp() } variant = 'contained'>Yes</RDetailStyle.LunchYesButton>
                                <RDetailStyle.LunchNoButton onClick = { () => { setShowDinnerPrice(false)} } { ...noLunch() } variant = 'contained'>No</RDetailStyle.LunchNoButton>
                            </RDetailStyle.BreakfastButtonsBox>
                        </RDetailStyle.BreakfastRow>
                        { showDinnerPrice && 
                            <>
                                <RDetailStyle.BreakfastRow>
                                    <RDetailStyle.BreakfastInput 
                                        size = 'small' 
                                        label = 'Price for Lunch  '
                                        autoComplete='off' 
                                        { ...checkIfError('lunch')}
                                        variant="outlined" 
                                        inputRef = { lunchRef }
                                        onBlur = { focusOutHandler }
                                        name = 'lunch'
                                        min = {0}
                                        helperText = { getErrorMessage('lunch') }
                                        className="number-input"
                                        InputProps={{
                                            inputProps: {
                                            inputMode: 'numeric',
                                            },
                                        }}
                                    >
                                    </RDetailStyle.BreakfastInput>
                                </RDetailStyle.BreakfastRow>
                                <RDetailStyle.BreakfastRow>
                                    <RDetailStyle.BreakfastInput 
                                        size = 'small' 
                                        label = 'Price for Dinner '
                                        autoComplete='off' 
                                        { ...checkIfError('dinner')}
                                        variant="outlined" 
                                        inputRef = { dinnerRef }
                                        onBlur = { focusOutHandler }
                                        name = 'dinner'
                                        min = {0}
                                        helperText = { getErrorMessage('dinner') }
                                        className="number-input"
                                        InputProps={{
                                            inputProps: {
                                            inputMode: 'numeric',
                                            },
                                        }}
                                    ></RDetailStyle.BreakfastInput>
                                </RDetailStyle.BreakfastRow>
                            </>
                        }
                    </RDetailStyle.LunchParent>
                </RDetailStyle.MealInfoBody>
                <RDetailStyle.Note>
                    Note : Price including taxes.
                </RDetailStyle.Note>
            </CDStyles.DetailsBox>
            <SaveDetails  showBackButton = {true} nextTabHandler = { moveToTabNextTab } prevTabHandler = {moveToPrevTab}/>
            {/* <SubmitConfirmation open = { openDialog } submitHandler = { moveToTabNextTab }/> */}
        </CDStyles.ParentBox>
    )
}

export default RoomMealInfo;