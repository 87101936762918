import styled from "styled-components";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const GSParent = styled(Box)`
&& {
    flex-grow:1;
    display:flex;
    height:calc(100vh - 146px);
    @media(max-width:576px) {
        min-height:calc(100vh - 146px);
    }
}`

export const GSFooterParent = styled(Box)`
&& {
    height:80px;
    padding:0 3rem;
    border-top:6px solid rgb(221, 221, 221) !important;
    display:flex;
    justify-content:flex-end;
    align-items:center;
    @media(max-width:576px) {
        padding:0 2rem;
    }
}`

export const GSButton = styled(Box)`
&& {
    background: linear-gradient(rgb(243,23,113) 0%,rgb(208,16,95) 100%);
    height: 48px;
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    cursor:pointer;
    @media(max-width:576px) {
        width:100%;
    }
}`

export const GridContainer = styled(Grid)`
&& {

}`


export const GridItem = styled(Grid)`
&& {

}`

export const GettingStartedText = styled(Typography)`
&& {
    font-size: 3rem;
    font-family: 'Red Hat Display';
    color: black;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-weight:700;
    @media(max-width:576px) {
        font-size: 2rem;
        justify-content: flex-start;
        padding-left: 3rem;
    }
}`

export const InfoMaster = styled(Box)`
&& {
    display:flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding:0 4rem 0 0;
    @media(max-width:576px){
        padding-left:3rem;
        padding-right:2rem;
        justify-content:flex-start;
    }
}`

export const InfoParentFirst = styled(Box)`
&& {
    display:flex;
    justify-content: space-between;
    padding-bottom:2rem;
    border-bottom:1px solid rgba(0, 0, 0, 0.1);
}`

export const InfoParentSecond = styled(Box)`
&& {
    display:flex;
    justify-content: space-between;
    padding:2rem 0;
    border-bottom:1px solid rgba(0, 0, 0, 0.1);
}`

export const InfoParentThird = styled(Box)`
&& {
    display:flex;
    justify-content: space-between;
    padding-top:2rem;
}`

export const InfoTextParent = styled(Box)`
&& {
    padding-left: 1.5rem;
    position: relative;
    padding-right:1rem;
}`

export const SNo = styled(Typography)`
&& {
    position: absolute;
    left: 0px;
    top: 0px;
    font-size:1.4rem;
    @media(max-width:576px) {
        font-size:1.12rem;
    }
}`

export const TextTitle = styled(Typography)`
&& {
    font-size:1.4rem;
    font-family:'Red Hat Display';
    font-weight:600;
    color:black;
    @media(max-width:576px) {
        font-size:1.12rem;
    }
}`

export const TextDesc = styled(Typography)`
&& {
    font-size:1.12rem;
    font-family:'Red Hat Display';
    font-weight:400;
    color:rgb(113, 113, 113);
    @media(max-width:576px) {
        font-size:0.85rem;
    }
}`

export const InfoImage1 = styled.img`
&& {
    background-image:url(styles/assets/image1.jpeg);
    background-repeat: no-repeat;
    height: 80px;
    width: 80px;
    background-size: cover;
    border-radius: 4px;
}`

export const InfoImage2 = styled.img`
&& {
    background-image: url(styles/assets/image2.jpeg);
    background-repeat: no-repeat;
    height: 80px;
    width: 80px;
    background-size: cover;
    border-radius: 4px;
}`

// background-image: url(styles/assets/image3.jpeg);
export const InfoImage3 = styled.img`
&& {
    background-repeat: no-repeat;
    height: 80px;
    width: 80px;
    background-size: cover;
    border-radius: 4px;
}`