export const authenticate = {
    isAuthenticated:() => {
        if(localStorage.getItem('userToken') != null)
            return true;
        return false;
    },
    authenticate: (token) => {
        localStorage.setItem('userToken',token);
    },
    signout:() => {
        localStorage.clear('userToken');
    }
}