import styled from 'styled-components';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';

export const DataGridParent = styled(Box)`
&& {
    display:flex;
    background: #FFFFFF;
    border: 1px solid #DEE2E6;
    border-radius: 10px;
    height:100%;
    width:100%;
    flex-direction:column;
}`

export const DataGridBody = styled(Box)`
&& {
    padding:0 0.5rem;
    width:100%;
}`

export const GridColumn = styled(Box)`
&& {
    width:20%;
    color:#495057;
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;
    display:flex;
    padding-left:1rem;
    align-items:center;
}`

export const GridActionColumn = styled(Box)`
&& {
    width:20%;
    color:#495057;
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;
    display:flex;
    padding: 0.5rem 1rem;
    align-items:center;
}`

export const GridRow = styled(Box)`
&& {
    display:flex;
    width:100%;
    border-bottom: 1px solid #F0F6FF;
}`

export const ActionParent = styled(Box)`
&& {
    display:flex;
    flex-direction:column;
}`

export const AssignRoom = styled(Button)`
&& {
    font-size: 0.65rem;
    border-radius: 12px;
    text-transform: capitalize;
}`

export const EditDeleteBox = styled(Box)`
&& {
    display:flex;
    margin-top: 0.5rem;
    justify-content: center;
}`

export const EditIcon = styled(Box)`
&& {
    height: 28px;
    width: 28px;
    background-image:url(styles/assets/editRoom.svg);
    cursor:pointer;
}`

export const DeleteIcon = styled(Box)`
&& {
    margin-left:0.5rem;
    height: 28px;
    width: 28px;
    background-image:url(styles/assets/deleteRoom.svg);
    cursor:pointer;
}`