import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';

//Internal Imports
import FileUploadControl from 'react-material-file-upload';
import { AWSAPI } from "../../../api";
import API from '../../../api';
import { s3config } from '../../../config/s3config';
import {
    setHotelImages
} from '../../../slices/hotelDetails';
import { PREURLIMG } from "../../../config/constants";

//Material UI and Style Imports
import * as RIStyle from '../../../styles/HotelInfo/roomImagesStyle';
import * as CommonStyle from '../../../styles/HotelInfo/commonStyle';
import Portal from '@mui/material/Portal'

const AmenitiesImages = (props) => {

    const hotelImages = useSelector(state => state.hotelDetails.hotelInfo.hotelImages);
    const hotelId = useSelector(state => state.hotelDetails.hotelInfo.hotelId);

    const [showLoader,setShowLoader] = useState(false);

    const dispatch = useDispatch();

    const fileChangeHandler = (event) => {
        let selectedFile = event;
        let uploadedFilesPath = [];
        const uploadFile = async () => {
            try {
                setShowLoader(true);
                for(let i = 0; i < selectedFile.length; i ++ ) {
                    let currentFile = selectedFile[i];
                    const res = await AWSAPI.post('/getSignedUrlForGivenFileNameInGivenBucket',[{
                        "bucketName": s3config.bucketName, 
                        "fileName": currentFile.name
                    }])    
                    let presignedUrl = res.data[0].signedUrl;
                    const fileUploadresult = await axios.put(presignedUrl, currentFile, {
                        headers: {
                            'Content-Type': currentFile.type,
                        },
                    });
                    uploadedFilesPath.push(s3config.bucketURL + currentFile.name);
                }
                let newHotelImages = hotelImages.map(img => img); //.filter(img => img.photo_type != 'SQUARE');
                uploadedFilesPath.map(file => {
                    newHotelImages.push({
                        hotel_id:hotelId,
                        photo_type:'SQUARE',
                        photo:file
                    });
                })
                dispatch(setHotelImages(newHotelImages));
                setShowLoader(false);
                //setImagesList(uploadedFilesPath);
            }
            catch(ex) {
                console.log(ex.toString());
                setShowLoader(false);
            }   
        };
        uploadFile();
    }

    const heroFileChangeHandler = (event) => {
        let currentFile = event[0];
        const uploadFile = async () => {
            try {
                setShowLoader(true);
                const res = await AWSAPI.post('/getSignedUrlForGivenFileNameInGivenBucket',[{
                    "bucketName": s3config.bucketName, 
                    "fileName": currentFile.name
                }])    
                let presignedUrl = res.data[0].signedUrl;
                const fileUploadresult = await axios.put(presignedUrl, currentFile, {
                    headers: {
                        'Content-Type': currentFile.type,
                    },
                });
                let newHotelImages = hotelImages.filter(img => img.photo_type != 'MAIN');
                newHotelImages.push({
                    hotel_id:hotelId,
                    photo_type:'MAIN',
                    photo:(PREURLIMG + currentFile.name)
                })
                dispatch(setHotelImages(newHotelImages));
                setShowLoader(false);
                //setHeroImage(s3config.bucketURL + currentFile.name);
            }
            catch(ex) {
                setShowLoader(false);
                console.log(ex.toString());
            }   
        };
        uploadFile();
    }

    const deleteHeroImage = async (img) => {
        if(img.hotel_photo_id) {
            const resp = await API.post('/deleteHotelPhoto',{
                hotel_photo_id:img.hotel_photo_id
            });
            let newHotelImages = hotelImages.filter(image => image.hotel_photo_id != img.hotel_photo_id);
            dispatch(setHotelImages(newHotelImages));
        }
        else {
        let newHotelImages = hotelImages.filter(image => image.photo != img.photo);
            dispatch(setHotelImages(newHotelImages));
        }
    }

    const deleteImage = async (img) => {
        if(img.hotel_photo_id) {
            const resp = await API.post('/deleteHotelPhoto',{
                hotel_photo_id:img.hotel_photo_id
            });
            let newHotelImages = hotelImages.filter(image => image.hotel_photo_id != img.hotel_photo_id);
            dispatch(setHotelImages(newHotelImages));
        }
        else {
            let newHotelImages = hotelImages.filter(image => image.photo != img.photo);
            dispatch(setHotelImages(newHotelImages));
        }
    }

    const showUploadedHeroImage = () => {
        let imagePath = hotelImages.find(img => img.photo_type == 'MAIN');
        if(imagePath)
            return (
                <>
                    <RIStyle.FacilityHeroImg src = { imagePath.photo }></RIStyle.FacilityHeroImg>
                    <RIStyle.DelHeroImage onClick = {() => deleteHeroImage(imagePath) }/>
                </>
            )
    }

    const showUploadedFacilityImages = () => {
        let imagesList = hotelImages.filter(img => img.photo_type == 'SQUARE');
        let images = imagesList.map(img => {
            return (
                <RIStyle.FacilityImageGridItem item xs = {12} lg = {6}>
                    <RIStyle.FacilityImg src = { img.photo }></RIStyle.FacilityImg>
                    <RIStyle.DelImage onClick = {() =>  deleteImage(img) }/>
                </RIStyle.FacilityImageGridItem>
            )
        })
        return images;
    }

    return (
        <RIStyle.RoomImageBoxParent container spacing={2}>
            <RIStyle.RoomImageBox item spacing = {2} lg = {6} xs = {21}>
                <RIStyle.DetailRow>
                    <RIStyle.DetailColumnLabel>
                        Upload Property Images
                    </RIStyle.DetailColumnLabel>
                    <RIStyle.DescriptionLabel>
                        Hero image of the hotel (Upload only one Image)
                    </RIStyle.DescriptionLabel>
                    <RIStyle.AmenititesUploadBox>
                        <FileUploadControl onChange={ heroFileChangeHandler } sx = {{ opacity:0, height:'100%', width:'100%', zIndex : '2', position:'relative'}}/>
                        {/* <RIStyle.ImgUpload onChange = { heroFileChangeHandler } type = 'file'></RIStyle.ImgUpload> */}
                        <RIStyle.RoomImagePic>
                            Drop your image here, or <span>browse</span> 
                        </RIStyle.RoomImagePic>
                    </RIStyle.AmenititesUploadBox>
                    {  props.imageError.noMainImage && <RIStyle.ErrorMessage>At least 1 image is mandatory.</RIStyle.ErrorMessage>}
                </RIStyle.DetailRow>
                <RIStyle.DetailRow>
                    <RIStyle.DetailColumnLabel>
                        Uploaded Image
                    </RIStyle.DetailColumnLabel>
                    <RIStyle.HeroImageUploaded>
                        { showUploadedHeroImage() } 
                    </RIStyle.HeroImageUploaded>
                </RIStyle.DetailRow>
            </RIStyle.RoomImageBox>
            <RIStyle.RoomImageBox item spacing = {2} lg = {6} xs = {21}>
                <RIStyle.DetailRow>
                    <RIStyle.DetailColumnLabel>
                        
                    </RIStyle.DetailColumnLabel>
                    <RIStyle.DescriptionLabel>
                        Images of Facade, Reception, Lobby, Restaurant, etc.,
                    </RIStyle.DescriptionLabel>
                    <RIStyle.AmenititesUploadBox>
                        <FileUploadControl onChange={ fileChangeHandler }  sx = {{opacity:0, height:'100%', width:'100%', zIndex : '2', position:'relative'}}/>
                        <RIStyle.RoomImagePic>
                            Drop your image here, or <span>browse</span>    
                        </RIStyle.RoomImagePic>
                    </RIStyle.AmenititesUploadBox>
                    {  props.imageError.noSubImages && <RIStyle.ErrorMessage>At least 1 image is mandatory.</RIStyle.ErrorMessage>}
                </RIStyle.DetailRow>

                <RIStyle.DetailRow>
                    <RIStyle.DetailColumnLabel>
                        Uploaded Images
                    </RIStyle.DetailColumnLabel>
                    <RIStyle.FacilityImagesUploaded>
                        <RIStyle.FacilityImageGrid container spacing = {2}>
                            { showUploadedFacilityImages() }
                        </RIStyle.FacilityImageGrid>
                    </RIStyle.FacilityImagesUploaded>
                </RIStyle.DetailRow>
            </RIStyle.RoomImageBox>
            { showLoader && 
                <Portal>
                    <CommonStyle.LoaderBox>
                        <CommonStyle.Loader></CommonStyle.Loader>
                    </CommonStyle.LoaderBox>
                </Portal>
            }
        </RIStyle.RoomImageBoxParent>
    )
}

export default AmenitiesImages;