import { createSlice } from "@reduxjs/toolkit";

const initialState  = {
    hotelInfo : {
        hotelCategoryMaster : [{
            code:'-1',
            label:'Deluxe AC Room'
        },{
            code:'-2',
            label:'Family Room'
        },{
            code:'-3',
            label:'Deluxe AC Room - Special'
        },{
            code:'-4',
            label:'Luxury Room'
        },{
            code:'-5',
            label:'Super Deluxe Room'
        },],
        hotelId:-1,
        propertyDetails:{
            country:'India',
            hotel_nick_name:'',
            name:'',
            gstin_number:'',
            category_type_id:'',
            star_category_type_id:'',
            attributes:{}
        },
        contactDetails:[],
        amenitiesDetails:[],
        roomDetails:{},
        editRoomInfo:false,
        selectedRoomInfo:{
            'roomCategory':'',
            'bedType':'',
            'roomCount':'',
            'roomNum':''
        },
        hotelImages:[]
    },
    activeTab : 0,
    activeLeftMenuID:1,
    hotelId:-1
}

const hotelDetailsSlice = createSlice({
    name:'hotelDetails',
    initialState,
    reducers:{
        setActiveTab:(state,actions) => {
            state.activeTab = actions.payload;
        },
        setActiveTabandHotelId:(state,action) => {
            state.activeTab = action.payload.activeTab;
            state.hotelInfo.hotelId = action.payload.hotelId;
        },
        setPropertyDetails:(state,action) => {
            state.hotelInfo.propertyDetails[action.payload.name] = action.payload.value;
        },
        setContactDetails:(state,action) => {
            const contactInfo = state.hotelInfo.contactDetails.find(contact => contact.designation_code == action.payload.designation_code)
            if(contactInfo)
                state.hotelInfo.contactDetails.find(contact => contact.designation_code == action.payload.designation_code)[action.payload.name] = action.payload.value;
            else {
                state.hotelInfo.contactDetails.push({
                    designation_code:action.payload.designation_code,
                    [action.payload.name] : action.payload.value
                })
            }
        },
        setExtraContactDetails:(state,action) => {
            state.hotelInfo.contactDetails.extraDetails[state.contactDetails.extraDetails.length][action.payload.name] = action.payload.value
        },
        setRoomDetails:(state,action) => {
            state.hotelInfo.roomDetails[action.payload.roomCategory] = action.payload.info;
            state.hotelInfo.selectedRoomInfo = {};
            state.hotelInfo.editRoomInfo = false;
        },
        setAmenitiesDetails:(state,action) => {
            if(action.payload.isChecked)
                state.hotelInfo.amenitiesDetails.push(action.payload.facilityObj);
            else 
                state.hotelInfo.amenitiesDetails = state.hotelInfo.amenitiesDetails.filter(amenity => amenity.facility_id != action.payload.facilityObj.facility_id);
        },
        setRefData:(state,action) => {
            state.hotelInfo.states = action.payload.states;
            state.hotelInfo.countries = action.payload.countries;
            state.hotelInfo.uts = action.payload.uts;
            state.hotelInfo.cities = action.payload.cities;
            state.hotelInfo.amenities = action.payload.amenities;
            state.hotelInfo.roomCategories = action.payload.roomCategories;
            state.hotelInfo.bedType = action.payload.bedType;
            state.hotelInfo.designationTypes = action.payload.designationTypes;
            state.hotelInfo.ownerShipTypes = action.payload.ownerShipTypes;
            state.hotelInfo.hotelCategoryTypes = action.payload.hotelCategoryTypes;
            state.hotelInfo.hotelStarCatTypes = action.payload.hotelStarCatTypes;
            state.hotelInfo.propertyDetails = action.payload.propertyDetails;
            state.hotelInfo.amenitiesDetails = action.payload.amenitiesDetails;
            state.hotelInfo.roomDetails = action.payload.roomDetails;
            state.hotelInfo.contactDetails = action.payload.contactDetails;
            state.hotelInfo.savedRoomCategoryTypes = action.payload.savedRoomCategoryTypes;
            state.hotelInfo.hotelImages = action.payload.hotelImages;
            state.activeTab = action.payload.activeTab;
            state.hotelInfo.hotelRulesMaster = action.payload.hotelRulesMaster;
            state.hotelInfo.hotelRules = action.payload.hotelRules;
            state.hotelInfo.mealDetails = action.payload.mealDetails;
        },
        deleteHotelRoom:(state,action) => {
            state.hotelInfo.roomDetails = action.payload;
        },
        setEditRoomInfoFlag:(state,action) => {
            state.hotelInfo.editRoomInfo = action.payload;
            if(!action.payload)
                state.hotelInfo.selectedRoomInfo = {};
        },
        setEditRoomInfo:(state,action) => {
            state.hotelInfo.selectedRoomInfo = action.payload;
            state.hotelInfo.editRoomInfo = true;
        },
        setActiveLeftMenuID:(state,action) => {
            state.activeLeftMenuID = action.payload;
        },
        setHotelPropInfo:(state,action) => {
            state.hotelInfo.propertyDetails = action.payload.propertyDetails;
        },
        setLoggedInUser:(state,action) => {
            state.hotelInfo.userInfo = action.payload.userInfo;
            state.hotelInfo.hotelId = action.payload.hotelId;
            state.activeTab = state.activeTab + 1;
        },
        setHotelImages:(state,action) => {
            state.hotelInfo.hotelImages = action.payload;
        },
        setActiveTabAndRoomDetails:(state,action) => {
            state.activeTab = action.payload.activeTab;
            state.hotelInfo.roomDetails = action.payload.roomDetails;
        },
        setActiveTabAndMealDetails:(state,action) => {
            state.activeTab = action.payload.activeTab;
            state.hotelInfo.mealDetails = action.payload.mealDetails;
        },
        setHotelRules:(state,action) => {
            if(action.payload.checked)
                state.hotelInfo.hotelRules.push(action.payload.obj);
            else 
                state.hotelInfo.hotelRules = state.hotelInfo.hotelRules.filter(rule => rule.rule_description != action.payload.obj.rule_description);
        },
        setHotelCheckOutTime:(state,action) => {
            state.hotelInfo.propertyDetails.attributes[action.payload['name']] = action.payload.value;
        },
        // setHourlyCheckoutTime:(state,action) => {
        //     state.hotelInfo.
        // }
    }
})

export const {
    setActiveTab,
    setPropertyDetails,
    setContactDetails,
    setRoomDetails,
    setRefData,
    setAmenitiesDetails,
    deleteHotelRoom,
    setEditRoomInfoFlag,
    setEditRoomInfo,
    setActiveLeftMenuID,
    setHotelPropInfo,
    setLoggedInUser,
    setActiveTabandHotelId,
    setHotelImages,
    setActiveTabAndRoomDetails,
    setHotelRules,
    setHotelCheckOutTime,
    setHourlyCheckoutTime,
    setActiveTabAndMealDetails
} = hotelDetailsSlice.actions;

export default hotelDetailsSlice.reducer;