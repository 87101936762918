import styled from "styled-components"
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { DatePicker } from "antd";

export const DashboardTabParent = styled(Box)`
&& {
    display:flex;
    width:100%;
    justify-content:space-between;
    padding:1rem 2rem;
}`

export const DashboardTabBody = styled(Box)`
&& {
    display:flex;
}`

export const DashboardTab = styled(Box)`
&& {
    border: 0.5px solid rgba(166, 188, 218, 1);
    height:40px;
    width:175px;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    background-color:${props => {
        return props.isSelected ? '#000000' : 'white'
    }};
    color:${props => {
        return props.isSelected ? 'white' : '#000000'
    }};
}`

export const DashboardFirstTab = styled(Box)`
&& {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 0.5px solid rgba(166, 188, 218, 1);
    height:40px;
    width:175px;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    background-color:${props => {
        if(props.isSelected)
            return '#000000';
        else
            return 'white';
    }};
    color:${props => {
        return props.isSelected ? 'white' : '#000000'
    }};
}`

export const DashboardLastTab = styled(Box)`
&& {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 0.5px solid rgba(166, 188, 218, 1);
    height:40px;
    width:175px;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    background-color:${props => {
        return props.isSelected ? '#000000' : 'white'
    }};
    color:${props => {
        return props.isSelected ? 'white' : '#000000'
    }};
}`

export const DashboardTabDateParent = styled(Box)`
&& {
    display: flex;
}`

export const TabText = styled(Typography)`
&& {

}`

export const TabCount = styled(Box)`
&& {
    height: 22px;
    margin-left:5px;
    width: 22px;
    font-size:0.85rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color:${props => {
        return props.isSelected ? 'white' : '#000000'
    }};
    color:${props => {
        return props.isSelected ? '#000000' : 'white'
    }};
}`

export const BookingDatePicker = styled(DatePicker)`
&& {

}`