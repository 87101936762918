import styled from "styled-components";
import Box from '@mui/material/Box';
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Switch } from "antd";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

export const HourlyParent = styled(Box)`
&& {
    display:flex;
    flex-grow:1;
}`

export const HourlyBox = styled(Box)`
&& {
    margin:1rem 10rem;
    flex-grow:1;
    border:1px solid black;
    border-radius:8px;
    padding-bottom:2rem;
    margin-bottom:100px;
    @media (max-width: 576px) {
        margin:1rem 2rem;
    }
    display:flex;
    flex-direction:column;
}`

export const HourlyInfoBox = styled(Box)`
&& {
    display:flex;
    flex-direction:column;
    padding:2rem;
    flex-grow:1;
    gap:1rem;
    @media(max-width:526px) {
        padding:1rem;
    };
    & ::-webkit-inner-spin-button: {
        "-webkit-appearance": "none";
        margin: 0;
    };
    & ::-webkit-outer-spin-button: {
        "-webkit-appearance": "none";
        margin: 0;
    };
}`

export const CheckInOutTime = styled(Box)`
&& {
    display:flex;
    gap:2rem;
    align-items:center;
    @media(max-width:576px) {
        flex-direction:column;
        gap:1rem;
        margin-top:1rem;
        align-items:flex-start;
    };
    & ul:last-child {
        overflow:unset !important;
    }
}`

export const AvailLable = styled(Box)`
&& {
    color: var(--black-main, #141414);
    font-family: Red Hat Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}`

export const TimePickerParent = styled(Box)`
&& {
    display:flex;
    gap:1rem;
}`

export const BreakfastRow = styled(Box)`
&& {
    display: flex;
    align-items: center;
    gap: 3rem;
    @media(max-width:526px) {
        flex-direction:column;
        width:100%;
        align-items:flex-start;
        gap:1rem;
    }
}`

export const BreakfastLabel = styled(Box)`
&& {
    color: var(--neutral-100, #20102B);
    font-family: Red Hat Display;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}`

export const BreakfastButtonsBox = styled(Box)`
&& {
    display:flex;
    gap:1rem;
}`

export const BreakfastYesButton = styled(Button)`
&& {
    border-radius: 5px;
    background: ${props => {
        if(props.hasHourlyStay)
            return '#141414';
        else 
            return 'white';
    }};
    color: ${props => {
        if(props.hasHourlyStay)
            return 'white';
        else 
            return '#141414';
    }};
    font-family: Red Hat Display;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Red Hat Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    &:hover {
        background: ${props => {
            if(props.hasHourlyStay)
                return '#141414';
            else 
                return 'white';
        }};
    }
}`

export const BreakfastNoButton = styled(Button)`
&& {
    border-radius: 5px;
    border: 1px solid var(--black-main, #141414);
    background: ${props => {
        if(props.noHourlyStay)
            return '#141414';
        else 
            return 'white';
    }};
    color: ${props => {
        if(!props.noHourlyStay)
            return '#141414';
        else 
            return 'white';
    }};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Red Hat Display;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    &:hover {
        background: ${props => {
            if(props.noHourlyStay)
                return '#141414';
            else 
                return 'white';
        }};
    }
}`

export const TarrifParentBox = styled(Grid)`
&& {
    & .MuiFormLabel-root {
        color: rgba(0, 0, 0, 0.6) !important;
    }
}`

export const TarrifBox3 = styled(Grid)`
&& {

}`

export const TarrifBox6 = styled(Grid)`
&& {
    
}`

export const TarrifBox9 = styled(Grid)`
&& {
    
}`

export const Tarrif3Parent = styled(Box)`
&& {
    width:100%;
    border-right:1px solid rgba(77, 76, 76, 0.4);
    padding-right:1rem;
    padding-left:1rem;
    display: flex;
    flex-direction: column;
    height:254px;
    gap: 2rem;
    padding-bottom: 2rem;
    @media(max-width:576px) {
        padding:0px;
        border:0px;
        height:fit-content;
    }

}`

export const Tarrif6Parent = styled(Box)`
&& {
    width:100%;
    border-right:1px solid rgba(77, 76, 76, 0.4);
    padding-right:1rem;
    padding-left:1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 2rem;
    height:254px;
    @media(max-width:576px) {
        padding:0px;
        border:0px;
        height:fit-content;
    };
}`

export const Tarrif9Parent = styled(Box)`
&& {
    width:100%;
    padding-right:1rem;
    padding-left:1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 2rem;
    height:254px;
    @media(max-width:576px) {
        padding:0px;
        height:fit-content;
    }
}`

export const RuleIDBox = styled(Box)`
&& {
    display:flex;
    justify-content:space-between;
    width:90%;
    @media(max-width:526px) {
        width:100%;
    }
}`

export const RuleLabel = styled(Typography)`
&& {
    color: var(--black-main, #141414);
    font-family: Red Hat Display;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}`

export const RuleSwitch = styled(Switch)`
&& {
    &.ant-switch-checked {
        background-color: #1DC9A0;
    };
    &.ant-switch-checked:hover {
        background-color: #1DC9A0;
    }
}`

export const RoomTarrifInput = styled(TextField)`
&& {
    width:90%;
}`

export const TariffInputParent = styled(Box)`
&& {
    display:flex;
    flex-direction:column;
    width:100%;
    gap:1.5rem;
}`

export const RulesBox = styled(Box)`
&& {
    margin:1rem 8rem;
    flex-grow:1;
    border:1px solid black;
    border-radius:8px;
    padding-bottom:2rem;
    margin-bottom:100px;
    @media (max-width: 576px) {
        margin:1rem 2rem;
        margin-bottom:100px;
    }
    display:flex;
    flex-direction:column;
}`

export const Note = styled(Typography)`{
    padding-bottom:2rem;
}`