import React, { useState } from 'react';

//Internal Imports
import FileUploadControl from 'react-material-file-upload';
import { AWSAPI } from '../../../api';
import { s3config } from '../../../config/s3config';
import { PREURLIMG } from '../../../config/constants';
import axios from 'axios';

//Material UI and Style Imports
import * as PropertyDetailStyle from '../../../styles/HotelInfo/propertyDetailsStyle';
import * as RIStyle from '../../../styles/HotelInfo/roomImagesStyle';
import * as CommonStyle from '../../../styles/HotelInfo/commonStyle';
import Portal from '@mui/material/Portal'

const RoomImages = ({ imageListError, imagesList, setImagesList }) => {

    const [showLoader,setShowLoader] = useState(false);

    const imagesUploaded = async (files) => {
        //const files = [];
        let selectedFile = files;
        let uploadedFilesPath = [];
        const uploadFile = async () => {
            try {
                setShowLoader(true);
                for(let i = 0; i < selectedFile.length; i ++ ) {
                    let currentFile = selectedFile[i];
                    const res = await AWSAPI.post('/getSignedUrlForGivenFileNameInGivenBucket',[{
                        "bucketName": s3config.bucketName, 
                        "fileName": currentFile.name
                    }])    
                    let presignedUrl = res.data[0].signedUrl;
                    await axios.put(presignedUrl, currentFile, {
                        headers: {
                            'Content-Type': currentFile.type,
                        },
                    });
                    uploadedFilesPath.push({
                        photo:s3config.bucketURL + currentFile.name
                    });
                }
                //let newHotelImages = [...imagesList];
                imagesList.map(img => {
                    uploadedFilesPath.push(img);
                })
                // uploadedFilesPath.map(file => {
                //     newHotelImages.push(PREURLIMG + file.name);
                // })
                setImagesList(uploadedFilesPath);
                setShowLoader(false);
            }
            catch(ex) {
                setShowLoader(false);
                console.log(ex.toString());
            }   
        };
        uploadFile();
    }

    const deleteImage = () => {

    }

    const showUploadedImage = () => {
        let images = imagesList.map(img => {
            return (
                <RIStyle.FacilityImageGridItem item lg = {4} xs = {12}>
                    <RIStyle.FacilityImg src = { img.photo }></RIStyle.FacilityImg>
                    <RIStyle.DelImageRoom onClick = {() =>  deleteImage(img) }/>
                </RIStyle.FacilityImageGridItem>
            )
        })
        return images;
    }

    return (
        <PropertyDetailStyle.RoomImageBox>
            <PropertyDetailStyle.DetailRow>
                <RIStyle.DetailColumnLabel>
                    Upload Room Images
                </RIStyle.DetailColumnLabel>
            </PropertyDetailStyle.DetailRow>
            <PropertyDetailStyle.DetailRow>
                <RIStyle.UploadBox>
                    <FileUploadControl onChange={ imagesUploaded } sx = {{ opacity:0, height:'100%', width:'100%', zIndex : '2', position:'relative'}}/>
                    <RIStyle.RoomImagePic>
                            Drop your image here, or <span>browse</span> 
                    </RIStyle.RoomImagePic>
                </RIStyle.UploadBox>
            </PropertyDetailStyle.DetailRow>
            <PropertyDetailStyle.DetailRowImage>
                { imageListError && <RIStyle.ErrorMessage>At least 1 image is mandatory.</RIStyle.ErrorMessage>}
                <RIStyle.DetailColumnLabel>
                    Images Uploaded
                </RIStyle.DetailColumnLabel>
                <RIStyle.RoomImageUploaded>
                    <RIStyle.FacilityImageGrid container spacing = {2}>
                        { showUploadedImage() } 
                    </RIStyle.FacilityImageGrid>
                </RIStyle.RoomImageUploaded>
            </PropertyDetailStyle.DetailRowImage>
            { showLoader && 
                <Portal>
                    <CommonStyle.LoaderBox>
                        <CommonStyle.Loader></CommonStyle.Loader>
                    </CommonStyle.LoaderBox>
                </Portal>
            }
        </PropertyDetailStyle.RoomImageBox>
    )
}

export default RoomImages;