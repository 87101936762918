import styled from 'styled-components';
import Box from '@mui/material/Box';
import { Button, TextField, Typography } from '@mui/material';

export const DHeaderParent = styled(Box)`
&& {
    padding:1rem 1rem;
    display:flex;
    justify-content:space-between;
    box-shadow: 3px 2px 8px rgba(0, 0, 0, 0.15);
}`

export const HotelNameHeader = styled(Typography)`
&& {
    color: #031633;
    font-weight: 700;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 2rem;
    font-family:'Red Hat Display';
}`

export const SearchBar = styled(TextField)`
&& {
    width:20rem;
    & .MuiInputBase-root {
        height:2rem;
        padding-left:15px;
        font-family:'Red Hat Display';
        font-size: 0.8rem;
        color: #718BAE;
    }
}`

export const SearchBarWrapper = styled(Box)`
&& {
    position:relative;
    & svg {
        position: absolute;
        color: #A6BCDA;
        top: 4px;
        left: 5px;
    }
}`

export const NewReservation = styled(Button)`
&& {
    width: 12rem;
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 18px;
    color: #FFFFFF;
    margin-left: 2rem;
    padding: 0.45rem;
    cursor: pointer;
    background:linear-gradient(rgb(243, 23, 113) 0%, rgb(208, 16, 95) 100%);
    text-transform:capitalize;
}`

export const UserLogo = styled(Box)`
&& {

}`

export const SearchBarParent = styled(Box)`
&& {
    flex-grow:1;
    display:flex;
    align-items:center;
    padding-left: 4rem;
    justify-content:flex-end;
}`