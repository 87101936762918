import styled from "styled-components";
import Box from '@mui/material/Box';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Typography } from "@mui/material";
import Button from '@mui/material/Button'
import TextField from "@mui/material/TextField";

export const BCContainer = styled(Box)`
&& {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 4rem;
    align-items:center;
}`

export const CircleOutline = styled(CheckCircleOutlineOutlinedIcon)`
&& {
    margin: 0 auto 0.5rem auto;
    color: #237804;
    font-size: 2rem;
}`

export const CloseIcon = styled(CloseOutlinedIcon)`
&& {
    cursor:pointer;
    margin:0.5rem 0;
}`

export const BookingConfHeader = styled(Typography)`
&& {
    margin-top:1rem;
    color:#000000;
    font-weight:600;
    font-size:1.5rem;
    letter-spacing:0.01rem;
    font-family:'Red Hat Display';
    text-align:center;
}`

export const BookingConfSubHeader = styled(Typography)`
&& {
    color:#000000;
    font-weight:400;
    font-size:1.1rem;
    letter-spacing:0.01rem;
    font-family:'Red Hat Display';
    text-align:center;
    margin:0 1rem;
    margin-top:1rem;
}`

export const AlterEmailText = styled(BookingConfSubHeader)`
&& {
    margin-top:2rem;
}`

export const AlterEmailLabel = styled(BookingConfSubHeader)`
&& {
    color:#354665;
    font-weight:500;
    margin-bottom:0.5rem;
}`

export const AlternateEmailBox = styled(Box)`
&& {
    display:flex;
    flex-direction:column;
    max-width: 30rem;
    align-items: flex-start;
    @media (max-width: 576px) {
        align-items: center;
        width: 90%;
        margin: 0 5%;
    }
}`

export const SubmitButton = styled(Button)`
&& {
    margin-top:0.7rem;
    width:90%;
    font-weight:500;
    background: black;
    border-radius: 5px;
    height:45px;
    font-size:1rem;
    font-family:'Red Hat Display';
    text-transform:capitalize;
    &:hover{
        background:black;
    }
}`

export const HotelioDomain = styled(Box)`
&& {
    background-color: #EFF3FA;
    font-style: normal;
    font-weight: 400;
    font-size: 0.85rem;
    line-height: 18px;
    color: #141414;
    font-family: 'Red Hat Display';
    height: 40px;
    flex-grow: 1;
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    left: -5px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-left: 0px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}`

export const DomainParent = styled(Box)`
&& {
    display:flex;
    width:90%;
    & .MuiFormHelperText-root {
        width:150%;
    }
}`

export const InputField = styled(TextField)`
&& {
    width: 70%;
    padding: 0px;
}`


export const ComfirmButton  = styled(Button)`
&& {
    background: black;
    text-transform: capitalize;
    font-family: 'Red Hat Display';
    font-size: 1rem;
    font-weight: 500;
    color: white;
    font-size: 0.75rem;
    &:hover {
        background:black !important;
    }
}`