import React, { useRef, useEffect} from "react";

//Material UI and Style Imports
import * as CDStyles from '../../../styles/HotelInfo/contactDetailsStyle';
import * as PropertyDetailStyle from '../../../styles/HotelInfo/propertyDetailsStyle';
import { OWNER } from "../../../config/constants";

const OwnerDetails  = ({ focusOutHandler , contactDetails, errorObject }) => {

    const ownerNameRef = useRef();
    const ownerEmailRef = useRef();
    const ownerNumberRef = useRef();

    const ownerContactDetails = contactDetails.find(contact => contact.designation_code == OWNER) || {};

    useEffect(() => {
        ownerEmailRef.current.value = ownerContactDetails?.email_id || '';
        ownerNameRef.current.value = ownerContactDetails?.employee_name || '';
        ownerNumberRef.current.value = ownerContactDetails?.mobile_number || '';
        return () => {

        }
    });

    const checkIfError = (name) => {
        if(errorObject[name] == '' && (!ownerContactDetails[name] || ownerContactDetails[name] == "")) 
            return {
                error:true
            }
        else if(errorObject[name]) {
            return {
                error:true
            }
        }
    }

    return (
        <>
            <CDStyles.OwnerDetailsLable>
                Property Owner Details
            </CDStyles.OwnerDetailsLable>
            <PropertyDetailStyle.DetailRow>
                <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                        Name <p>*</p>
                    </PropertyDetailStyle.DetailColumnLabel>
                    <PropertyDetailStyle.InputField 
                        { ...checkIfError("employee_name")}
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Please Enter Name"
                        name = "employee_name"
                        onBlur = { (event) => focusOutHandler(event,OWNER) }
                        inputRef = { ownerNameRef }
                        size="small"

                    >
                    </PropertyDetailStyle.InputField>
                </PropertyDetailStyle.DetialColumn>
                <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                        Email <p>*</p>
                    </PropertyDetailStyle.DetailColumnLabel>
                    <PropertyDetailStyle.InputField 
                        { ...checkIfError("email_id")}
                        type="email"
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Please Enter Email"
                        name = "email_id"
                        onBlur = { (event) => focusOutHandler(event,OWNER) }
                        inputRef = { ownerEmailRef }
                        size="small"
                        helperText = { errorObject['email_id'] }
                    >
                        
                    </PropertyDetailStyle.InputField>
                </PropertyDetailStyle.DetialColumn>
            </PropertyDetailStyle.DetailRow>
            <PropertyDetailStyle.DetailRow>
                <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                        Phone Number <p>*</p>
                    </PropertyDetailStyle.DetailColumnLabel>
                    <PropertyDetailStyle.InputField 
                        autoComplete='off'
                        { ...checkIfError("mobile_number")}
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Please Enter Phone Number"
                        type="tel"
                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                        name = "mobile_number"
                        onBlur = { (event) => focusOutHandler(event,OWNER) }
                        inputRef = { ownerNumberRef }
                        size="small"
                        helperText = { errorObject['mobile_number'] }
                    >
                    </PropertyDetailStyle.InputField>
                </PropertyDetailStyle.DetialColumn>
                <PropertyDetailStyle.DetialColumn>
                </PropertyDetailStyle.DetialColumn>
            </PropertyDetailStyle.DetailRow>
        </>
    )
}

export default OwnerDetails