import styled from 'styled-components';
import Box from '@mui/material/Box';

export const DashboardParent = styled(Box)`
&& {
    display:flex;
    flex-direction:column;
    height:100%;
}`

export const DashboardRightSection = styled(Box)`
&& {
    height:100%;
    display:flex;
    flex-grow:1;
    flex-direction:column;
}`

export const DashboardLeftSection = styled(Box)`
&& {
    width:170px;
    height:100%;
    display:flex;
}`

export const DashboardBody = styled(Box)`
&& {
    flex-grow:1;
    display:flex;
    padding-top:5px;
    overflow-y:auto;
}`