import styled from "styled-components";

import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Autocomplete } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';

export const  PropertyDetailsParentBox = styled(Box) `
&& {
    display:flex;
    flex-grow:1;
    flex-direction:column;
    overflow-x:hidden;
}`

export const DetailsBox = styled(Box)`
&& {
    margin:1rem 10rem;
    flex-grow:1;
    border:1px solid black;
    border-radius:8px;
    padding-bottom:2rem;
    margin-bottom:100px;
    @media (max-width: 576px) {
        margin:1rem 2rem;
        margin-bottom:100px;
    }
}`

export const UserSalution = styled(Box)`
&& {
    margin:0 10rem;
    font-family:'Red Hat Display';
    & p {
        margin:0px;
    }
    @media (max-width: 576px) {
        margin:0rem 2rem;
    }
}`

export const UserWelcome = styled.p`
&& {
    color : black;
    font-size:1.20rem;
    margin-top:1rem !important;
    font-weight:700;
    text-transform:capitalize;
}`

export const ProperyInfoMsg = styled.p`
&& {
    color:#4D4C4C;
    font-size:1rem;
    margin-top:0.5rem !important;
}`

export const ProperyInfoChangeMsg = styled.p`
&& {
    color:#4D4C4C;
    font-size:0.8rem;
    margin-top:0.5rem !important;
}`

export const DetailHeader = styled(Box)`
&& {
    height:50px;
    background-color:#EFF3FA;
    color:black;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display:flex;
    align-items:center;
    padding-left:20px;
    font-weight: 600;
}
`

export const DetailRow = styled(Box)`
&& {
    display:flex;
    margin-top:1rem;
    @media (max-width: 576px) {
        flex-direction: column;
        gap: 1rem;
    }
}`


export const DetailRowImage = styled(Box)`
&& {
    display:flex;
    margin-top:1rem;
    flex-direction:column;
    width:90%;
    height:calc(100% - 200px);
}`

export const DetialColumn = styled(Box)`
&& {
    flex-basis:50%;
    padding-left:2rem;
    position:relative;
}`

export const LogoImg = styled.img`
&& {
    width:150px;
    height:95px;
    border-radius:5px;
}`

export const DelImage = styled(DeleteIcon)`
&& {
    right: 5px;
    top: 5px;
    position: absolute;
    cursor: pointer;
    color: black;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    font-size:1.25rem;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}`

export const ImgParent = styled(Box)`
&& {
    width:150px;
    height:95px;
    border-radius:5px;
    position:relative;
}`

export const DetailColumnLabel = styled(Box)`
&& {
    display: flex;
    font-size: 1rem;
    height: 34px;
    font-weight:500;
    align-items: center;
    color:black;
    & p {
        color:red;
        font-size: 0.8rem;
    }
    & span {
        font-size:0.8rem;
    }
}`

export const InputField = styled(TextField)`
&& {
    width: 90%;
    padding: 0px;
    & .MuiInputBase-input {
        color:#4D4C4C;
    }
}`

export const Dropdown = styled(Autocomplete)`
&& {
    width: 90%;
    padding: 0px;
    & .MuiInputBase-input {
        color:#4D4C4C;
    }
}`

export const MultiLIneInputField = styled(TextField)`
&& {
    width: 90%;
    & input {
        padding:8px;
        color:#718BAE;
    }
}`



export const LocationLabel = styled(Box)`
&& {
    color:black;
    font-size:1rem;
    font-weight:600;
    padding-left:2rem;
    margin-top:1rem;
}`

export const SaveFooter = styled(Box)`
&& {
    background-color:#F9F9F9;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    height:86px;
    margin-top:2rem;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-items:center;
    position:fixed;
    bottom:0px;
    z-index:2;
    width:100vw;
}`

export const FooterButtonBox = styled(Box)`
&& {
    display:flex;
    justify-content:space-between;
    height:80px;
    padding:0 3rem 0 2.2rem;
    width:100%;
    align-items:center;
}`

export const SaveButton = styled(Button)`
&& {
    text-transform:initial;
    height:3rem;
    font-weight:600;
    background: #222222;
    border-radius: 5px;
    font-family:'Red Hat Display';
    font-size:1rem;
    &:hover {
        background: #222222 !important;
    }
}`

export const BackButton = styled(Box)`
&& {
    cursor:pointer;
    display:flex;
    align-items:center;
    height:27px;
    font-family:'Red Hat Display';
    & p{
        color: black;
        padding-left: 10px;
        text-decoration: underline;
        font-family: 'Red Hat Display';
        font-size: 1rem;
    }
    }
    & svg {
        font-size:1rem;
    }
    border-radius:6px;
    padding:0 15px 0 8px;
}`

export const RoomImageBox = styled(Box)`
&& {
    display:flex;
    flex-grow:1;
    flex-direction:column;
    flex-basis:50%;
    & .MuiButtonBase-root {
        height: 135px;
        width: 100%;
        position: absolute;
    };
    @media (max-width: 576px) {
        margin:1rem 2rem;
    }
}`

export const LogoUpload = styled.input`
&& {
    margin-top:0.5rem;
    width:90%;
    border:1px dashed #A6BCDA;
    background: #F0F6FF;
    height:80px;
    border-radius:5px;
    position:absolute;
    z-index:2;
    opacity:0;
    cursor:pointer;
}`

export const LogoUploadDummy = styled(Box)`
&& {
    margin-top:0.5rem;
    width:90%;
    background: white;
    height:90px;
    border-radius:5px;
    position:relative;
    z-index:1;
    border:1px dashed rgb(176, 176, 176) !important;
    background-image:url(styles/assets/photo.png);
    background-repeat: no-repeat;
    background-position: 50% 10px;
    background-size: 10%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 0.5rem;
    & span {
        padding-left: 5px;
    }
}`