import { Grid } from 'antd';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from "dayjs";

//Styled and Material UI IMports
import * as DBGrid from '../../styles/Dashboard/dashboardGridStyle';

//Internal Imports 
import DataGrid from '../Grid';
import AGGrrdWrapper from '../Grid/AGGridWrapper';
import { DASHBOARDTAB } from '../../config/constants';
import ActionButtonRenderer from './ActionButtonRenderer';
import API from '../../api';
import {
    setRoomSummary
} from '../../slices/dashboardInfo';

const DashboardGrid = () => {

    const dispatch = useDispatch();

    //Get Active Tab
    const activeDashboardTab = useSelector(state => state.dashboardInfo.activeDashboardTab);

    let date = '2023-12-23';

    //Get Hotel Id
    const hotelId = useSelector(state => state.dashboardInfo.hotelId);

    const roomSummary = useSelector(state => state.dashboardInfo.roomSummary);

    //Get options on the basis of Active Tab
    const getOptionsByTab = () => {
        switch(activeDashboardTab) {
            case DASHBOARDTAB.PENDING:
                return [{
                    id:DASHBOARDTAB.CHECKEDIN,
                    label:'Check In'
                },{
                    id:DASHBOARDTAB.CANCELLED,
                    label:'Cancel'
                },{
                    id:DASHBOARDTAB.NOSHOW,
                    label:'No Show'
                }];
            case DASHBOARDTAB.CHECKEDIN:
                return [{
                    id:DASHBOARDTAB.PENDING,
                    label:'Pending Checkin'
                },{
                    id:DASHBOARDTAB.CHECKEDOUT,
                    label:'Check Out'
                }];
            case DASHBOARDTAB.CHECKEDOUT:
                return [{
                    id:DASHBOARDTAB.CHECKEDIN,
                    label:'Check In'
                }];
            case DASHBOARDTAB.CANCELLED:
                return [{
                    id:DASHBOARDTAB.PENDING,
                    label:'Pending Checkin'
                }];
            case DASHBOARDTAB.NOSHOW:
                return [{
                    id:DASHBOARDTAB.PENDING,
                    label:'Pending Checkin'
                },{
                    id:DASHBOARDTAB.CHECKEDIN,
                    label:'Pending Checkin'
                }];
            default:
                return [];
        }
    }

    //cancel hotel booking
    const cancelHotelBooking = async (bookinId) => {
        const resp = await API.post('/cancelBookingByHotel',{
            booking_id:bookinId,
            hotel_id:hotelId
        });
        if(resp.data.data.affectedRows == 1) {
            let promiseArr = [];
            promiseArr.push(API.get(`/getPendingCheckIn?hotel_id=${hotelId}&date=${date}`));
            promiseArr.push(API.get(`/getCancelledInfo?hotel_id=${hotelId}&date=${date}`));

            const newSummary = await Promise.all(promiseArr);
            dispatch(setRoomSummary({
                roomSummary:{
                    pendingCheckIns:newSummary[0].data.data,
                    checkIns:[...roomSummary.checkIns],
                    checkOuts:[...roomSummary.checkOuts],
                    cancelledBookings:newSummary[1].data.data,
                    noShows:[...roomSummary.noShows]
                }
            }));
        }
    }

    const noShowBooking = async (bookinId) => {
        const resp = await API.post('/noShowBooking',{
            booking_id:bookinId,
            hotel_id:hotelId
        });
        if(resp.data.data.affectedRows == 1) {
            let promiseArr = [];
            promiseArr.push(API.get(`/getPendingCheckIn?hotel_id=${hotelId}&date=${date}`));
            promiseArr.push(API.get(`/getNoShowInfo?hotel_id=${hotelId}&date=${date}`));

            const newSummary = await Promise.all(promiseArr);
            dispatch(setRoomSummary({
                roomSummary:{
                    pendingCheckIns:newSummary[0].data.data,
                    checkIns:[...roomSummary.checkIns],
                    checkOuts:[...roomSummary.checkOuts],
                    cancelledBookings:[...roomSummary.cancelledBookings],
                    noShows:newSummary[1].data.data
                }
            }));
        }
    }

    //cancel hotel booking
    const markCheckIn = async (bookinId) => {
        const resp = await API.post('/checkInByHotel',{
            booking_id:bookinId,
            hotel_id:hotelId
        });
        if(resp.data.data.affectedRows == 1) {
            let promiseArr = [];
            promiseArr.push(API.get(`/getPendingCheckIn?hotel_id=${hotelId}&date=${date}`));
            promiseArr.push(API.get(`/getCheckedInInfo?hotel_id=${hotelId}&date=${date}`));
            promiseArr.push(API.get(`/getCheckoutInfo?hotel_id=${hotelId}&date=${date}`));
            promiseArr.push(API.get(`/getNoShowInfo?hotel_id=${hotelId}&date=${date}`));

            const newSummary = await Promise.all(promiseArr);
            dispatch(setRoomSummary({
                roomSummary:{
                    pendingCheckIns:newSummary[0].data.data,
                    checkIns:newSummary[1].data.data,
                    checkOuts:newSummary[2].data.data,
                    cancelledBookings:[...roomSummary.cancelledBookings],
                    noShows:newSummary[3].data.data
                }
            }));
        }
    }

    //cancel hotel booking
    const markCheckOut = async (bookinId) => {
        const resp = await API.post('/checkOutFromHotel',{
            booking_id:bookinId,
            hotel_id:hotelId
        });
        if(resp.data.data.affectedRows == 1) {
            let promiseArr = [];
            promiseArr.push(API.get(`/getCheckedInInfo?hotel_id=${hotelId}&date=${date}`));
            promiseArr.push(API.get(`/getCheckoutInfo?hotel_id=${hotelId}&date=${date}`));

            const newSummary = await Promise.all(promiseArr);
            dispatch(setRoomSummary({
                roomSummary:{
                    pendingCheckIns:[...roomSummary.pendingCheckIns],
                    checkIns:newSummary[0].data.data,
                    checkOuts:newSummary[1].data.data,
                    cancelledBookings:[...roomSummary.cancelledBookings],
                    noShows:[...roomSummary.noShows]
                }
            }));
        }
    }

    //Mark pending checkin hotel booking
    const markPendingCheckin = async (bookinId) => {
        const resp = await API.post('/pendingCheckIn',{
            booking_id:bookinId,
            hotel_id:hotelId
        });
        if(resp.data.data.affectedRows == 1) {
            let promiseArr = [];
            promiseArr.push(API.get(`/getPendingCheckIn?hotel_id=${hotelId}&date=${date}`));
            promiseArr.push(API.get(`/getCheckedInInfo?hotel_id=${hotelId}&date=${date}`));
            promiseArr.push(API.get(`/getNoShowInfo?hotel_id=${hotelId}&date=${date}`));
            promiseArr.push(API.get(`/getCancelledInfo?hotel_id=${hotelId}&date=${date}`));

            const newSummary = await Promise.all(promiseArr);
            dispatch(setRoomSummary({
                roomSummary:{
                    pendingCheckIns:newSummary[0].data.data,
                    checkIns:newSummary[1].data.data,
                    checkOuts:[...roomSummary.checkOuts],
                    cancelledBookings:newSummary[3].data.data,
                    noShows:newSummary[2].data.data,
                }
            }));
        }
    }

   

    const GridInfo = {};
    GridInfo.ColumnInfo = [{
        field : 'guest_name',
        headerName : 'Guest Name'
    },{
        field : 'generated_booking_id',
        headerName : 'Booking Id',
        width: 150,
    },{
        field : 'CheckInDate',
        headerName : 'Check-In',
        width: 150,
        valueFormatter:(params) => {
            let checkOutDate = new Date(params.data['CheckInDate']);
            return dayjs(checkOutDate).format('DD-MMM-YYYY');
        }
    },{
        field : 'CheckOutDate',
        headerName : 'Check-Out',
        width: 150,
        valueFormatter:(params) => {
            let checkOutDate = new Date(params.data['CheckOutDate']);
            return dayjs(checkOutDate).format('DD-MMM-YYYY');
        }
    },{
        field : 'nights',
        headerName : 'Nights',
        width: 100,
        valueFormatter:(params) => {
            let checkOutDate = new Date(params.data['CheckOutDate']);
            let checkInDate = new Date(params.data['CheckInDate']);
            let count = dayjs(checkOutDate).diff(dayjs(checkInDate),'day');
            return count;
        }
    },{
        field : 'roomcount',
        width: 100,
        headerName : 'Rooms Count'
    },{
        field : 'paymentMode',
        headerName : 'Payment Mode',
        valueFormatter:() => {
            return 'Pay at Hotel'
        }
    },{
        field : 'action',
        pinned: 'right',
        headerName : 'Action',
        width: 150,
        cellRenderer:ActionButtonRenderer,
        cellRendererParams : {
            options: getOptionsByTab(),
            onClick: (option,bookinId) => { 
                switch (option.id) {
                    case DASHBOARDTAB.CANCELLED:
                        return cancelHotelBooking(bookinId);
                    case DASHBOARDTAB.NOSHOW:
                        return noShowBooking(bookinId);
                    case DASHBOARDTAB.CHECKEDIN:
                        return markCheckIn(bookinId);
                    default:
                        return false;
                }
            }
        }
    }];


    const getGridData = () => {
        switch(activeDashboardTab) {
            case DASHBOARDTAB.PENDING:
                return roomSummary.pendingCheckIns;
            case DASHBOARDTAB.CHECKEDIN:
                return roomSummary.checkIns;
            case DASHBOARDTAB.CHECKEDOUT:
                return roomSummary.checkOuts;
            case DASHBOARDTAB.CANCELLED:
                return roomSummary.cancelledBookings;
            case DASHBOARDTAB.NOSHOW:
                return roomSummary.noShows;
        }
    }

    return (
        <DBGrid.DBGridParent>
            {/* <DBGrid.DBGridHeader>Reservation List</DBGrid.DBGridHeader> */}
            {/* <DBGrid.DBGridOptions>
                <DBGrid.DBGridButtons size = "small" variant='contained'> Expected Arrival </DBGrid.DBGridButtons>
                <DBGrid.DBGridButtons size = "small" variant='contained'> Checked In </DBGrid.DBGridButtons>
                <DBGrid.DBGridButtons size = "small" variant='contained'> Checked Out </DBGrid.DBGridButtons>
            </DBGrid.DBGridOptions> */}
            <DBGrid.DataGridWrapper>
                {/* <DataGrid GridInfo = { GridInfo } GridData = { GridData }/> */}
                <AGGrrdWrapper rowData={ getGridData() } colDefs = { GridInfo.ColumnInfo } />
            </DBGrid.DataGridWrapper>
        </DBGrid.DBGridParent>
    )
}

export default DashboardGrid;