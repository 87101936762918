import React from 'react';
import { useDispatch } from 'react-redux';
const yup = require('yup');

//Material UI and Style Imports
import *  as SUStyle from '../../styles/Login/signUpStyle.js'
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { InputAdornment, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

//Internal Imports
import API from '../../api/index.js';
import { authenticate } from '../../utils/auth.js';
import { setLoggedInUser } from '../../slices/hotelDetails.js'
import { PASSWORDREGEX } from '../../config/constants.js';
import { NAME_REGEX } from '../../config/constants.js';
import { MOBILE_REGEX } from '../../config/constants.js';

const SignUp = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [showPassword , setShowPassword] = useState(false);

    const [confirmshowPassword , setConfirmShowPassword] = useState(false);


    const [errorObject , setErrorObj] = useState({});

    const yupObject = yup.object().shape({
        password:yup.string().required().matches(PASSWORDREGEX,'Password is not valid'),
        mobile:yup.string().matches(MOBILE_REGEX,'Please enter a valid Phone Number.'),
        name:yup.string().matches(NAME_REGEX)
    })

    const mandatoryProps = [
        'name',
        'mobile',
        'password',
        'confirmPassword'
    ];

    const [formState, setFormState] = useState({
        password:'Abc@12345',
        confirmPassword:'Abc@12345'
    });

    const signupHandler = async (hasError) => {
        if(!hasError) {
            const empExists = await API.post('/checkIfHotelEmployeeExists',{
                mobile:formState.mobile
            });
            if(empExists.data.count == 1) {
                let obj = {
                    'mobile':'Mobile number already exists.' 
                }
                let userInfo = {
                    mobile:formState.mobile,
                    emailId : formState.mobile,
                    name:formState.name,
                    employeeId:empExists.data.data[0].employee_id,
                    //userName:resp.data.data[0]
                }
                
                authenticate.authenticate('test');
                let hotelId = -1;

                hotelId = empExists.data.data[0].hotel_id || -1;
                dispatch(setLoggedInUser({
                    userInfo,
                    hotelId
                }));
                //history.push('/onboarding');
                // setErrorObj((prevObj) => ({
                //     ...prevObj,
                //     ...obj
                // }));
            }
            else {
                const resp = await API.post('/signupHotelEmployee',{
                    employee_name:formState.name,
                    email_id:formState.mobile,
                    mobile:formState.mobile,
                    password:formState.password
                });
                let userInfo = {
                    mobile:formState.mobile,
                    emailId : formState.mobile,
                    name:formState.name,
                    employeeId:resp.data.data.insertId,
                    //userName:resp.data.data[0]
                }
                
                if(resp.data.data.affectedRows > 0) {
                    authenticate.authenticate('test');
                    let hotelId = -1;
                    dispatch(setLoggedInUser({
                        userInfo,
                        hotelId
                    }));
                    history.push('/onboarding');
                }
            }
        }
    }

    const focusOutHandler = (event) => {
        setFormState((prevObj) => ({
            ...prevObj,
            [event.target.name]:event.target.value
        }))
    }

    const validateInfo = () => {
        let errorObj = {};
        let hasError = false;
        mandatoryProps.map((prop) => {
            if(!formState[prop] || formState[prop] === ''){
                hasError = true;
                errorObj[prop] = '';
            }
        })
        if(formState['password'] && formState['confirmPassword'] && (formState['password'] != formState['confirmPassword'])) {
            errorObj['password'] = "Passwords don't match. Please re-enter correctly.";
            hasError = true;
        }
        if(hasError) {
            setErrorObj((prevObj) => ({
                ...errorObj  
            }));
            return true;
        }
        //if(!hasError) {
        yupObject.validate(formState,{ abortEarly:false})
        .then((res) => {
            signupHandler(false);
        }).catch((err) => {
            let obj = {};
            const errors = err.inner.reduce((acc, error) => {
                return {
                    ...acc,
                    [error.path]: true,
                }
                }, {});
            if(errors['password']) {
                hasError = true;
                obj['password'] = 'Password must be at least 8 characters long and should include at least one uppercase letter, one special character (!@#$%^&*), and one digit.';
            }
            if(errors['mobile']) {
                hasError = true;
                obj['mobile'] = 'Please enter a valid Mobile Number.';
            }
            if(errors['name']) {
                hasError = true;
                obj['name'] = 'Please enter a valid name with alphabets only.';
            }
            if(hasError) {
                setErrorObj((prevObj) => ({
                    ...obj  
                }));
            }
            else
                signupHandler(hasError);
        })
    }

    const checkIfError = (name) => {
        if(errorObject[name] == '' && (!formState[name] || formState[name] == ""))
            return {
                error:true
            }
        else if(errorObject[name]) {
            return {
                error:true
            }
        }
    }

    const onSignInClick = () => {
        history.push('/login')
    }

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const handleToggleConfirmPasswordVisibility = () => {
        setConfirmShowPassword(!confirmshowPassword);
    }

    return (
        <SUStyle.ParentBox>
            {/* <SUStyle.LeftPanel>

            </SUStyle.LeftPanel> */}
            <SUStyle.RightPanel>
                <SUStyle.HeaderBox>
                    Hello! Tell us about yourself.
                </SUStyle.HeaderBox>
                <SUStyle.DataRow>
                    <SUStyle.Label>
                        Name<p>*</p>
                    </SUStyle.Label>
                    <SUStyle.InputField
                        autoComplete='off'
                        { ...checkIfError("name")}
                        InputLabelProps={{shrink: false}} 
                        size="small"
                        variant="outlined" 
                        placeholder="Please Enter Name"
                        name="name"
                        value = { formState.name || ''}
                        onChange = { focusOutHandler}
                        helperText = { errorObject['name'] }
                    ></SUStyle.InputField>
                </SUStyle.DataRow>
                <SUStyle.DataRow>
                    <SUStyle.Label>
                        Mobile Number<p>*</p>
                    </SUStyle.Label>
                    <SUStyle.InputField
                        autoComplete='off'
                        { ...checkIfError("mobile")}
                        InputLabelProps={{shrink: false}} 
                        size="small"
                        variant="outlined" 
                        placeholder="Please Enter Mobile"
                        name="mobile"
                        value = { formState.mobile || ''}
                        onChange = { focusOutHandler}
                        helperText = { errorObject['mobile'] }
                    ></SUStyle.InputField>
                </SUStyle.DataRow>
                {/* <SUStyle.DataRow>
                    <SUStyle.Label>
                        Password<p>*</p>
                    </SUStyle.Label>
                    <SUStyle.InputField
                        autoComplete='off'
                        { ...checkIfError("password")}
                        InputLabelProps={{shrink: false}} 
                        size="small"
                        variant="outlined" 
                        type={ showPassword ? 'text' : "password" }
                        placeholder="Please Enter Password"
                        name="password"
                        value = { formState.password || ''}
                        onChange = { focusOutHandler}
                        helperText = { errorObject['password'] }
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleTogglePasswordVisibility}>
                                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                    ></SUStyle.InputField>
                </SUStyle.DataRow>
                <SUStyle.DataRow>
                    <SUStyle.Label>
                        Confirm Password<p>*</p>
                    </SUStyle.Label>
                    <SUStyle.InputField
                        autoComplete='off'
                        { ...checkIfError("confirmPassword")}
                        InputLabelProps={{shrink: false}} 
                        size="small"
                        variant="outlined" 
                        type={ confirmshowPassword ? 'text' : "password" }
                        placeholder="Please Re-enter Password"
                        name="confirmPassword"
                        value = { formState.confirmPassword || ''}
                        onChange = { focusOutHandler}
                        helperText = { errorObject['confirmPassword'] }
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleToggleConfirmPasswordVisibility}>
                                  {confirmshowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                    ></SUStyle.InputField>
                </SUStyle.DataRow> */}
                <SUStyle.DataRow>
                    <SUStyle.SingUPButton
                        variant="contained"
                        size="small"
                        onClick={validateInfo}
                    >
                        Get Started
                    </SUStyle.SingUPButton>
                </SUStyle.DataRow>
                {/* <SUStyle.TCBoxRow>
                    <SUStyle.SignInTCBox >
                        Already have an account? <a href="" onClick = {onSignInClick}> Sign In</a>
                    </SUStyle.SignInTCBox>
                </SUStyle.TCBoxRow> */}
            </SUStyle.RightPanel>
        </SUStyle.ParentBox>
    )
}

export default SignUp;