import React from 'react';
import { useDispatch } from 'react-redux';

//Material UI and Style Imports
import *  as SUStyle from '../../styles/Login/signUpStyle.js'
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { InputAdornment, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

//Internal Imports
import API from '../../api/index.js';
import { SNSAPI } from '../../api/index.js';
import { authenticate } from '../../utils/auth.js';
import { setLoggedInUser } from '../../slices/hotelDetails.js';
import { setHotelID } from '../../slices/dashboardInfo.js';

const Login = (props) => {

    const resetPwdLocation = ((props.location && props.location.state) ? true : false);

    const history = useHistory();

    const dispatch = useDispatch()

    const [errorObject , setErrorObj] = useState({});

    const [showPassword , setShowPassword] = useState(false);

    const [snackbarError, setSnackBarError] = useState({
        open:false,
        message:'',
        severity:'info'
    })

    const mandatoryProps = [
        'mobile',
        'password'
    ];

    const [formState, setFormState] = useState({});

    const loginHandler = async () => {
        try {
            if(!validateInfo()) {
                const resp = await API.post('/loginHotelEmployee',{
                    mobile_number:formState.mobile,
                    password:formState.password
                });
                if(resp.data.data.length > 0 && resp.data.data[0].mobile_number == formState.mobile) {
                    let userInfo = {
                        emailId : resp.data.data[0].email_id,
                        mobile:resp.data.data[0].mobile_number,
                        name:resp.data.data[0].employee_name,
                        employeeId:resp.data.data[0].employee_id,
                        //userName:resp.data.data[0]
                    }
                    let hotelId = -1;
                    if(resp.data.data[0].hotel_id)
                        hotelId = resp.data.data[0].hotel_id;
                    dispatch(setLoggedInUser({
                        userInfo,
                        hotelId
                    }));
                    dispatch(setHotelID(hotelId));
                    authenticate.authenticate(userInfo.emailId);
                    history.push('/dashboard');
                }
                else {
                    let errObj = {
                        open:true,
                        message:'Incorrect login credentials. Please try again.'
                    }
                    setSnackBarError((prevObj) => ({
                        ...prevObj,
                        ...errObj
                    }));
                }
            }
        }
        catch(ex) {

        }
    }

    const focusOutHandler = (event) => {
        setFormState((prevObj) => ({
            ...prevObj,
            [event.target.name]:event.target.value
        }))
    }

    const validateInfo = () => {
        let errorObj = {};
        let hasError = false;
        mandatoryProps.map((prop) => {
            if(!formState[prop] || formState[prop] === ''){
                hasError = true;
                errorObj[prop] = true;
            }
        })
        setErrorObj((prevObj) => ({
            ...errorObj  
        }));
        return hasError;
    }

    const checkIfError = (name) => {
        if(errorObject[name] && (!formState[name] || formState[name] == ""))
            return {
                error:true
            }
    }

    const getErrorMessage = () => {
        if(snackbarError.open)
            return snackbarError.message;
    }

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const getHeadingText = () => {
        if(resetPwdLocation) {
            return <span>Your password has been reset <br/> Sign In Now </span>
        }
        else
            return 'Welcome to Hotelio!'
    }

    return (
        <SUStyle.ParentBox>
            {/* <SUStyle.LeftPanel>

            </SUStyle.LeftPanel> */}
            <SUStyle.RightPanel>
                <SUStyle.LoginHeaderBox>
                    { getHeadingText() }
                </SUStyle.LoginHeaderBox>
                <SUStyle.DataRow>
                    <SUStyle.Label>
                        Mobile Number<p>*</p>
                    </SUStyle.Label>
                    <SUStyle.InputField
                        { ...checkIfError("mobile")}
                        InputLabelProps={{shrink: false}} 
                        size="small"
                        variant="outlined" 
                        placeholder="Please Enter Mobile Number"
                        name="mobile"
                        value = { formState.mobile || ''}
                        onChange = { focusOutHandler}
                    ></SUStyle.InputField>
                </SUStyle.DataRow>
                <SUStyle.DataRow>
                    <SUStyle.Label>
                        Password<p>*</p>
                    </SUStyle.Label>
                    <SUStyle.InputField
                        autoComplete='off'
                        { ...checkIfError("password")}
                        InputLabelProps={{shrink: false}} 
                        size="small"
                        variant="outlined" 
                        type={ showPassword ? 'text' : "password" }
                        placeholder="Please Enter Password"
                        name="password"
                        value = { formState.password || ''}
                        onChange = { focusOutHandler}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleTogglePasswordVisibility}>
                                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                    ></SUStyle.InputField>
                </SUStyle.DataRow>
                {/* <SUStyle.TCBoxRow>
                    <SUStyle.TCBox>
                        <a href="">Forgot Password?</a>
                    </SUStyle.TCBox>
                </SUStyle.TCBoxRow> */}
                <SUStyle.DataRow>
                    <SUStyle.SingUPButton
                        variant="contained"
                        size="small"
                        onClick={loginHandler}
                    >
                        Sign In
                    </SUStyle.SingUPButton>
                </SUStyle.DataRow>
                <SUStyle.OTPBoxRow>
                    <SUStyle.OTPBox>
                        <a href="/loginotp">Log in with OTP</a>
                    </SUStyle.OTPBox>
                    <SUStyle.TCBox>
                        {/* <a href="">Forgot Password?</a> */}
                        <p onClick = {() => { history.push('/forgotpassword') }}>Forgot Password?</p>
                    </SUStyle.TCBox>
                </SUStyle.OTPBoxRow>
                <SUStyle.SignUPBoxRow>
                    <SUStyle.SignUPTCBox>
                        <SUStyle.CreateAccText >Don't have account?</SUStyle.CreateAccText>
                        <SUStyle.CreateAccBtn variant='text' onClick={ () => { history.push('/Signup') } } href="">Create Account</SUStyle.CreateAccBtn>
                    </SUStyle.SignUPTCBox>
                </SUStyle.SignUPBoxRow>
                <SUStyle.InvalidCreds>
                    { getErrorMessage() }
                </SUStyle.InvalidCreds>
            </SUStyle.RightPanel>
            {/* <Snackbar open={snackbarError.open} autoHideDuration={3000} severity = { snackbarError.severity } message = {snackbarError.message}>
            </Snackbar> */}
        </SUStyle.ParentBox>
    )
}

export default Login;