import React from "react";
import { useSelector } from "react-redux";

//Material UI and style imports
import * as PropertyDetailsStyle from '../../../styles/HotelInfo/propertyDetailsStyle';


const PropertyDetailInfo = () => {

    const userInfo = useSelector(state => state.hotelDetails.hotelInfo.userInfo)

    const getGreetingText = () => {
        if(userInfo)
            return `Welcome ${userInfo?.name}`;
        else 
            return 'Welcome';
    }
    return (
        <PropertyDetailsStyle.UserSalution>
            <PropertyDetailsStyle.UserWelcome>
                {getGreetingText()}
            </PropertyDetailsStyle.UserWelcome>
            <PropertyDetailsStyle.ProperyInfoMsg>
                Tell us about your place.
            </PropertyDetailsStyle.ProperyInfoMsg>
            {/* <PropertyDetailsStyle.ProperyInfoChangeMsg>
                You can make changes later.
            </PropertyDetailsStyle.ProperyInfoChangeMsg> */}
        </PropertyDetailsStyle.UserSalution>
    )
}

export default PropertyDetailInfo;