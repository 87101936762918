import React from 'react'; 

//Material UI IMports
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

//Style Imports
import * as BCStyle from '../../../styles/HotelInfo/confirmationStyle';


const SubmitConfirmation = ({ open, submitHandler, handleClose }) => {
    return (
        <Dialog onClose = { handleClose } sx = {{ padding:'2rem'}} open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            {/* <DialogTitle id="alert-dialog-title">
            {"Use Google's location service?"}
            </DialogTitle> */}
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                Finalize your submission: Are you ready to proceed?
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{justifyContent:'center'}}>
                <BCStyle.ComfirmButton onClick={ submitHandler } autoFocus>
                    Submit
                </BCStyle.ComfirmButton>
            </DialogActions>
        </Dialog>
    )
}

export default SubmitConfirmation;