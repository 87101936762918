import React from "react";
import { useSelector } from "react-redux";

//Material UI and style Imports
import * as PropertyDetailStyle from '../../styles/HotelInfo/propertyDetailsStyle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from "@mui/material";

//Internal Imports 
import HeadStepper from './HeadStepper/HeadStepper';

const SaveDetails = ({ showBackButton , nextTabHandler, prevTabHandler}) => {

    const activeTabIndex = useSelector(state => state.hotelDetails.activeTab);

    const getButtonText = () => {
        if(activeTabIndex == 7) 
            return 'Submit'
        else 
            return 'Next'
    }

    return (
        <>
            <PropertyDetailStyle.SaveFooter>
                <HeadStepper activeTab={activeTabIndex}/>
                <PropertyDetailStyle.FooterButtonBox>
                    <Box>
                        { showBackButton && 
                            <PropertyDetailStyle.BackButton tabindex = "1" onClick = {prevTabHandler}>
                                {/* <ArrowBackIcon></ArrowBackIcon> */}
                                <p>
                                    Back
                                </p>
                            </PropertyDetailStyle.BackButton>
                        }
                    </Box>
                    <PropertyDetailStyle.SaveButton variant="contained" onClick={nextTabHandler}>
                        { getButtonText() }
                    </PropertyDetailStyle.SaveButton>
                </PropertyDetailStyle.FooterButtonBox>
            </PropertyDetailStyle.SaveFooter>
        </>
    )
}

export default SaveDetails;