import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//Material UI and style imports
import * as PropertyDetailsStyle from '../../../styles/HotelInfo/propertyDetailsStyle';
import * as ADStyle from '../../../styles/HotelInfo/amenitiesDetailsStyle';

//Internal Imports 
import AmenitiesList from './AmenitiesList';
import AmenitiesImages from './AmenitiesImages';
import HeadStepper from '../HeadStepper/HeadStepper';
import SaveDetails from '../SaveDetails';
import API from '../../../api';

//Redux Imports 
import { 
    setActiveTab
 } from '../../../slices/hotelDetails';

const AmenitiesDetails = () => {

    const dispatch = useDispatch();

    const activeTab = useSelector(state => {
        return state.hotelDetails.activeTab;
    })

    const [imageError, setImageError] = useState({
        noMainImage:false,
        noSubImages:false
    })

    const hotelId = useSelector(state => state.hotelDetails.hotelInfo.hotelId);

    const hotelImages = useSelector(state => state.hotelDetails.hotelInfo.hotelImages);

    const { amenitiesList, amenitiesDetails } = useSelector((state) => {
        return {
            amenitiesList : state.hotelDetails.hotelInfo.amenities,
            amenitiesDetails : state.hotelDetails.hotelInfo.amenitiesDetails
        }
    }) 

    const moveToTabNextTab = async () => {
        try {
            let noMainImage = false;
            let noSubImages = false;
            if(hotelImages.find(img => img.photo_type == 'MAIN') == null)
                noMainImage = true;
            if(hotelImages.find(img => img.photo_type == 'MAIN') == null)
                noSubImages = true;
            if(noMainImage || noSubImages) {
                let newObj = {
                    noMainImage,
                    noSubImages
                }
                setImageError(newObj);
                return;
            }

            if(hotelImages.length > 0) {
                await API.post('/addHotelPhoto',hotelImages.filter(img => img.photo_type != 'LOGO'));
            }//.filter(img => img.hotel_photot_id != null));

            const facilities = amenitiesDetails.map((facility) => {
                return {
                    hotel_id : hotelId,
                    facility_id: facility.facility_id
                }
            });

            if(facilities.length > 0) {
                await API.post('/addFacilityForHotel',facilities);
                await API.post('/updateHotelSetupState',{
                    setup_state:(activeTab + 1),
                    hotel_id:hotelId
                });
                dispatch(setActiveTab(activeTab + 1));
            }
            else {
                await API.post('/updateHotelSetupState',{
                    setup_state:(activeTab + 1),
                    hotel_id:hotelId
                });
                dispatch(setActiveTab(activeTab + 1));
            }
        }
        catch(ex) {

        }
    }

    const moveToPrevTab = () => {
        dispatch(setActiveTab(activeTab - 1))
    }

    return (
        <ADStyle.ParentBox>
            <ADStyle.DetailsBox>
                <PropertyDetailsStyle.DetailHeader>
                        Amenities Details
                </PropertyDetailsStyle.DetailHeader>
                <AmenitiesList amenitiesList = { amenitiesList } amenitiesDetails = { amenitiesDetails } />
                <AmenitiesImages imageError = { imageError }/>
            </ADStyle.DetailsBox>
            <SaveDetails showBackButton = {true} nextTabHandler = { moveToTabNextTab} prevTabHandler = {moveToPrevTab}/>
        </ADStyle.ParentBox>
    )
}

export default AmenitiesDetails;