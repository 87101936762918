import React , { useRef, useEffect ,useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

//Material UI and stle imports
import * as PropertyDetailStyle from '../../../styles/HotelInfo/propertyDetailsStyle';
import TextField from '@mui/material/TextField';
import Portal from '@mui/material/Portal'
import * as CommonStyle from '../../../styles/HotelInfo/commonStyle';

//Internal IMports 
import { AWSAPI } from "../../../api";
import API from '../../../api';
import { s3config } from '../../../config/s3config';
import { PREURLIMG } from "../../../config/constants";
import {
    setHotelImages
} from '../../../slices/hotelDetails';

const PropertyNameInfo = ({ focusOutHandler , propertyDetails, refData , changeHandler, errorObject}) => {

    const propertyNameRef = useRef('');
    const fileNameref = useRef('');
    // const propertyNickNameRef = useRef('');
    // const phoneNumberRef = useRef('');
    // const propertyOwnershipRef = useRef('');
    // const registeredNameRef = useRef('');
    // const gstINRef = useRef('');
    // const propertyCategoryRef = useRef('');

    const [showLoader,setShowLoader] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        propertyNameRef.current.value = propertyDetails.name || '';
        //propertyNickNameRef.current.value = propertyDetails.nick_name || '';
        //phoneNumberRef.current.value = propertyDetails.phoneNumber || '';
        //registeredNameRef.current.value = propertyDetails.registered_company_name || '';
        //gstINRef.current.value = propertyDetails.gstin_number || '';
    })

    //Genereate option and Value for Ownership
    let selectedOwnership = null;
    let ownershipOptions = [];
    refData.ownerShipTypes.map(opt => {
        if(opt.ownership_type_id == propertyDetails.ownership_type_id) {
            selectedOwnership = {
                code : opt.ownership_type_id,
                label : opt.ownership_type_name
            };
            ownershipOptions.push(selectedOwnership);
        }
        else {
            ownershipOptions.push({
                code : opt.ownership_type_id,
                label : opt.ownership_type_name
            })
        }
    })

    const hotelImages = useSelector(state => state.hotelDetails.hotelInfo.hotelImages);

    const fileUploadHandler = (event) => {
        let selectedFile = event.target.files[0];
        setShowLoader(true);
        AWSAPI.post('/getSignedUrlForGivenFileNameInGivenBucket',[{
            "bucketName": s3config.bucketName, 
            "fileName": event.target.files[0].name
        }])
        .then(res => {
            let presignedUrl = res.data[0].signedUrl;
            axios.put(presignedUrl, selectedFile, {
              headers: {
                'Content-Type': selectedFile.type,
              },
            })
            .then((res) => {
                let newObj = {
                    photo_type:'LOGO',
                    photo:(PREURLIMG + selectedFile.name)
                }
                let newHotelImages = hotelImages.filter(img => img.photo_type != 'LOGO');
                newHotelImages.push(newObj);
                dispatch(setHotelImages(newHotelImages));
                console.log('Image uploaded successfully!');
                setShowLoader(false);
            })
            .catch((error) => {
                console.error('Error uploading image:', error);
                setShowLoader(false);
            });
        }).catch(ex => {
            setShowLoader(false);
        });
    }
 
    //Generate option and Value for Category
    let selectedCategory = null;
    let categoryOptions = [];
    refData.hotelCategoryTypes.map(opt => {
        if(opt.category_type_id == propertyDetails.category_type_id) {
            selectedCategory = {
                code : opt.category_type_id,
                label : opt.category_type_name
            };
            categoryOptions.push(selectedCategory);
        }
        else {
            categoryOptions.push({
                code : opt.category_type_id,
                label : opt.category_type_name
            })
        }
    })

    //Generate option and Value for Star Category
    let selectedStarCategory = null;
    let starCatOptions = [];
    refData.hotelStarCatTypes.map(opt => {
        if(opt.star_category_type_id == propertyDetails.star_category_type_id) {
            selectedStarCategory = {
                code : opt.star_category_type_id,
                label : opt.star_category_type_name
            };
            starCatOptions.push(selectedStarCategory);
        }
        else {
            starCatOptions.push({
                code : opt.star_category_type_id,
                label : opt.star_category_type_name
            })
        }
    })

    const checkIfError = (name) => {
        if(errorObject[name] == '' && (!propertyDetails[name] || propertyDetails[name] == "")) {
            return {
                error:true
            }
        }
        else if(errorObject[name]) {
            return {
                error:true
            }
        }
    }

    const deleteImage = async (img) => {
        if(img.hotel_photo_id) {
            const resp = await API.post('/deleteHotelPhoto',{
                hotel_photo_id:img.hotel_photo_id
            });
        }
        let newHotelImages = hotelImages.filter(image => image.photo != img.photo);
        //fileNameref.current.value = uuidv4();
        dispatch(setHotelImages(newHotelImages));
    }

    const imageUploadClick = (event) => {
        event.target.value = '';
    }

    const getHotelLogoImage = () => {
        let hotelLogoImage = hotelImages.find(img => img.photo_type == 'LOGO');
        if(hotelLogoImage)      
            return (
                <PropertyDetailStyle.ImgParent>
                    <PropertyDetailStyle.LogoImg src = { hotelLogoImage.photo }></PropertyDetailStyle.LogoImg> 
                    <PropertyDetailStyle.DelImage onClick = {() =>  deleteImage(hotelLogoImage) }/>
                </PropertyDetailStyle.ImgParent>
            )   
    }
      
    return (
        <>
            <PropertyDetailStyle.DetailRow>
                <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                        Property/Hotel Name <p>*</p>
                    </PropertyDetailStyle.DetailColumnLabel>
                    <PropertyDetailStyle.InputField 
                        { ...checkIfError("name")}
                        InputLabelProps = { { shrink: false } } 
                        variant="outlined" 
                        placeholder="Please Enter Property/Hotel Name"
                        name = "name"
                        onBlur = { focusOutHandler }
                        inputRef = { propertyNameRef } 
                        size = "small"
                        helperText = { errorObject['name']}
                        inputProps={{ autoComplete : "nope" }} 
                    >
                    </PropertyDetailStyle.InputField>
                </PropertyDetailStyle.DetialColumn>
                {/* <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                        Property Nickname &nbsp;<span > ( This will appear on your home screen ) </span >
                    </PropertyDetailStyle.DetailColumnLabel>
                    <PropertyDetailStyle.InputField 
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Please Enter Property Nickname"
                        name = "nick_name"
                        onBlur = { focusOutHandler }
                        inputRef = { propertyNickNameRef }
                        size = "small"
                        helperText = { errorObject['nick_name']}
                        inputProps={{ autoComplete : "nope" }} 
                    >
                        
                    </PropertyDetailStyle.InputField>
                </PropertyDetailStyle.DetialColumn> */}
                {/* <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                        Property Ownership<p>*</p>
                    </PropertyDetailStyle.DetailColumnLabel>
                    <PropertyDetailStyle.Dropdown

                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Select Property Ownership"
                        name = "ownership_type_id"
                        onChange = { (event,value) => { changeHandler('ownership_type_id', value )}}
                        value = { selectedOwnership || '' }
                        size="small"
                        options = { ownershipOptions }
                        renderInput={(params) => <TextField { ...checkIfError("ownership_type_id") } {...params} placeholder="Select Property Ownership" />}
                    >
                    </PropertyDetailStyle.Dropdown>
                </PropertyDetailStyle.DetialColumn> */}
            </PropertyDetailStyle.DetailRow>

            {/* <PropertyDetailStyle.DetailRow>
                <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                        Property Phone Number<p>*</p>
                    </PropertyDetailStyle.DetailColumnLabel>
                    <PropertyDetailStyle.InputField
                        { ...checkIfError("phoneNumber")}
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Please Enter Property Phone Number"
                        name = "phoneNumber"
                        onBlur = { focusOutHandler }
                        inputRef = { phoneNumberRef }
                        size = "small"
                        helperText = { errorObject['phoneNumber'] }
                        inputProps={{ autoComplete : "nope" }} 
                    >

                    </PropertyDetailStyle.InputField>
                </PropertyDetailStyle.DetialColumn>
               <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                        Property Ownership<p>*</p>
                    </PropertyDetailStyle.DetailColumnLabel>
                    <PropertyDetailStyle.Dropdown

                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Select Property Ownership"
                        name = "ownership_type_id"
                        onChange = { (event,value) => { changeHandler('ownership_type_id', value )}}
                        value = { selectedOwnership || '' }
                        size="small"
                        options = { ownershipOptions }
                        renderInput={(params) => <TextField { ...checkIfError("ownership_type_id") } {...params} placeholder="Select Property Ownership" />}
                    >
                    </PropertyDetailStyle.Dropdown>
                </PropertyDetailStyle.DetialColumn>
            </PropertyDetailStyle.DetailRow> */}


            {/* <PropertyDetailStyle.DetailRow>
                <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                        Registered Company/Trade Name	
                    </PropertyDetailStyle.DetailColumnLabel>
                    <PropertyDetailStyle.InputField
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Please Enter Registered Company/Trade Name"
                        name = "registered_company_name"
                        onBlur = { focusOutHandler }
                        inputRef = { registeredNameRef }
                        size = "small"
                        inputProps={{ autoComplete : "nope" }} 
                    >

                    </PropertyDetailStyle.InputField>
                </PropertyDetailStyle.DetialColumn>
                <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                        GST Number
                    </PropertyDetailStyle.DetailColumnLabel>
                    <PropertyDetailStyle.InputField 
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Please Enter GSTIN Number"
                        name = "gstin_number"
                        onBlur = { focusOutHandler }
                        inputRef = { gstINRef }
                        size = "small"
                        inputProps={{ autoComplete : "nope" }} 
                    >
                        
                    </PropertyDetailStyle.InputField>
                </PropertyDetailStyle.DetialColumn>
            </PropertyDetailStyle.DetailRow> */}

            <PropertyDetailStyle.DetailRow>
                {/* <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                        Property Category
                    </PropertyDetailStyle.DetailColumnLabel>
                    <PropertyDetailStyle.Dropdown
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Select Property Category"
                        name = "category_type_id"
                        onChange = { (event,value) => { changeHandler('category_type_id', value )}}
                        value = { selectedCategory || '' }
                        size="small"
                        options = { categoryOptions }
                        renderInput={(params) => <TextField {...params} placeholder="Select Property Category" />}
                    >
                    </PropertyDetailStyle.Dropdown>
                </PropertyDetailStyle.DetialColumn> */}
                {/* <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                        Star Category
                    </PropertyDetailStyle.DetailColumnLabel>
                    <PropertyDetailStyle.Dropdown
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Select Star Category"
                        name = "star_category_type_id"
                        onChange = { (event,value) => { changeHandler('star_category_type_id', value )}}
                        value = { selectedStarCategory || '' }
                        size="small"
                        options = { starCatOptions }
                        renderInput={(params) => <TextField {...params} placeholder="Select Star Category" />}
                    >
                    </PropertyDetailStyle.Dropdown>
                </PropertyDetailStyle.DetialColumn> */}
            </PropertyDetailStyle.DetailRow>
            <PropertyDetailStyle.DetailRow>
                <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                        Upload Property/Hotel Logo
                    </PropertyDetailStyle.DetailColumnLabel>
                    <PropertyDetailStyle.LogoUpload onClick={ imageUploadClick } inputRef = { fileNameref } onChange = { fileUploadHandler } type = 'file'></PropertyDetailStyle.LogoUpload>
                    <PropertyDetailStyle.LogoUploadDummy type = 'file'>
                        Drop your image here, or <span>browse</span> 
                    </PropertyDetailStyle.LogoUploadDummy>
                </PropertyDetailStyle.DetialColumn>
                <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                    </PropertyDetailStyle.DetailColumnLabel>
                    { getHotelLogoImage() }
                </PropertyDetailStyle.DetialColumn>
            </PropertyDetailStyle.DetailRow>
            { showLoader && 
                <Portal>
                    <CommonStyle.LoaderBox>
                        <CommonStyle.Loader></CommonStyle.Loader>
                    </CommonStyle.LoaderBox>
                </Portal>
            }
        </>
    )
}

export default PropertyNameInfo;

