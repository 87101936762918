import React from 'react';
import { format } from 'date-fns'; 
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

//Styled Imports 
import * as LMStyle from '../../styles/LeftMenu/leftMenuStyle';
import GridViewIcon from '@mui/icons-material/GridView';
import AssessmentIcon from '@mui/icons-material/Assessment';

//Internal Imports 
import { setActiveLeftMenuID } from '../../slices/hotelDetails';

const LeftMenu = () => {

    const dispatch = useDispatch();

    const activeMenuID = useSelector(state => state.hotelDetails.activeLeftMenuID);

    const history = useHistory();

    const checkActive = (tabID) => {
        if (tabID == activeMenuID )
            return {
                isActive : true
            }
    }

    const setLocationForMenu = (menuID) => {
        switch (menuID.toString()) {
            case "1" : 
                history.push('/dashboard');
                break;
            case "2" :
                history.push('/calendar');
                break;
        }
    }

    //Set Active Left Menu ID
    const menuClickHandler = (menuID) => {
        if (menuID != activeMenuID) {
            dispatch(setActiveLeftMenuID(menuID));
            setLocationForMenu(menuID);
        }
    }

    return (
        <LMStyle.LeftMenuParent>
            {/* <LMStyle.LMHeader>
                hotelio
            </LMStyle.LMHeader>
            <LMStyle.LMDate>
                { format(new Date(),'dd MMM yyyy') }
            </LMStyle.LMDate> */}
            <LMStyle.LMMenuBox { ...checkActive(1) } onClick = {() => menuClickHandler(1)}>
                <LMStyle.IconBox>
                    <LMStyle.IconsDB />
                </LMStyle.IconBox>
                <LMStyle.LMMenuText { ...checkActive(1) }>
                    Dashboard
                </LMStyle.LMMenuText>
            </LMStyle.LMMenuBox>
            <LMStyle.LMMenuBox { ...checkActive(2) } onClick = {() => menuClickHandler(2)}>
                <LMStyle.IconBox>
                    <LMStyle.IconCal />
                </LMStyle.IconBox>
                <LMStyle.LMMenuText { ...checkActive(2) }>
                    Calendar
                </LMStyle.LMMenuText>
            </LMStyle.LMMenuBox>
            {/* <LMStyle.LMMenuBox { ...checkActive(3) } onClick = {() => menuClickHandler(3)}>
                <LMStyle.IconBox>
                    
                </LMStyle.IconBox>
                <LMStyle.LMMenuText { ...checkActive(3) }>
                    Rooms
                </LMStyle.LMMenuText>
            </LMStyle.LMMenuBox>
            <LMStyle.LMMenuBox { ...checkActive(4) } onClick = {() => menuClickHandler(4)}>
                <LMStyle.IconBox>
                        
                </LMStyle.IconBox>
                <LMStyle.LMMenuText { ...checkActive(4) }>
                    Channel
                    Manager
                </LMStyle.LMMenuText>
            </LMStyle.LMMenuBox> */}
            {/* <LMStyle.LMMenuBox { ...checkActive(5) } onClick = {() => menuClickHandler(5)}>
                <LMStyle.IconBox>
                    <AssessmentIcon />
                </LMStyle.IconBox>
                <LMStyle.LMMenuText { ...checkActive(5) }>
                    Reports
                </LMStyle.LMMenuText>
            </LMStyle.LMMenuBox>
            <LMStyle.LMMenuBox { ...checkActive(6) } onClick = {() => menuClickHandler(6)}>
                <LMStyle.IconBox>
                        
                </LMStyle.IconBox>
                <LMStyle.LMMenuText { ...checkActive(6) }>
                    Database
                </LMStyle.LMMenuText>
            </LMStyle.LMMenuBox>
            <LMStyle.LMMenuBox { ...checkActive(7) } onClick = {() => menuClickHandler(7)}>
                <LMStyle.IconBox>
                    <LMStyle.IconsSettings />
                </LMStyle.IconBox>
                <LMStyle.LMMenuText  { ...checkActive(7) }>
                    Settings
                </LMStyle.LMMenuText>
            </LMStyle.LMMenuBox>
            <LMStyle.LMMenuBox { ...checkActive(8) } onClick = {() => menuClickHandler(8)}>
                <LMStyle.IconBox>
                        
                </LMStyle.IconBox>
                <LMStyle.LMMenuText { ...checkActive(8) }>
                    Support
                </LMStyle.LMMenuText>
            </LMStyle.LMMenuBox> */}
        </LMStyle.LeftMenuParent>
    )
}

export default LeftMenu;