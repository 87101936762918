import styled from "styled-components";

import Box from '@mui/material/Box';
import { Button } from "@mui/material";

export const HeaderParentBox = styled(Box) `
&& {
    background-color:white;
    padding: 10px 0;
    display:flex;
    align-items:center;
    height:60px;
    & p {
        font-size:2rem;    
        margin:0;
        color:black;
        padding-left:30px;
        padding-right:30px;
        text-transform:uppercase;
    }
    
    position: sticky;
    top: 0px;
    z-index: 2;
    justify-content:space-between;

}
`
/*    border-bottom:1px solid #dddddd;*/
export const HotelioLogo = styled(Box)`
&& {
    background-image: url(styles/assets/2.png);
    background-repeat: no-repeat;
    height: 60px;
    width: 100%;
    background-size: 10%;
    background-position: 0;
    @media (max-width: 576px) { 
        background-size: 40%;
    }
}`


export const LogoutBtn = styled(Button)`
&& {
    color: #F8F9FA;
    text-transform: capitalize;
    margin-right: 1rem;
    font-family:'Red Hat Display';
    font-size: 1.1rem;
    display:none;
}`