import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

//Materila UI and style imports
import * as PropertyDetailStyle from '../../../styles/HotelInfo/propertyDetailsStyle';
import TextField from '@mui/material/TextField';

const PropertyLocation = ({ focusOutHandler, propertyDetails, refData, changeHandler, errorObject }) => {

    const pincodeRef = useRef('');
    const addressRef = useRef('');

    useEffect(() => {
        pincodeRef.current.value = propertyDetails.pincode || '';
        addressRef.current.value = propertyDetails.address || '';
    })

    //Generate State Options and Selected Value
    let selectedState = null;
    const stateOptions = [];
    refData.states.map(state => {
        if(state == propertyDetails.state) {
            selectedState = {
                code : state,
                label : state
            }
            stateOptions.push(selectedState);
        }
        else {
            stateOptions.push( {
                code : state,
                label : state
            });
        }
    })
    

    //Generate City Options and Selected Value
    let selectedCity = null;
    const citiesToShow = [];
    if(propertyDetails.state) {  
        refData.cities[propertyDetails.state].map(city => {
            if(propertyDetails.city == city) {
                selectedCity = {
                    code : city,
                    label : city
                };
                citiesToShow.push(selectedCity);
            }
            else {
                citiesToShow.push( {
                    code : city,
                    label : city
                });
            }
        })
    }

    //Generate Country Options and Selected Value
    let selectedCountry = null;
    const countryOptions = [];
    // if(propertyDetails.country == '' || propertyDetails.country == null) {
    //     selectedCountry = {
    //         code : 'India',
    //         label : 'India'
    //     }
    //     //countryOptions.push(selectedCountry);
    // }
    
    refData.countries.map(state => {
        
        if(state == propertyDetails.country && selectedCountry == null) {
            selectedCountry = {
                code : state,
                label : state
            }
            countryOptions.push(selectedCountry);
        }
        else {
            countryOptions.push( {
                code : state,
                label : state
            });
        }
    })

    const checkIfError = (name) => {
        if(errorObject[name] == '' && (!propertyDetails[name] || propertyDetails[name] == ""))  {
            return {
                error:true
            }
        }
        else if(errorObject[name]) {
            return {
                error:true
            }
        }
    }

    return (
        <>
            <PropertyDetailStyle.DetailRow>
                <PropertyDetailStyle.LocationLabel>
                    Where is your property located?
                </PropertyDetailStyle.LocationLabel>
            </PropertyDetailStyle.DetailRow>
            <PropertyDetailStyle.DetailRow>
                <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                        Country <p>*</p>
                    </PropertyDetailStyle.DetailColumnLabel>
                    <PropertyDetailStyle.Dropdown
                        disableClearable = "true"
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        label="Please Enter Country"
                        placeholder="Please Enter Country"
                        name = {uuidv4()}
                        onChange = { (event,value) => { changeHandler('country', value )}}
                        value = { selectedCountry || ''}
                        size="small"
                        autoSelect = 'true'
                        options = { countryOptions }
                        id='some randing id to prevent'
                        renderInput={(params) => <TextField autoComplete='new-password' id = 'field1' inputProps={{ autoComplete : "mat de" }} name = {`autocomplete-${uuidv4()}`} { ...checkIfError("country") } {...params} placeholder="Please Enter Country" />}
                    >
                        
                    </PropertyDetailStyle.Dropdown>
                </PropertyDetailStyle.DetialColumn>
                <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                        State <p>*</p>
                    </PropertyDetailStyle.DetailColumnLabel>
                    <PropertyDetailStyle.Dropdown      
                        disableClearable = "true" 
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Please Enter State"
                        name = "state"
                        autoSelect = 'true'
                        onChange = { (event,value) => { changeHandler('state', value )}}
                        value = { selectedState || ''}
                        size="small"
                        options = { stateOptions }
                        renderInput={(params) => <TextField inputProps={{ autoComplete : "nope" }}  name = {`autocomplete-${Math.random()}`} { ...checkIfError("state") } {...params} placeholder="Please Enter State" />}
                    >

                    </PropertyDetailStyle.Dropdown>
                </PropertyDetailStyle.DetialColumn>
                
            </PropertyDetailStyle.DetailRow>
            <PropertyDetailStyle.DetailRow>
                
                <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                        City <p>*</p>
                    </PropertyDetailStyle.DetailColumnLabel>
                    <PropertyDetailStyle.Dropdown  
                        disableClearable = "true"    
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Please Enter City"
                        name = "city"
                        onChange = { (event,value) => { changeHandler('city', value )}}
                        value = { selectedCity || ''}
                        size="small"
                        autoSelect = 'true'
                        options = { citiesToShow }
                        renderInput={(params) => <TextField name = {`autocomplete-${Math.random()}`} inputProps={{ autoComplete : "nope" }} { ...checkIfError("city") } {...params} placeholder="Please Enter City" />}
                    >
                        
                    </PropertyDetailStyle.Dropdown>
                </PropertyDetailStyle.DetialColumn>
                <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                        Pin Code <p>*</p>
                    </PropertyDetailStyle.DetailColumnLabel>
                    <PropertyDetailStyle.InputField
                        { ...checkIfError("pincode") }
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Please Enter Pin Code"
                        name = "pincode"
                        onBlur = { focusOutHandler }
                        inputRef = { pincodeRef }
                        
                        size="small"
                        inputProps={{ autoComplete : "nope" }}
                    >

                    </PropertyDetailStyle.InputField>
                </PropertyDetailStyle.DetialColumn>
            </PropertyDetailStyle.DetailRow>
            <PropertyDetailStyle.DetailRow>
                <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                        Property Full Address<p>*</p>
                    </PropertyDetailStyle.DetailColumnLabel>
                    <PropertyDetailStyle.MultiLIneInputField 
                        { ...checkIfError("address") }
                        multiline 
                        rows={3}
                        InputLabelProps={{ shrink: false }} 
                        variant="outlined" 
                        placeholder="Please Enter Full Property Address"
                        name = "address"
                        onBlur = { focusOutHandler }
                        inputRef = { addressRef }
                        size="small"
                        inputProps={{ autoComplete : "nope" }}
                    >
                    </PropertyDetailStyle.MultiLIneInputField>
                </PropertyDetailStyle.DetialColumn>
            </PropertyDetailStyle.DetailRow>
        </>
    )
}

export default PropertyLocation;