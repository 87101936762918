import { configureStore, combineReducers } from "@reduxjs/toolkit";

import login from './login';
import hotelDetails from "./hotelDetails";
import bookingDetails from "./bookingDetails";
import dashboardInfo from './dashboardInfo';
import calendarInfo from "./calendarInfo";

const reducer = combineReducers({
    login,
    hotelDetails,
    bookingDetails,
    dashboardInfo,
    calendarInfo,
});

export default configureStore({
    reducer
});