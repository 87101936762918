import React, { useEffect , useRef, useState,forwardRef, useImperativeHandle} from "react";
import * as yup from 'yup';

//Internal Imports 
import { POSITIVE_REGEX,ROOM_COUNT_REGEX, ROOM_NUM_REGEX } from '../../../config/constants'

//Material UI and Style Imports
import * as RDetailStyle from '../../../styles/HotelInfo/roomDetailsStyle';
import { TextField } from "@mui/material";
import { createFilterOptions } from '@mui/material/Autocomplete'

const RoomInfo  = forwardRef ( ({ imagesList, refData, setRoomDetailsInfo, existinRoomCats, 
    cancelEditRoomHandler, editRoomInfo, selectedRoomInfo, setImageListError }, ref ) => {

    // const roomDescRef = useRef('');
    const roomCountRef = useRef('');
    //const roomNumRef = useRef('');
    // const minOccRef = useRef('');
    const maxOccRef = useRef('');
    const priceRef = useRef('');
    const doubleOccRef = useRef('');
    const extraBedRef = useRef('');

    const mandatoryProps = [
        'roomCategory',
        'roomCount',
        // 'roomNum',
        'price',
        'doubleOccupancyPrice',
        'extraBedPrice'
    ];

    const [roomInfo, setRoomInfo] = useState(selectedRoomInfo);
    const [errorObject , setErrorObj] = useState({});
    const [ roomCatInputValue, setRoomCatInputValue ] = useState('');

    const dropDownChangeHandler = (name,value) => {
        setRoomInfo((prevState) => {
            return {
                ...prevState,
                [name]:value.code
            }
        })
    }

    const yupObject = yup.object({
        //price:yup.string().matches(POSITIVE_REGEX,'Please enter valid Price.'),
        price: yup.number().typeError('Price must be a number').positive('Price must be positive')
            .test('decimal-places', 'Price must have up to 2 decimal places', (value) => {
            if (value === undefined || value === null) {
                return true; 
            }
            return /^\d+(\.\d{1,2})?$/.test(value.toString());
            }),
        doubleOccupancyPrice:yup.number().typeError('Price must be a number').positive('Price must be positive')
            .test('decimal-places', 'Price must have up to 2 decimal places', (value) => {
            if (value === undefined || value === null) {
                return true; 
            }
            return /^\d+(\.\d{1,2})?$/.test(value.toString());
            }),
            extraBedPrice:yup.number().typeError('Price must be a number').positive('Price must be positive')
            .test('decimal-places', 'Price must have up to 2 decimal places', (value) => {
            if (value === undefined || value === null) {
                return true;
            }
            return /^\d+(\.\d{1,2})?$/.test(value.toString());
            }),
        roomCount:yup.string().matches(ROOM_COUNT_REGEX)
    })

    //Room Category Change Handler
    const roomCatChangeHandler = (name,value) => {
        if(value) {
            setRoomInfo((prevState) => {
                return {
                    ...prevState,
                    [name]:value.code,
                    roomCategoryName:value.label
                }
            })
        }
    }

    //Add Room Click Handler
    const addRoom = (event) => {
        validateInfo();
    }

    const focusOutHandler = (event) => {
        setRoomInfo((prevState) => {
            return {
                ...prevState,
                [event.target.name]:event.target.value
            }
        })
    }

    useEffect(() => {
        // roomDescRef.current.value = roomInfo.roomDesc || '';
        //roomNumRef.current.value = roomInfo.roomNum || '';
        roomCountRef.current.value = roomInfo.roomCount || '';
        // minOccRef.current.value = roomInfo.minOcc || '';
        maxOccRef.current.value = roomInfo.maxOcc || 3;
        priceRef.current.value = roomInfo.price || '';
        doubleOccRef.current.value = roomInfo.doubleOccupancyPrice || '';
        extraBedRef.current.value = roomInfo.extraBedPrice || '';
        return () => {

        }
    });

    //Generate room category options.
    let selectedRoomCategory = null;
    let roomCategoryOptions = [];
    refData.roomCategories.map(cat => {
        if(cat.code == roomInfo.roomCategory) {
            selectedRoomCategory = cat;
            roomCategoryOptions.push(selectedRoomCategory);
        }
        else {
            if(existinRoomCats.indexOf(cat.label) == -1) 
                roomCategoryOptions.push(cat);
        }
        
    });

    //Set SeelctedBed Type Option
    let selectedBedType = null;
    refData.bedType.map((bed) => {
        if(bed.code == roomInfo.bedType)
            selectedBedType = bed;
    });

    //Cancel Edit Mode
    const cancelEditRoomInfo = () => {
        cancelEditRoomHandler();
    }

    //Check if Room Category is REad Only
    const isReadOnly = (roomCategory) => {
        if(roomCategory)
            return true;
        else 
            return false;
    }

    //Validation on Save
    const validateInfo = () => {
        //let isvalid = await propertySchema.isValid(propertyDetails);
        let errorObj = {};
        let hasError = false;
        mandatoryProps.map((prop) => {
            if(!roomInfo[prop] || roomInfo[prop] === ''){
                hasError = true;
                errorObj[prop] = '';
            }
        })
        if(hasError) {
            setErrorObj((prevObj) => ({
                ...errorObj  
            }));
            return hasError;
        }
        
        if(imagesList.length == 0) {
            setImageListError(true);
            return false;
        }
        yupObject.validate(roomInfo,{ abortEarly:false})
        .then((res) => {
            // if(roomInfo['roomNum'] && roomInfo['roomCount'] && (roomInfo['roomCount']) != roomInfo['roomNum'].split(',').length) {
            //     errorObj['roomNum'] = 'Room numbers should be equal to Room count.'
            //     setErrorObj((prevObj) => ({
            //         ...errorObj  
            //     }));
            //     return hasError;
            // }
            setRoomDetailsInfo( {
                roomCategory : roomInfo.roomCategoryName,
                info: { ...roomInfo,imagesList ,attributes:{}}
            });
            setRoomInfo({});
            setErrorObj({});
            setRoomCatInputValue('');
            setImageListError(false);
        }).catch((err) => {
            let obj = {};
            const errors = err.inner.reduce((acc, error) => {
                return {
                    ...acc,
                    [error.path]: true,
                }
                }, {});
            if(errors['price']) {
                hasError = true;
                obj['price'] = 'Please enter a valid Price.';
            }
            if(errors['doubleOccupancyPrice']) {
                hasError = true;
                obj['doubleOccupancyPrice'] = 'Please enter a valid Price.';
            }
            if(errors['extraBedPrice']) {
                hasError = true;
                obj['extraBedPrice'] = 'Please enter a valid Price.';
            }
            if(errors['roomCount']) {
                hasError = true;
                obj['roomCount'] = 'Please enter a valid room count.';
            }
            if(errors['roomNum']) {
                hasError = true;
                obj['roomNum'] = 'Please enter valid Room Numbers.';
            }
            if(hasError) {
                setErrorObj((prevObj) => ({
                    ...obj  
                }));
            }
        })
    }
    

    const checkIfError = (name) => {
        if(errorObject[name] == '' && (!roomInfo[name] || roomInfo[name] == ""))
            return {
                error:true
            }
        else if(errorObject[name]) {
            return {
                error : true
            }
        }
    }

    const filter = createFilterOptions();

    useImperativeHandle(ref, () => ({
        addRoomRef:() => {
            addRoom();
        },
        cancelEditRoomInfoRef:() => {
            cancelEditRoomInfo()
        }
    }))

    return (
        <RDetailStyle.RoomInfoParent>
            <RDetailStyle.DetailRow>
                <RDetailStyle.DetialColumn>
                    <RDetailStyle.DetailColumnLabel>
                        Room Category <p>*</p>
                    </RDetailStyle.DetailColumnLabel>
                    <RDetailStyle.Dropdown
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.label);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    code:(Math.floor(Math.random() * 900) + 101) * -1,
                                    label: `${inputValue}`,
                                });
                            }
                            return filtered;
                        }}
                        freeSolo
                        inputValue= { roomCatInputValue }
                        onInputChange={(event, newInputValue) => {
                            setRoomCatInputValue(newInputValue);
                        }}
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Select Room Category"
                        size="small"
                        options={ roomCategoryOptions }
                        onChange = { (event,value) => { roomCatChangeHandler('roomCategory',value) }}
                        name="roomCategory"
                        value = { selectedRoomCategory  || ''}
                        readOnly = { isReadOnly(selectedRoomInfo.roomCategory) }
                        renderInput={(params) => <TextField  { ...checkIfError("roomCategory")} {...params} placeholder="Select Room Category" />}
                    >
                    </RDetailStyle.Dropdown> 
                </RDetailStyle.DetialColumn>
            </RDetailStyle.DetailRow>
            {/* <RDetailStyle.DetailRow>
                <RDetailStyle.DetialColumn>
                    <RDetailStyle.DetailColumnLabel>
                        Room Description
                    </RDetailStyle.DetailColumnLabel>
                    <RDetailStyle.InputField 
                        InputLabelProps={{shrink: false}} 
                        multiline
                        maxRows={4}
                        rows={2}
                        variant="outlined" 
                        placeholder="Please Enter Room Description"
                        size="small"
                        inputRef = { roomDescRef }
                        onBlur = { focusOutHandler }
                        name = 'roomDesc'
                    >
                    </RDetailStyle.InputField>
                </RDetailStyle.DetialColumn>
            </RDetailStyle.DetailRow>
            <RDetailStyle.DetailRow>
                <RDetailStyle.DetialColumn>
                    <RDetailStyle.DetailColumnLabel>
                        Bed Type
                    </RDetailStyle.DetailColumnLabel>
                    <RDetailStyle.Dropdown
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Please Select Bed Type"
                        label="Please Select Bed Type"
                        size="small"
                        options={ refData.bedType }
                        onChange = { (event,value) => { dropDownChangeHandler('bedType',value) }}
                        name="bedType"
                        value = { selectedBedType || ''}
                        renderInput={(params) => <TextField { ...checkIfError("bedType")}  {...params} placeholder="Please Select Bed Type" />}
                    >
                    </RDetailStyle.Dropdown> 
                </RDetailStyle.DetialColumn>
            </RDetailStyle.DetailRow> */}
            {/* <RDetailStyle.DetailRow>
                <RDetailStyle.DetialColumn>
                    <RDetailStyle.DetailColumnLabel>
                        Room Size
                    </RDetailStyle.DetailColumnLabel>
                    <RDetailStyle.RoomSizeBox>
                        <RDetailStyle.InputField 
                            InputLabelProps={{shrink: false}} 
                            variant="outlined" 
                            placeholder="Length"
                            size="small"
                        >
                        </RDetailStyle.InputField>
                        <RDetailStyle.InputField 
                            InputLabelProps={{shrink: false}} 
                            variant="outlined" 
                            placeholder="Breadth"
                            size="small"
                        >
                        </RDetailStyle.InputField>
                        <RDetailStyle.InputField 
                            InputLabelProps={{shrink: false}} 
                            variant="outlined" 
                            placeholder="sq ft"
                            size="small"
                        >
                        </RDetailStyle.InputField>
                    </RDetailStyle.RoomSizeBox>
                </RDetailStyle.DetialColumn>
            </RDetailStyle.DetailRow> */}
            <RDetailStyle.DetailRow>
                <RDetailStyle.DetialColumn>
                    <RDetailStyle.DetailColumnLabel>
                        Room Count <p>*</p>
                    </RDetailStyle.DetailColumnLabel>
                    <RDetailStyle.InputField 
                        autoComplete='off'
                        { ...checkIfError("roomCount")}
                        type="email"
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Room Count"
                        size="small"
                        inputRef = { roomCountRef }
                        onBlur = { focusOutHandler }
                        name = 'roomCount'
                        helperText = { errorObject['roomCount'] }
                    >
                    </RDetailStyle.InputField>
                </RDetailStyle.DetialColumn>
            </RDetailStyle.DetailRow>
            {/* <RDetailStyle.DetailRow>
                <RDetailStyle.DetialColumn>
                    <RDetailStyle.DetailColumnLabel>
                        Room Number <p>*</p>
                    </RDetailStyle.DetailColumnLabel>
                    <RDetailStyle.InputField 
                        autoComplete='off'
                        { ...checkIfError("roomNum")}
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Room Number separated by comma"
                        size="small"
                        inputRef = { roomNumRef }
                        onBlur = { focusOutHandler }
                        name = 'roomNum'
                        helperText = { errorObject['roomNum'] }
                    >
                    </RDetailStyle.InputField>
                </RDetailStyle.DetialColumn>
            </RDetailStyle.DetailRow> */}
            {/* <RDetailStyle.DetailRow>
                <RDetailStyle.DetialColumn>
                    <RDetailStyle.DetailColumnLabel>
                        Min Occupancy
                    </RDetailStyle.DetailColumnLabel>
                    <RDetailStyle.InputField 
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Please Select Min Occupancy"
                        size="small"
                        inputRef = { minOccRef }
                        onBlur = { focusOutHandler }
                        name = 'minOcc'
                    >
                    </RDetailStyle.InputField>
                </RDetailStyle.DetialColumn>
            </RDetailStyle.DetailRow> */}
            <RDetailStyle.DetailRow>
                <RDetailStyle.DetialColumn>
                    <RDetailStyle.DetailColumnLabel>
                        Max Occupancy
                    </RDetailStyle.DetailColumnLabel>
                    <RDetailStyle.InputField 
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Please Select Max Occupancy"
                        size="small"
                        inputRef = { maxOccRef }
                        onBlur = { focusOutHandler }
                        name = 'maxOcc'
                    >
                    </RDetailStyle.InputField>
                </RDetailStyle.DetialColumn>
            </RDetailStyle.DetailRow>
            <RDetailStyle.DetailRow>
                <RDetailStyle.DetialColumn>
                    <RDetailStyle.DetailColumnLabel>
                        Room Tariff Single Occupancy (Including Taxes)<p>*</p>
                    </RDetailStyle.DetailColumnLabel>
                    <RDetailStyle.InputField 
                        autoComplete='off'
                        { ...checkIfError("price")}
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Please Enter Price"
                        size="small"
                        inputRef = { priceRef }
                        onBlur = { focusOutHandler }
                        name = 'price'
                        helperText = { errorObject['price'] }
                    >
                    </RDetailStyle.InputField>
                </RDetailStyle.DetialColumn>
            </RDetailStyle.DetailRow>
            <RDetailStyle.DetailRow>
                <RDetailStyle.DetialColumn>
                    <RDetailStyle.DetailColumnLabel>
                        Room Tariff Double Occupancy (Including Taxes)<p>*</p>
                    </RDetailStyle.DetailColumnLabel>
                    <RDetailStyle.InputField 
                        autoComplete='off'
                        { ...checkIfError("doubleOccupancyPrice")}
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Please Enter Price"
                        size="small"
                        inputRef = { doubleOccRef }
                        onBlur = { focusOutHandler }
                        name = 'doubleOccupancyPrice'
                        helperText = { errorObject['doubleOccupancyPrice'] }
                    >
                    </RDetailStyle.InputField>
                </RDetailStyle.DetialColumn>
            </RDetailStyle.DetailRow>
            <RDetailStyle.DetailRow>
                <RDetailStyle.DetialColumn>
                    <RDetailStyle.DetailColumnLabel>
                        Room Tariff for Triple Occupancy (Including Taxes)<p>*</p>
                    </RDetailStyle.DetailColumnLabel>
                    <RDetailStyle.InputField 
                        autoComplete='off'
                        { ...checkIfError("extraBedPrice")}
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Please Enter Price"
                        size="small"
                        inputRef = { extraBedRef }
                        onBlur = { focusOutHandler }
                        name = 'extraBedPrice'
                        helperText = { errorObject['extraBedPrice'] }
                    >
                    </RDetailStyle.InputField>
                </RDetailStyle.DetialColumn>
            </RDetailStyle.DetailRow>
            {/* <RDetailStyle.DetailRow>
                <RDetailStyle.RoomButtonBar>
                    { !editRoomInfo && 
                        <RDetailStyle.AddRoom variant="contained" size = "small" onClick={ addRoom }>
                            + Add Room Category
                        </RDetailStyle.AddRoom>
                    }
                    { editRoomInfo && 
                        <>
                            <RDetailStyle.CancelRoom variant="contained" size = "small" onClick={ cancelEditRoomInfo }>
                                Cancel
                            </RDetailStyle.CancelRoom>
                            <RDetailStyle.AddRoom variant="contained" size = "small" onClick={ addRoom }>
                                Save
                            </RDetailStyle.AddRoom>
                        </>
                    }
                </RDetailStyle.RoomButtonBar>
            </RDetailStyle.DetailRow> */}
            
        </RDetailStyle.RoomInfoParent>
    )
});

export default RoomInfo;