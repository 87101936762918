import styled from 'styled-components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Button, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import OutlinedInput, { outlinedInputClasses } from '@mui/material/OutlinedInput';

export const GuestDetialBox = styled(Box)`
&& {
    margin:0 2rem;
}`

export const GuestHeader = styled(Typography)`
&& {
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    letter-spacing: 0.2px;
    color: #031633;
    margin:1.5rem 0;
}`

export const GuestBody = styled(Box)`
&& {

}`

export const GDGrid = styled(Grid)`
&& {

}`

export const GDGridItem = styled(Grid)`
&& {

}`

export const GDLabelParent = styled(Box)`
&& {
    display:flex;
    margin-bottom:0.5rem;
}`

export const GDComponentParent = styled(Box)`
&& {
    display:flex;
    position:relative;
}`

export const GDLabelMandatory = styled(Typography)`
&& {
    font-size:0.85rem;
    color:red;
}`

export const GDLabel = styled(Typography)`
&& {
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 0.85rem;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01rem;
    color: #354665;
}`

export const GDTextBox = styled(TextField)`
&& {
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 0.85rem;
    line-height: 18px;
    color: #718BAE;
    opacity: 0.5;
    width:95%;
}`

export const GDAutoComplete = styled(Autocomplete)`
&& {
    width:95%;
}`

export const GDFileUpload = styled.input`
&& {
    width:95%;
    border:1px dashed #A6BCDA;
    background: #F0F6FF;
    height:36px;
    border-radius:5px;
    position:absolute;
    z-index:2;
    opacity:0;
    cursor:pinter;
}`

export const GDMatFileUploadDummy = styled(Box)`
&& {
    width:95%;
    border:1px dashed #A6BCDA;
    background: #F0F6FF;
    height:36px;
    border-radius:5px;
    position:relative;
    z-index:1;
    background-image:url(styles/assets/upload.svg);
    background-repeat:no-repeat;
    background-position:50%;
    cursor:pinter;
}`

export const GuestFooter = styled(Box)`
&& {
    display:flex;
    justify-content:center;
    padding:2rem 0;
}`

export const BtnAddGuest = styled(Button)`
&& {
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 18px;
    color: #FFFFFF;
    text-transform:capitalize;
    padding: 0.5rem 1.5rem;
}`

export const GDIndiaCode = styled(Box)`
&& {
    position:absolute;
    background: #F2F4F7;
    border-right: 1px solid #d9dadb;
    color:#052C65;
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    height: 100%;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}`

export const GDContactTextBox = styled(TextField)`
&& {
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 0.85rem;
    line-height: 18px;
    color: #718BAE;
    opacity: 0.5;
    width:95%;
    & .MuiOutlinedInput-input{
        padding-left:45px;
    }
}`