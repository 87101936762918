import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { object, string, number } from 'yup';
import * as yup from 'yup';

//Material UI and Style Imports.
import * as PropertyDetailsStyle from '../../../styles/HotelInfo/propertyDetailsStyle';


//Internal Imports
import PropertyDetailInfo from './PropertyDetailInfo';
import PropertyNameInfo from './PropertyNameInfo';
import PropertyLocation from './PropertyLocation';
import SaveDetails from '../SaveDetails';
import HeadStepper from '../HeadStepper/HeadStepper';
import API from '../../../api';
import { MOBILE_REGEX,NAME_REGEX_SEC } from '../../../config/constants';

//REdux Imports 
import hotelDetails, { 
    setActiveTab, 
    setPropertyDetails,
    setActiveTabandHotelId 
} from '../../../slices/hotelDetails';



const PropertyDetails = () => {

    const mandatoryProps = [
        'name',
        // 'ownership_type_id',
        'pincode',
        'city',
        'state',
        'address',
        'country'
    ];

    const activeTab = useSelector(state => {
        return state.hotelDetails.activeTab;
    })

    const hotelImages = useSelector(state => state.hotelDetails.hotelInfo.hotelImages);

    const [errorObject , setErrorObj] = useState({});

    const propertyDetails = useSelector(state => {
        return state.hotelDetails.hotelInfo.propertyDetails;
    })

    let hotelId = useSelector(state => state.hotelDetails.hotelInfo.hotelId);

    const userInfo = useSelector(state => state.hotelDetails.hotelInfo.userInfo);

    const refData = useSelector(state => {
        return {
            cities:state.hotelDetails.hotelInfo.cities,
            uts:state.hotelDetails.hotelInfo.uts,
            countries:state.hotelDetails.hotelInfo.countries,
            states:state.hotelDetails.hotelInfo.states,
            hotelStarCatTypes:state.hotelDetails.hotelInfo.hotelStarCatTypes,
            hotelCategoryTypes:state.hotelDetails.hotelInfo.hotelCategoryTypes,
            ownerShipTypes:state.hotelDetails.hotelInfo.ownerShipTypes
        }
    })

    const dispatch = useDispatch();

    const moveToTabNextTab = () => {
        validateInfo();
    }

    const saveTabInfo = async (isNotValid) => {
        try {
            if(isNotValid)
                return false;
            let hotelInfoObj = {...propertyDetails};
            if(hotelId === -1) {
                hotelInfoObj.hotel_name = hotelInfoObj.name;
                hotelInfoObj.hotel_nick_name = hotelInfoObj.nick_name;
                hotelInfoObj.attributes = JSON.stringify({});
                hotelInfoObj.logo_image_location = '';
                hotelInfoObj.ownership_type_id = 1;
                hotelInfoObj.registered_company_name = '';
                const resp = await API.post('/createHotel',hotelInfoObj);
                hotelId = resp.data.data.insertId;
                await API.post('/updateHotelIdForEmployee',{
                    hotel_id:hotelId,
                    employee_id:userInfo.employeeId
                });
            }
            else {
                hotelInfoObj.hotel_id = hotelId;
                hotelInfoObj.ownership_type_id = 1;
                hotelInfoObj.registered_company_name = '';
                hotelInfoObj.hotel_nick_name = hotelInfoObj.name;
                await API.post('/updateHotel',hotelInfoObj);
            }
            
            let phoneNumber = propertyDetails.phoneNumber;
            let hotelContactDetails = []
            phoneNumber.split(',').map(phone => {
                hotelContactDetails.push({
                    hotel_id:hotelId,
                    contact_type:'Phone',
                    contact_value:phone,
                    attributes:null
                })
            });
            let hotelLogoImage = {...hotelImages.find(img => img.photo_type == 'LOGO')};
            if(hotelLogoImage)  {
                hotelLogoImage.hotel_id = hotelId;
                await API.post('/addHotelPhoto',hotelLogoImage);
            }

            let promiseArr = [];
            hotelContactDetails.map(contact => {
                promiseArr.push(API.post('/addContactDetailForHotel',contact));
            })
            Promise.all(promiseArr).then(() => {

            });
            await API.post('/updateHotelSetupState',{
                setup_state:(activeTab + 1),
                hotel_id:hotelId
            });
            dispatch(setActiveTabandHotelId({
                activeTab : (activeTab + 1),
                hotelId:hotelId
            }));
        }   
        catch(ex){
            console.log(ex);
            var a = 1;
        }
    }

    const moveToPrevTab = () => {
        dispatch(setActiveTab(activeTab - 1))
    }

    const focusOutHandler = (event) => {
        dispatch(setPropertyDetails({
            name:event.target.name ,
            value: event.target.value
        }))
    }

    const changeHandler = (name,value) => {
        dispatch(setPropertyDetails({
            name:name ,
            value:value.code
        }))
    }

    //Validation on Save
    const validateInfo = () => {
        let errorObj = {};
        let hasError = false;
        mandatoryProps.map((prop) => {
            if(!propertyDetails[prop] || propertyDetails[prop] === ''){
                hasError = true;
                errorObj[prop] = '';
            }
        })
        if(hasError) {
            setErrorObj((prevObj) => ({
                ...errorObj  
            }));
            return;
        }
        yupObject.validate(propertyDetails,{ abortEarly:false})
        .then((res) => {
            saveTabInfo(false);
        }).catch((err) => {
            let obj = {};
            const errors = err.inner.reduce((acc, error) => {
                return {
                    ...acc,
                    [error.path]: true,
                }
                }, {});
            if(errors['phoneNumber']) {
                hasError = true;
                obj['phoneNumber'] = 'Please enter a valid Phone Number';
            }
            if(errors['name']) {
                hasError = true;
                obj['name'] = 'Invalid Name.Only alphabets and numbers allowed.';
            }
            if(errors['nick_name']) {
                hasError = true;
                obj['nick_name'] = 'Invalid Name.Only alphabets and numbers allowed.';
            }
            if(hasError) {
                setErrorObj((prevObj) => ({
                    ...obj  
                }));
            }
            else 
                saveTabInfo(hasError);
        })
        return hasError;
    }

    //Property Object Schema for Validation.
    let yupObject = yup.object({
        //phoneNumber:yup.string().required().matches(MOBILE_REGEX,'Please enter a valid Phone Number.'),
        name:yup.string().matches(NAME_REGEX_SEC)
    });
    
    return (
        <PropertyDetailsStyle.PropertyDetailsParentBox>
            
            <PropertyDetailInfo />
            <PropertyDetailsStyle.DetailsBox>
                <PropertyDetailsStyle.DetailHeader>
                    Property Details
                </PropertyDetailsStyle.DetailHeader>
                <PropertyNameInfo errorObject = { errorObject } refData = { refData } focusOutHandler = { focusOutHandler } propertyDetails = { propertyDetails } changeHandler = { changeHandler }/>
                <PropertyLocation errorObject = { errorObject } refData = { refData } focusOutHandler = { focusOutHandler } propertyDetails = { propertyDetails } changeHandler = { changeHandler }/>
                
            </PropertyDetailsStyle.DetailsBox>
            <SaveDetails showBackButton = {false} nextTabHandler = { moveToTabNextTab} prevTabHandler = {moveToPrevTab}/>
        </PropertyDetailsStyle.PropertyDetailsParentBox>
    )
}

export default PropertyDetails;