//External Imports
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

//Internal Imports
import API from '../../api';
import {
    setRoomSummary
} from '../../slices/dashboardInfo';
import { setHotelPropInfo } from '../../slices/hotelDetails';

//MUI and CSS Imports
import * as DTStyle from '../../styles/Dashboard/dashboardTabStyle';
import { DASHBOARDTAB } from '../../config/constants';
import { setDashboardTab } from '../../slices/dashboardInfo';


const CurrentBookingInfo = () => {

    const dispatch = useDispatch();

    //Get active dashboard tab.
    const activeTab = useSelector(state => state.dashboardInfo.activeDashboardTab);
    const hotelId = useSelector(state => state.dashboardInfo.hotelId);
    const roomSummary = useSelector(state => state.dashboardInfo.roomSummary);

    const [date, setDate] = useState(new Date().toISOString());

    //get current booking info 
    // useEffect(() => {
    //     let promiseArr = [];
    //     promiseArr.push(API.get(`/getPendingCheckins?hotel_id=${hotelId}&check_in_date=${date}`));
    //     promiseArr.push(API.get(`/getCheckedInInfo?hotel_id=${hotelId}&check_in_date=${date}`));
    //     promiseArr.push(API.get(`/getCheckoutInfo?hotel_id=${hotelId}&check_in_date=${date}`));
    //     promiseArr.push(API.get(`/getCancelledInfo?hotel_id=${hotelId}&check_in_date=${date}`));
    //     promiseArr.push(API.get(`/getNoShowInfo?hotel_id=${hotelId}&check_in_date=${date}`));

    //     Promise.all(promiseArr).then((resp) => {

    //     })
    // },[])

    //Get selected tab prop
    const isTabSelected = (currentTab) => { 
        if(activeTab == currentTab) {
            return {
                isSelected:true
            };
        }
    }

    const onTabClick = (currentTab) => {
        dispatch(setDashboardTab(currentTab));
    }

    const onDateChange = (date) => {
        let newDate = dayjs(new Date(date)).format("YYYY-MM-DD");
        setDate(newDate);
        let promiseArr = [];
        promiseArr.push(API.get(`/getHotelDetailsById?hotel_id=${hotelId}`));
        promiseArr.push(API.get(`/getPendingCheckIn?hotel_id=${hotelId}&date=${newDate}`));
        promiseArr.push(API.get(`/getCheckedInInfo?hotel_id=${hotelId}&date=${newDate}`));
        promiseArr.push(API.get(`/getCheckoutInfo?hotel_id=${hotelId}&date=${newDate}`));
        promiseArr.push(API.get(`/getCancelledInfo?hotel_id=${hotelId}&date=${newDate}`));
        promiseArr.push(API.get(`/getNoShowInfo?hotel_id=${hotelId}&date=${newDate}`));

        Promise.all(promiseArr).then((res) => {
            let propertyDetails = res[0].data.data[0];
            let pendingCheckIns = res[1].data.data;
            let checkIns = res[2].data.data;
            let checkOuts = res[3].data.data;
            let cancelledBookings = res[4].data.data;
            let noShows = res[5].data.data;
            dispatch(setHotelPropInfo({
                propertyDetails
            }));
            dispatch(setRoomSummary({
                roomSummary:{
                    pendingCheckIns,
                    checkIns,
                    checkOuts,
                    cancelledBookings,
                    noShows
                }
            }));
            setDate(newDate);
        });
    }

    return (
        <DTStyle.DashboardTabParent>
            <DTStyle.DashboardTabBody>
                <DTStyle.DashboardFirstTab onClick = { (event) => { onTabClick(DASHBOARDTAB.PENDING) } } 
                    { ...isTabSelected(DASHBOARDTAB.PENDING) }>
                    <DTStyle.TabText>
                        Pending Check In
                    </DTStyle.TabText>
                    <DTStyle.TabCount { ...isTabSelected(DASHBOARDTAB.PENDING) }> { roomSummary.pendingCheckIns.length } </DTStyle.TabCount>
                </DTStyle.DashboardFirstTab>
                <DTStyle.DashboardTab onClick = { (event) => { onTabClick(DASHBOARDTAB.CHECKEDIN) } } 
                    { ...isTabSelected(DASHBOARDTAB.CHECKEDIN) }>
                    <DTStyle.TabText>
                        Checked In
                    </DTStyle.TabText>
                    <DTStyle.TabCount { ...isTabSelected(DASHBOARDTAB.CHECKEDIN) }> { roomSummary.checkIns.length } </DTStyle.TabCount>
                </DTStyle.DashboardTab>
                <DTStyle.DashboardTab onClick = { (event) => { onTabClick(DASHBOARDTAB.CHECKEDOUT) } } 
                    { ...isTabSelected(DASHBOARDTAB.CHECKEDOUT) }>
                    <DTStyle.TabText>
                        Checked Out
                    </DTStyle.TabText>   
                    <DTStyle.TabCount { ...isTabSelected(DASHBOARDTAB.CHECKEDOUT) }> { roomSummary.checkOuts.length } </DTStyle.TabCount>
                </DTStyle.DashboardTab>
                <DTStyle.DashboardTab onClick = { (event) => { onTabClick(DASHBOARDTAB.CANCELLED) } } 
                    { ...isTabSelected(DASHBOARDTAB.CANCELLED) }>
                    <DTStyle.TabText>
                        Cancelled
                    </DTStyle.TabText>       
                    <DTStyle.TabCount { ...isTabSelected(DASHBOARDTAB.CANCELLED) }> { roomSummary.cancelledBookings.length } </DTStyle.TabCount>
                </DTStyle.DashboardTab>
                <DTStyle.DashboardLastTab onClick = { (event) => { onTabClick(DASHBOARDTAB.NOSHOW) } } 
                    { ...isTabSelected(DASHBOARDTAB.NOSHOW) }>
                    <DTStyle.TabText>
                        No Show
                    </DTStyle.TabText>   
                    <DTStyle.TabCount { ...isTabSelected(DASHBOARDTAB.NOSHOW) }> { roomSummary.noShows.length } </DTStyle.TabCount>
                </DTStyle.DashboardLastTab>
            </DTStyle.DashboardTabBody>
            <DTStyle.DashboardTabDateParent>
                <DTStyle.BookingDatePicker value={dayjs(date,'YYYY/MM/DD')} onChange={ onDateChange } />
            </DTStyle.DashboardTabDateParent>
        </DTStyle.DashboardTabParent>
    )
}

export default CurrentBookingInfo;