import { createSlice } from "@reduxjs/toolkit";
import { nextDay } from 'date-fns'; 

const dateRange = [];


const today = new Date()
let tomorrow =  new Date()
tomorrow.setDate(today.getDate() + 1)

dateRange.push(today);
dateRange.push(tomorrow);

const initialState = {
    hotelDetails:{},
    hotelPhotos:[],
    hotelfacilities:[],
    hotelRules:[],
    hotelRooms:[],
    hotelCategory:[],
    hotelRoomImages:[],
    selectionCriteria:{
        dateRange,
        roomInfo:{
            Adults:1,
            Children:0,
            Rooms:1
        },
        roomTypeInfo:{
        },
        guestDetails:[]
    }
};

const bookingDetailsSlice = createSlice({
    name:'bookingDetails',
    initialState,
    reducers:{
        setBookingDetails:(state,action) => {
            state.hotelDetails = action.payload.hotelDetails;
            state.hotelPhotos = action.payload.hotelPhotos;
            state.hotelfacilities = action.payload.hotelfacilities;
            state.hotelRules = action.payload.hotelRules;
        },
        setHotelRoomDetails:(state,action) => {
            state.hotelRooms = action.payload.hotelRooms;
            state.hotelCategory = action.payload.hotelCategory;
            state.hotelRoomImages = action.payload.hotelRoomImages;
        },
        setCriteriaValue:(state,action) => {
            state.selectionCriteria.roomInfo = action.payload;
        },
        setDateRange:(state,action) => {
            state.selectionCriteria.dateRange = action.payload;
        },
        setRoomTypeInfo:(state,action) => {
            state.selectionCriteria.roomTypeInfo.roomCategory = action.payload.roomCategory;
            state.selectionCriteria.roomTypeInfo.hotelRoom = action.payload.hotelRoom;
        },
        setGuestDetails:(state,action) => {
            state.selectionCriteria.guestDetails = action.payload;
        }
    }
})

export const {
    setBookingDetails,
    setHotelRoomDetails,
    setCriteriaValue,
    setDateRange,
    setRoomTypeInfo,
    setGuestDetails
} = bookingDetailsSlice.actions;

export default bookingDetailsSlice.reducer;