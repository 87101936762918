import styled from "styled-components";
import Box from '@mui/material/Box';


export const ParentBox = styled(Box)`
&& {
    display:flex;
    flex-grow:1;
    flex-direction:column;
    overflow-x:hidden;
}`

export const DetailHeader = styled(Box)`
&& {
    height:50px;
    background-color:#EFF3FA;
    color:color;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display:flex;
    align-items:center;
    padding-left:20px;
    font-weight: 600;
}
`

export const DetailsBox = styled(Box)`
&& {
    margin:1rem 10rem;
    flex-grow:1;
    border:1px solid black;
    border-radius:8px;
    padding-bottom:2rem;
    margin-bottom:100px;
    @media (max-width: 576px) {
        margin:1rem 2rem;
        margin-bottom: 100px;
    }
}`

export const OwnerDetailsLable = styled(Box)`
&& {
    padding-left: 2rem;
    font-size:1.1rem;
    font-weight:600;
    font-family:'Red Hat Display';
    color:black;	
    margin-top:2rem;
}`