import React from 'react';
import { useDispatch } from 'react-redux';

//Internal Imports
import { setAmenitiesDetails } from '../../../slices/hotelDetails';

//Material UI and style imports
import * as ADStyle from '../../../styles/HotelInfo/amenitiesDetailsStyle';
import HomeIcon from '@mui/icons-material/Home';

const AmenitiesList = (props) => {

    const dispatch = useDispatch();

    const existingAmenityNamesList = props.amenitiesDetails.map(facility =>  {
        return facility.facility_name;
    })

    const generateAmenitiesList = () => {
        let list = [];
        props.amenitiesList.map((item) => {
            if(existingAmenityNamesList.indexOf(item.facility_name) > -1)
                list.push(
                    <ADStyle.CBBox key = {item.facility_id}>
                        <ADStyle.FacilityBoxSelected onClick ={(event) =>  checkBoxChangedHandler(event,false,item) }>
                            {/* <HomeIcon /> */}
                            <img src = {`styles/assets/${item.facility_image_location}`} ></img>
                            <span>{item.facility_name}</span>
                        </ADStyle.FacilityBoxSelected>
                    </ADStyle.CBBox>
                )
            else 
                list.push(                    
                    <ADStyle.CBBox  key = {item.facility_id}>
                        <ADStyle.FacilityBox onClick ={(event) =>  checkBoxChangedHandler(event,true,item) }>
                            {/* <HomeIcon /> */}
                            <img src = {`styles/assets/${item.facility_image_location}`} ></img>
                            <span>{item.facility_name}</span>
                        </ADStyle.FacilityBox>
                    </ADStyle.CBBox>
                )
        })
        return list;
    }

    const checkBoxChangedHandler = (event,checked,item) => {
        let facilityObj = {};
        facilityObj.facility_id = item.facility_id;
        facilityObj.facility_name = item.facility_name;
        facilityObj.facility_image_location = null;
        dispatch(setAmenitiesDetails({
            isChecked:checked,
            facilityObj
        }));
    }

    return (
        <ADStyle.ListParent>
            { generateAmenitiesList() }
        </ADStyle.ListParent>
        
    )
}

export default AmenitiesList;