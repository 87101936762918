import React, { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//Style and Materila UI Imports
import * as ARStyle from '../../styles/AssignRoom/assignRoomStyle';
import * as DashboardStyle from '../../styles/Dashboard/dashboardStyle';

//Internal Imports 
import LeftMenu from '../LeftMenu';
import DashboardHeader from '../DashboardHeader';
import GuestDetails from './GuestDetails';
import BookingDetails from './BookingDetails';
import API from '../../api';

import {
    setVacantRoomsandCategories
} from '../../slices/dashboardInfo';

const AssignRoom = ({ initialValues}) => {

    const dispatch = useDispatch();

    const [formState, setFormState] = useState(initialValues);
    const hotelId = useSelector((state) => state.hotelDetails.hotelId);

    useEffect(() => {
        setFormState(initialValues);
    },[initialValues]);

    useEffect(() => {
        let promiseArr = [];
        promiseArr.push(API.get(`/getHotelRoomsByHotelId?hotel_id=${hotelId}`));
        promiseArr.push(API.get(`/getVacantHotelRoomsByHotelId?hotel_id=${hotelId}`));

        Promise.all(promiseArr).then((res) => {
            let roomCategories = res[0].data.data.map(cat => {
                return {
                    code:cat.hotel_room_type_id,
                    label:cat.name
                }
            });
            let vacantRooms = res[1].data.data.map(room => {
                return {
                    code:room.hotel_room_id,
                    label:room.room_number,
                    room_type:room.hotel_room_type_id
                }
            })
            dispatch(setVacantRoomsandCategories({
                roomCategories,
                vacantRooms
            }));
        })
    },[]);

    return (
        <DashboardStyle.DashboardParent>
            <DashboardStyle.DashboardLeftSection>
                <LeftMenu />
            </DashboardStyle.DashboardLeftSection>
            <DashboardStyle.DashboardRightSection>
                <DashboardHeader />
                <DashboardStyle.DashboardBody>
                    <ARStyle.AssignRoomBox>
                        <ARStyle.AssignRoomHeader>
                            { `Create Booking Room - `}
                        </ARStyle.AssignRoomHeader>
                        <BookingDetails />
                        <GuestDetails />
                        <ARStyle.ARFooter>
                            <ARStyle.CreateBookingButton> Create Booking </ARStyle.CreateBookingButton>
                        </ARStyle.ARFooter>
                    </ARStyle.AssignRoomBox>
                </DashboardStyle.DashboardBody>
            </DashboardStyle.DashboardRightSection>
        </DashboardStyle.DashboardParent>
        
    )
}

export default AssignRoom;