import styled from "styled-components";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';


export const LoaderBox = styled(Box)`
&& {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 10px;
    width: 100%;
    z-index: 2;
}`

export const Loader = styled(LinearProgress)`
&& {
    width: 100%;
    position: absolute;
    top: 0px;
}`