import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

//Style and UI Imports
import * as GSStyle from '../../styles/HotelInfo/gettingStarteStyle';

//Internal Imports
import hotelDetails, { 
    setActiveTab, 
} from '../../slices/hotelDetails';;


const GetStarted = () => {

    const dispatch = useDispatch();

    const history = useHistory();

    const getStartedHandler = () =>{
        dispatch(setActiveTab(1));
        setTimeout(() => {
            history.push('/onboarding');
        },100)
    }
    return (
        <>
            <GSStyle.GSParent>
                <GSStyle.GridContainer container spacing={2}>
                    <GSStyle.GridItem lg = {6} xs = {12}>
                        <GSStyle.GettingStartedText>
                            It's easy to get<br/>
                            started with Hotelio
                        </GSStyle.GettingStartedText>
                    </GSStyle.GridItem>
                    <GSStyle.GridItem lg = {6} xs = {12}>  
                        <GSStyle.InfoMaster>
                            <GSStyle.InfoParentFirst hasBorder >
                                <GSStyle.InfoTextParent>
                                    <GSStyle.SNo>1.</GSStyle.SNo>
                                    <GSStyle.TextTitle>Tell us about your place</GSStyle.TextTitle>
                                    <GSStyle.TextDesc>Share basic details like location and room categories.</GSStyle.TextDesc>
                                </GSStyle.InfoTextParent>
                                <GSStyle.InfoImage1 src = 'styles/assets/image1.jpeg'></GSStyle.InfoImage1>
                            </GSStyle.InfoParentFirst>
                            <GSStyle.InfoParentSecond hasBorder >
                                <GSStyle.InfoTextParent>
                                    <GSStyle.SNo>2.</GSStyle.SNo>
                                    <GSStyle.TextTitle>Showcase your unique style</GSStyle.TextTitle>
                                    <GSStyle.TextDesc>Add beautiful photos of your place and share amenities.</GSStyle.TextDesc>
                                </GSStyle.InfoTextParent>
                                <GSStyle.InfoImage2 src = 'styles/assets/image2.jpeg'></GSStyle.InfoImage2>
                            </GSStyle.InfoParentSecond>
                            <GSStyle.InfoParentThird>
                                <GSStyle.InfoTextParent>
                                    <GSStyle.SNo>3.</GSStyle.SNo>
                                    <GSStyle.TextTitle>Finish up and publish</GSStyle.TextTitle>
                                    <GSStyle.TextDesc>Set an attractive price and publish with confidence.</GSStyle.TextDesc>
                                </GSStyle.InfoTextParent>
                                <GSStyle.InfoImage3 src = 'styles/assets/image3.jpeg'></GSStyle.InfoImage3>
                            </GSStyle.InfoParentThird>
                        </GSStyle.InfoMaster>
                    </GSStyle.GridItem>
                </GSStyle.GridContainer>
            </GSStyle.GSParent>
            <GSStyle.GSFooterParent>
                <GSStyle.GSButton onClick={ getStartedHandler }> Get Started</GSStyle.GSButton>
            </GSStyle.GSFooterParent>
        </>
    )
}

export default GetStarted;