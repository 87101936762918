import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
var numeral = require('numeral');
import dayjs from "dayjs";
//Internal Imports
import DashboardHeader from '../DashboardHeader';
import LeftMenu from "../LeftMenu";
import API from '../../api';
import { setResourcesForCalendar } from '../../slices/calendarInfo';

//Style and MUI Imports
import * as CalendarStyles from '../../styles/Calendar/calendarStyle';
import { ScheduleComponent,TimelineMonth,TimelineViews, Day, Week, WorkWeek, Month, Agenda, ViewsDirective, ViewDirective, ResourcesDirective, ResourceDirective, Inject } from '@syncfusion/ej2-react-schedule';

const Calendar = () => {

    const hotelId = useSelector((state) => state.hotelDetails.hotelInfo.hotelId);

    const resourceData = useSelector((state) => state.calendarInfo.hotelRooms);

    const [eventData, setEventData] = useState([]);
    const [calendarDate, setCalendarDate] = useState(new Date());

    const dispatch = useDispatch();

    const history = useHistory();

    const getDaysOfMonth = (month, roomId, occupancy, price ) => {
        const months = [
            { name: 'January', days: 31 },
            { name: 'February', days: 28 },
            { name: 'March', days: 31 },
            { name: 'April', days: 30 },
            { name: 'May', days: 31 },
            { name: 'June', days: 30 },
            { name: 'July', days: 31 },
            { name: 'August', days: 31 },
            { name: 'September', days: 30 },
            { name: 'October', days: 31 },
            { name: 'November', days: 30 },
            { name: 'December', days: 31 }
        ];
    
        const isLeapYear = (year) => ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
    
        if (typeof month !== 'number' || month < 0 || month > 11) {
            throw new Error("Invalid month number");
        }
    
        let { name: monthName, days: daysInMonth } = months[month];
    
        // Adjust for leap year if February
        const year = new Date().getFullYear();
        if (monthName === 'February' && isLeapYear(year)) {
            daysInMonth = 29;
        }
    
        const daysArray = [];
        for (let day = 1; day <= daysInMonth; day++) {
            const dateStr = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
            daysArray.push({ 
                roomId,
                Id: 1,
                Subject: `${numeral(price).format('0,0')}`,
                StartTime: `${dateStr}T04:00:00.000Z`,
                EndTime: `${dateStr}T05:30:00.000Z`,
                CategoryColor: '#1aaa55',
            });
        }
    
        return daysArray;
    }

    const getEventDataForday = (priceObj) => {
        let priceArray = [];
        if (priceObj.single_price) {
            priceArray.push({
                roomId:`${priceObj.hotel_room_type_id}1`,
                Subject: `Price : ${numeral(priceObj.single_price).format('0,0')}`,
                StartTime: `${priceObj.date}`,
                EndTime: `${priceObj.date}`,
                CategoryColor: '#1aaa55',
                hotelRoomId: priceObj.hotel_room_type_id,
                price: priceObj.single_price,
                occupancy:1,
                hotelRoomCategoryId: priceObj.hotel_room_type_category_id,
            })
        }
        if (priceObj.double_price) {
            priceArray.push({
                roomId:`${priceObj.hotel_room_type_id}2`,
                Subject: `Price : ${numeral(priceObj.double_price).format('0,0')}`,
                StartTime: `${priceObj.date}`,
                EndTime: `${priceObj.date}`,
                CategoryColor: '#1aaa55',
                hotelRoomId: priceObj.hotel_room_type_id,
                price: priceObj.double_price,
                occupancy:2,
                hotelRoomCategoryId: priceObj.hotel_room_type_category_id,
            })
        }
        if (priceObj.triple_price) {
            priceArray.push({
                roomId:`${priceObj.hotel_room_type_id}3`,
                Subject: `Price : ${numeral(priceObj.triple_price).format('0,0')}`,
                StartTime: `${priceObj.date}`,
                EndTime: `${priceObj.date}`,
                CategoryColor: '#1aaa55',
                hotelRoomId: priceObj.hotel_room_type_id,
                price: priceObj.triple_price,
                occupancy:3,
                hotelRoomCategoryId: priceObj.hotel_room_type_category_id,
            })
        }
        return priceArray;
    }

    const getEventDataForSchedular = (hotelRoomPrices) => {
        let data = [];
        hotelRoomPrices.map(priceInfo => {
            data = [...data, ...getEventDataForday(priceInfo)];
        });
        return data;
    }

    const getDataForDateRange = (startDate, endDate) => {
        let promiseArr = [];
        promiseArr.push(API.get(`/getHotelRoomsByHotelId?hotel_id=${hotelId}`));
        promiseArr.push(API.get(`/getHotelRoomPricesByDate?hotel_id=${hotelId}&start_date=${startDate}&end_date=${endDate}`));
        Promise.all(promiseArr).then((res) => {
            const hotelRooms = [];
            res[0].data.data.map((room,index) => {
                hotelRooms.push({
                    text: room.name,
                    id: `${room.hotel_room_type_id}1`,
                    occupancy: 'Single',
                });
                hotelRooms.push({
                    text: room.name,
                    id: `${room.hotel_room_type_id}2`,
                    occupancy: 'Double',
                });
                hotelRooms.push({
                    text: room.name,
                    id: `${room.hotel_room_type_id}3`,
                    occupancy: 'Triple',
                });
            });
            const newData = [...getEventDataForSchedular(res[1].data.data, (new Date()).getMonth())]
            setEventData(newData);
            dispatch(setResourcesForCalendar({
                hotelRooms,
                hotelRoomPrices: res[1].data.data
            }));
        });
    }

    useEffect(() => {
        if(hotelId == -1){
            history.push('/dashboardlogin');
            return;
        }
        let startDate = dayjs().startOf("month").format('YYYY-MM-DD');
        let endDate = dayjs().month(dayjs().month() + 1).date(1).hour(0).minute(0).second(0).format('YYYY-MM-DD');
        getDataForDateRange(startDate, endDate);
    }, [])

    const group = { byGroupId:false, resources: ['Rooms'] }

    const onNavigating = (event) => {
        const month = event.currentDate.getMonth();
        let promiseArr = [];
        let startDate = dayjs(event.currentDate).startOf("month").format('YYYY-MM-DD');
        let endDate = dayjs(event.currentDate).month(dayjs(event.currentDate).month() + 1).date(1).hour(0).minute(0).second(0).format('YYYY-MM-DD');
        promiseArr.push(API.get(`/getHotelRoomPricesByDate?hotel_id=${hotelId}&start_date=${startDate}&end_date=${endDate}`));
        Promise.all(promiseArr).then((res) => {
            const newData = [...getEventDataForSchedular(res[0].data.data, month)]
            setEventData(newData);
            setCalendarDate(event.currentDate);
            dispatch(setResourcesForCalendar({
                hotelRooms:resourceData,
                hotelRoomPrices: res[0].data.data
            }));
        });
    }

    const getRoomName = (value) => {
        return value.resourceData.text;
    };
    const getRoomType = (value) => {
        return value.resourceData.occupancy;
    };

    const resourceHeaderTemplate = (props) => {
        return (
            <CalendarStyles.TemplateWrap>
                <CalendarStyles.RoomNameCalendar>{getRoomName(props)}</CalendarStyles.RoomNameCalendar>
                <CalendarStyles.RoomTypeCalendar>{getRoomType(props)}</CalendarStyles.RoomTypeCalendar>
            </CalendarStyles.TemplateWrap>
        );
    }

    const onRenderCell = (args) => {
        if (args.element.classList.contains('e-work-cells')) {
            if (args.date < new Date(2021, 6, 31, 0, 0)) {
                args.element.setAttribute('aria-readonly', 'true');
                args.element.classList.add('e-read-only-cells');
            }
        }
        if (args.elementType === 'emptyCells' && args.element.classList.contains('e-resource-left-td')) {
            let target = args.element.querySelector('.e-resource-text');
            target.innerHTML = '<div class="name">Rooms</div><div class="type">Type</div><div class="capacity">Capacity</div>';
        }
    };

    const onHandleSaveEvent = (event) => {
        const priceObj = {
            room_type_id: event.hotelRoomCategoryId,
            start_date: dayjs(event.StartTime).format('YYYY-MM-DD'),
            end_date: dayjs(event.EndTime).format('YYYY-MM-DD'),
        }
        if(event.occupancy == 1) {
            priceObj.single_price = parseInt(event.Subject.split(":")[1].trim());
            priceObj.double_price = null;
            priceObj.triple_price = null;
        }
        if(event.occupancy == 2) {
            priceObj.double_price = parseInt(event.Subject.split(":")[1].trim())
            priceObj.single_price = null;
            priceObj.triple_price = null;
        }
        if(event.occupancy == 3) {
            priceObj.triple_price = parseInt(event.Subject.split(":")[1].trim())
            priceObj.singleprice = null;
            priceObj.double_price = null;
        }
        API.post('updateHotelRoomPricesByDate',priceObj).then(res => {
            let startDate = dayjs(event.StartTime).startOf("month").format('YYYY-MM-DD');
            let endDate = dayjs(event.StartTime).month(dayjs(event.StartTime).month() + 1).date(1).hour(0).minute(0).second(0).format('YYYY-MM-DD');
            getDataForDateRange(startDate, endDate);
        });
    }

    return (
          <CalendarStyles.CalendarParent>
            <DashboardHeader />
            <CalendarStyles.CalendarBody>
                <CalendarStyles.CalendarLeftSection>
                    <LeftMenu />
                </CalendarStyles.CalendarLeftSection>
                <CalendarStyles.CalendarRightSection>
                    <CalendarStyles.SchedularParent>
                        <ScheduleComponent height="100%" showTimeIndicator={false}
                            saveEvent = {onHandleSaveEvent}
                            editFollowingEvents={true}
                            eventClick = {() => {
                                console.log('test');
                            }}
                            eventSettings={{  enableMaxHeight: true,editFollowingEvents: true, allowDeleting: false, dataSource: eventData, fields: { id: 'Id', subject: { title: 'Summary', name: 'Subject' }, description: { title: 'Comments', name: 'Description' }, startTime: { title: 'From', name: 'StartTime' }, endTime: { title: 'To', name: 'EndTime' } }  }}
                            selectedDate={calendarDate}
                            navigating={onNavigating}
                            group={{enableCompactView:false, resources: ['Room']}}
                            resourceHeaderTemplate={resourceHeaderTemplate}
                            onRenderCell={onRenderCell}
                            
                        >
                            <ResourcesDirective>
                                <ResourceDirective field='roomId' title='Room' 
                                    name='Room' dataSource={resourceData} textField='text' idField='id' colorField='color'
                                    orientation='Vertical'
                                />
                            </ResourcesDirective>
                            <ViewsDirective>
                                <ViewDirective option='TimelineMonth' />
                            </ViewsDirective>
                            <Inject services={[ TimelineViews,TimelineMonth]} />
                        </ScheduleComponent>
                    </CalendarStyles.SchedularParent>
                </CalendarStyles.CalendarRightSection>
            </CalendarStyles.CalendarBody>
        </CalendarStyles.CalendarParent>
    )
}

export default Calendar;