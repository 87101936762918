import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

//Internal imports
import OwnerDetails from './OwnerDetails';
import ManagerDetails from './ManagerDetail';
import SaveDetails from '../SaveDetails';
import { OWNER,MANAGER,OTHER } from "../../../config/constants";
import { MOBILE_REGEX,NEW_MOBILE_REGEX } from '../../../config/constants';
import SubmitConfirmation from '../RoomMeals/SubmitConfirmation';

//Material UI and Style imports .
import * as CDStyles from '../../../styles/HotelInfo/contactDetailsStyle';
import API from '../../../api';
import FormControl, { useFormControl } from '@mui/material/FormControl';


//REdux Imports 
import { 
    setActiveTab,
    setContactDetails
 } from '../../../slices/hotelDetails';

const ContactDetails = () => {

    const { focused } = useFormControl() || {};

    //Active Tab state
    const activeTab = useSelector(state => {
        return state.hotelDetails.activeTab;
    });

    const [errorObject , setErrorObj] = useState({});
    const [managerErrorObject , setManagerErrorObj] = useState({});
    const [otherErrorObject , setOtherErrorObj] = useState({});
    const[openDialog, setOpenDialog] = useState(false);

    //Get dAta from Redux
    const refData = useSelector(state => {
        return {
            designationTypes:state.hotelDetails.hotelInfo.designationTypes
        }
    })

    const hotelId = useSelector(state => state.hotelDetails.hotelInfo.hotelId);

    const contactDetails = useSelector(state => {
        return state.hotelDetails.hotelInfo.contactDetails;
    });

    const dispatch = useDispatch();

    //Save and Continue Button Handler
    const moveToTabNextTab = async () => {
        //validateInfo();
        saveTabInfo(false);
    }

    const openDialogBox = () => {
        // if(!validateInfo())
        //     setOpenDialog(true);
        validateInfo();
    }

    const saveTabInfo =  async (isNotValid) => {
        try {
            if(isNotValid)
                return false;

            let hotelContactDetailObj = {};
            let managerDetails = contactDetails.find(contact => contact.designation_code == MANAGER);
            hotelContactDetailObj = {
                hotel_id:hotelId,
                contact_type:'Phone',
                contact_value:managerDetails.mobile_number,
                attributes:null
            }
            await API.post('/addContactDetailForHotel',hotelContactDetailObj);
            let updatedContactDetails = structuredClone(contactDetails);
            if(updatedContactDetails.length > 0) {
                let promiseArr = [];
                updatedContactDetails.map(contact => {
                    contact.country_code = '+91';
                    contact.hotel_id = hotelId;
                    promiseArr.push(API.post('/addEmployeeForHotel',contact));
                })
                await API.post('/updateHotelSetupState',{
                    setup_state:(activeTab + 1),
                    hotel_id:hotelId
                });
                Promise.all(promiseArr).then(() => {
                    setOpenDialog(false);
                    dispatch(setActiveTab(activeTab + 1));
                })
                //await API.post('/addEmployeeForHotel',updatedContactDetails);
            }
            else {
                await API.post('/updateHotelSetupState',{
                    setup_state:(activeTab + 1),
                    hotel_id:hotelId
                });
                setOpenDialog(false);
                dispatch(setActiveTab(activeTab + 1));
            }
        }
        catch(ex) {
            setOpenDialog(false);
        }
    }

    //Yup schema object 
    let yupObject = yup.object({
        mobile_number:yup.string().matches(NEW_MOBILE_REGEX,'Please enter a valid Phone Number.'),
        email_id:yup.string().email(),
        manager_mobile_number:yup.string().matches(NEW_MOBILE_REGEX,'Please enter a valid Phone Number.'),
        manager_email_id:yup.string().email(),
        other_mobile_number:yup.string().matches(MOBILE_REGEX,'Please enter a valid Phone Number.'),
        other_email_id:yup.string().email()
    });

    //Back button Handler
    const moveToPrevTab = () => {
        dispatch(setActiveTab(activeTab - 1))
    }

    //Input Text focus out handler
    const focusOutHandler = (event,code) => {
        dispatch(setContactDetails({
            designation_code:code,
            name:event.target.name ,
            value: event.target.value
        }));
    }

    //Drop down change handler.
    const changeHandler = (name,value,code) => {
        dispatch(setContactDetails({
            designation_code:code,
            name:name ,
            value: value.code
        }));
    }

    //Validation on Save
    const validateInfo = () => {
        let errorObj = {};
        let hasError = false;
        const ownerContactDetails = contactDetails.find(contact => contact.designation_code == OWNER);
        const managerContactDetails = contactDetails.find(contact => contact.designation_code == MANAGER);
        const otherContactDetails = contactDetails.find(contact => contact.designation_code == MANAGER);
        if(!ownerContactDetails) {
            errorObj.email_id = true;
            errorObj.employee_name = true;
            errorObj.mobile_number = true;
            hasError = true;
        }
        else {
            if(!ownerContactDetails.email_id || ownerContactDetails.email_id == "") {
                hasError = true; 
                errorObj.email_id = '';
            }
            if(!ownerContactDetails.employee_name || ownerContactDetails.employee_name == "") {
                hasError = true; 
                errorObj.employee_name = '';
            }
            if(!ownerContactDetails.mobile_number || ownerContactDetails.mobile_number == "") {
                hasError = true; 
                errorObj.mobile_number = '';
            }
        }
        if(!managerContactDetails) {
            managerErrorObject.email_id = true;
            managerErrorObject.employee_name = true;
            managerErrorObject.mobile_number = true;
            hasError = true;
        }
        else {
            if(!managerContactDetails.email_id || managerContactDetails.email_id == "") {
                hasError = true; 
                managerErrorObject.email_id = '';
            }
            if(!managerContactDetails.employee_name || managerContactDetails.employee_name == "") {
                hasError = true; 
                managerErrorObject.employee_name = '';
            }
            if(!managerContactDetails.mobile_number || managerContactDetails.mobile_number == "") {
                hasError = true; 
                managerErrorObject.mobile_number = '';
            }
        }
        if(hasError) {
            setErrorObj((prevObj) => ({
                ...errorObj  
            }));
            setManagerErrorObj((prevObj) => ({
                ...managerErrorObject  
            }));
            return hasError;
        }
        let promiseArr = [];
        let validateManager = {
            'manager_mobile_number':managerContactDetails?.mobile_number,
            'manager_email_id':managerContactDetails?.email_id,
        }
        let validateOther = {
            'other_mobile_number':otherContactDetails?.mobile_number,
            'other_email_id':otherContactDetails?.email_id,
        }
        promiseArr.push(yupObject.validate(ownerContactDetails,{ abortEarly:false}));
        if(managerContactDetails)
            promiseArr.push(yupObject.validate(validateManager,{ abortEarly:false}));
        if(otherContactDetails)
            promiseArr.push(yupObject.validate(validateOther,{ abortEarly:false}));
        Promise.all(promiseArr).then((res) => {
            //saveTabInfo(false);
            setOpenDialog(true);
        }).catch(err => {
            var a = 1;
            const errors = err.inner.reduce((acc, error) => {
                return {
                    ...acc,
                    [error.path]: true,
                }
                }, {});
                let obj = {};
                let managerObj = {};
                let otherObj = {};
                if(errors['mobile_number']) {
                    hasError = true;
                    obj['mobile_number'] = 'Please enter a 10 Digit Mobile Number.';
                }
                if(errors['email_id']) {
                    hasError = true;
                    obj['email_id'] = 'Please enter a valid Email ID.';
                }
                if(errors['manager_mobile_number']) {
                    hasError = true;
                    managerObj['mobile_number'] = 'Please enter a valid Phone Number.';
                }
                if(errors['manager_email_id']) {
                    hasError = true;
                    managerObj['email_id'] = 'Please enter a valid Email ID.';
                }
                if(errors['other_mobile_number']) {
                    hasError = true;
                    otherObj['mobile_number'] = 'Please enter a valid Phone Number.';
                }
                if(errors['other_email_id']) {
                    hasError = true;
                    otherObj['email_id'] = 'Please enter a valid Email ID.';
                }
                if(hasError) {
                    setErrorObj((prevObj) => ({
                        ...obj  
                    }));
                    setOtherErrorObj((prevObj) => ({
                        ...otherObj  
                    }));
                    setManagerErrorObj((prevObj) => ({
                        ...managerObj
                    }));
                }
        });
        return hasError;
    }

    const handleClose = () => {
        setOpenDialog(false);
    }

    return (
        <CDStyles.ParentBox>
            <CDStyles.DetailsBox>
                <FormControl sx = {{ width: '100%'}}>
                    <CDStyles.DetailHeader>
                        Contact Details
                    </CDStyles.DetailHeader>
                    <OwnerDetails errorObject = { errorObject } focusOutHandler = { focusOutHandler } contactDetails = { contactDetails } />
                    <ManagerDetails errorObject = { managerErrorObject } focusOutHandler = { focusOutHandler }  contactDetails = { contactDetails }/>
                    {/* <OtherDetails errorObject = { otherErrorObject } changeHandler = { changeHandler } refData = {refData} focusOutHandler = { focusOutHandler }  contactDetails = { contactDetails }/> */}
                </FormControl>
            </CDStyles.DetailsBox>
            <SaveDetails showBackButton = {true} nextTabHandler = { openDialogBox} prevTabHandler = {moveToPrevTab}/>
            <SubmitConfirmation handleClose = { handleClose } open = { openDialog } submitHandler = { moveToTabNextTab }/>
        </CDStyles.ParentBox>
    )
}

export default ContactDetails;