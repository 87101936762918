import styled from "styled-components";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Switch } from "antd";
import { Grid } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TimeClock } from '@mui/x-date-pickers/TimeClock';

export const RuleParent = styled(Box)`
&& {
    display:flex;
    flex-grow:1;

}`

export const RulesBox = styled(Box)`
&& {
    margin:1rem 10rem;
    flex-grow:1;
    border:1px solid black;
    border-radius:8px;
    padding-bottom:2rem;
    margin-bottom:100px;
    @media (max-width: 576px) {
        margin:1rem 2rem;
    }
    display:flex;
    flex-direction:column;
}`

export const RuleListParent = styled(Grid)`
&& {

}`

export const RulesListBox = styled(Box)`
&& {
    display:flex;
    flex-direction:column;
    padding:2rem;
    flex-grow:1;
    @media(max-width:526px) {
        padding:1rem;
    }
}`

export const CheckInOutTime = styled(Box)`
&& {
    display:flex;
    gap:2rem;
    align-items:center;
    padding-bottom:2rem;
    @media(max-width:576px) {
        flex-direction:column;
    }
}`

export const CheckOutLabel = styled(Box)`
&& {
    color: var(--black-main, #141414);
    font-family: Red Hat Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}`

export const RuleSwitch = styled(Switch)`
&& {
    &.ant-switch-checked {
        background-color: #1DC9A0;
    };
    &.ant-switch-checked:hover {
        background-color: #1DC9A0;
    }
}`

export const RuleIDParent = styled(Grid)`
&& {
    
}`

export const RuleIDBox = styled(Box)`
&& {
    display:flex;
    justify-content:space-between;
    gap:3px;
}`

export const RuleLabel = styled(Typography)`
&& {
    color: var(--black-main, #141414);
    font-family: Red Hat Display;
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}`

export const CheckTimePicker = styled(TimePicker)`
&& {
    width:140px;
    & .MuiInputBase-input {
        padding:0.6rem;
    }
}`