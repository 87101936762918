import React from 'react';
import { useDispatch } from 'react-redux';

//Material UI and Style Imports
import * as HeaderStyle from '../../styles/Header/headerStyle';
import { useHistory } from 'react-router';

//Internal Imports
import { authenticate } from '../../utils/auth';
import { setActiveTab } from '../../slices/hotelDetails';

const Header = () => {
    const history = useHistory();

    const dispatch = useDispatch();

    const logoutHandler = () => {
        authenticate.signout();
        dispatch(setActiveTab(0));
        history.push('/login');
    }

    return (
        <HeaderStyle.HeaderParentBox>
            {/* <p>
                Hotelio
            </p> */}
            <HeaderStyle.HotelioLogo></HeaderStyle.HotelioLogo>
            <HeaderStyle.LogoutBtn variant='text' onClick = { logoutHandler }>
                Logout
            </HeaderStyle.LogoutBtn>
        </HeaderStyle.HeaderParentBox>
    )
}

export default Header;