import styled from 'styled-components';
import Box from '@mui/material/Box';
import { 
    Typography,
    TextField,
    Autocomplete
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { InputNumber } from 'antd';

export const BookingDetailsBox = styled(Box)`
&& {
    margin: 0 2rem;
}`

export const BDHeader = styled(Typography)`
&& {
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    letter-spacing: 0.2px;
    color: #031633;
    margin:1.5rem 0;
}`

export const BDBody = styled(Box)`
&& {

}`

export const BDGrid = styled(Grid)`
&& {

}`

export const BDGridItem = styled(Grid)`
&& {

}`

export const KidCountBox = styled(Box)`
&& {
    width:50%;
    padding-right:0.5rem;
}
`

export const AdultCountBox = styled(Box)`
&& {
    width:50%;
    padding-right:0.5rem
}
`

export const GuestCountBox = styled(Box)`
&& {
    display:flex;
    align-items:center;
}`

export const BDLabelParent = styled(Box)`
&& {
    display:flex;
    margin-bottom:0.5rem;
}`

export const BDLabelMandatory = styled(Typography)`
&& {
    font-size:0.85rem;
    color:red;
}`

export const BDLabel = styled(Typography)`
&& {
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 0.85rem;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01rem;
    color: #354665;
}`

export const BDTextBox = styled(TextField)`
&& {
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 0.85rem;
    line-height: 18px;
    color: #718BAE;
    opacity: 0.5;
    width:95%;
}`

export const BDAutoComplete = styled(Autocomplete)`
&& {
    width:95%;
}`

export const BDDatePicker = styled(DatePicker)`
&& {
    width:95%;
}`

export const NumberStepper = styled(InputNumber)`
&& {
    border-radius:4px;
    width:95%;
}`