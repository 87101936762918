import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


//Material UI and Style Imports
import * as CDStyles from '../../../styles/HotelInfo/contactDetailsStyle';
import * as RDetailStyle from '../../../styles/HotelInfo/roomDetailsStyle';
import { Snackbar } from "@mui/material";

//Internal Imports
import RoomInfo from './RoomInfo';
import SaveDetails from '../SaveDetails';
import RoomImages from './RoomImages';
import AddedRooms from './AddedRooms';
import { ROOMONLY } from '../../../config/constants';
import API from '../../../api';

//REdux Imports 
import { 
    setActiveTab,
    setRoomDetails,
    setEditRoomInfoFlag,
    setActiveTabAndRoomDetails
 } from '../../../slices/hotelDetails';
import { object } from 'yup';




const RoomDetails = () => {

    const roomInfoRef = useRef();

    //Error object for Notification
    const [errorObj, setErrorObj] = useState({
        open:false,
        message:'',
        severity:'info'
    })

    const [imagesList, setImagesList] = useState([]);
    const [imageListError, setImageListError] = useState(false);

    const activeTab = useSelector(state => {
        return state.hotelDetails.activeTab;
    });

    const refData = useSelector(state => {
        return {
            roomCategories:state.hotelDetails.hotelInfo.roomCategories,
            bedType:state.hotelDetails.hotelInfo.bedType
        }
    });

    const roomDetails = useSelector(state => state.hotelDetails.hotelInfo.roomDetails);
    const editRoomInfo = useSelector(state => state.hotelDetails.hotelInfo.editRoomInfo);
    const selectedRoomInfo = useSelector(state => state.hotelDetails.hotelInfo.selectedRoomInfo);
    const hotelId = useSelector(state => state.hotelDetails.hotelInfo.hotelId);
    const savedRoomCategoryTypes = useSelector(state => state.hotelDetails.hotelInfo.savedRoomCategoryTypes);
    let existinRoomCats = Object.keys(roomDetails); 

    const dispatch = useDispatch();

    const moveToTabNextTab = () => {
        if(Object.keys(roomDetails).length > 0)
            saveRoomInfo();
        else {
            setErrorObj((prevObj) => ({
                ...errorObj,
                open:true,
                message:'Please add room category',
                severity:'error'
            }));
        }

    }

    const moveToPrevTab = () => {
        dispatch(setActiveTab(activeTab - 1));
    }
    
    const setRoomDetailsInfo = (info) => {
        dispatch(setRoomDetails(info));
        setImagesList([]);
    }

    const cancelEditRoomHandler = () => {
        setImagesList([]);
        dispatch(setEditRoomInfoFlag(false));
    }

    
    const saveRoomInfo = async () => {
        try {
            let roomCategoryInfo = [];
            let roomsCollection = [];
            let hotelRoomType = [];
            let photoCollection = [];
            for(var o in roomDetails) {
                let room = roomDetails[o];
                if(parseInt(room.roomCategory) < 0) {
                    hotelRoomType.push({
                        hotel_id:hotelId,
                        name:room.roomCategoryName,
                    })
                }
                else {
                    room.imagesList.map((img) => {
                        photoCollection.push({
                            hotel_room_type_id:room.roomCategory,
                            photo:img.photo
                        })
                    });
                }
                roomCategoryInfo.push(...getRoomCategoriesArr(room));
    
                // let roomNums = room.roomNum.split(',');
                // roomNums.map(rooomNumber => {
                //     roomsCollection.push({
                //         hotel_id:hotelId,
                //         hotel_room_type_id:room.roomCategory,
                //         room_number:rooomNumber,
                //         categoryName:room.roomCategoryName
                //     })
                // });
            }
            let updatedRoomDetails = structuredClone(roomDetails);
            let hotelRoomTypeIds = [];
            if(hotelRoomType.length > 0) {
                for(var o in hotelRoomType) {
                    const insertData = await API.post('/addHotelRoomType',hotelRoomType[o]);
                    let photos = roomDetails[hotelRoomType[o].name].imagesList || [];
                    let roomCategory = roomCategoryInfo.find(roomCat => roomCat.categoryName == hotelRoomType[o].name);
                    if(roomCategory) {
                        updatedRoomDetails[hotelRoomType[o].name].roomCategory = roomCategory.hotel_room_type_id = insertData.data.data.insertId;
                        photos.map((photo) => {
                            photoCollection.push({
                                hotel_room_type_id:roomCategory.hotel_room_type_id,
                                photo:photo.photo
                            })
                        });
                    }
                    roomsCollection.map(roomNumber => {
                        if(roomNumber.categoryName == hotelRoomType[o].name)
                            roomNumber.hotel_room_type_id = insertData.data.data.insertId;
                    })
                }
            }

            
            let promiseArr = [];
            roomCategoryInfo.map(roomCat => {
                promiseArr.push(API.post('/addHotelRoomCategoriesandPrices',roomCat))
            })
            roomsCollection.map(roomColl => {
                promiseArr.push(API.post('/addHotelRoomNumbers',roomColl));
            })
            promiseArr.push(API.post('addHotelRoomPhotos',photoCollection));//.filter(img => img.hotel_room_type_photo_id != null)));
            Promise.all(promiseArr).then(() => {
                API.post('/updateHotelSetupState',{
                    setup_state:(activeTab + 1),
                    hotel_id:hotelId
                }).then((resp) => {

                    for(var o in roomCategoryInfo) {
                        updatedRoomDetails[roomCategoryInfo[o].categoryName].attributes = JSON.parse(roomCategoryInfo[o].attributes);
                    }
                    //let updatedRoomDetails = structuredClone(roomDetails);
                    dispatch(setActiveTabAndRoomDetails({
                        activeTab:activeTab + 1,
                        roomDetails:updatedRoomDetails
                    }));
                });
            })

        }
        catch(ex){
            var a = 1;
        }
    }

    const getRoomCategoriesArr = (room) =>{
        let roomCategoryCollection = [];
        let priceObj = {};
        if(room.attributes) {
            priceObj = { ...room.attributes } ;
        }
        else {
            priceObj = {
                tax:500,
                "currency": "INR",
                "currency_symbol": "₹"
            }
        }
        priceObj.price = room.price;
        priceObj.doubleOccupancyPrice = room.doubleOccupancyPrice;
        priceObj.extraBedPrice = room.extraBedPrice;
        let allRoomCats = structuredClone(savedRoomCategoryTypes.filter(roomCat => roomCat.hotel_room_type_id == room.roomCategory));
        if(allRoomCats.length > 0) {
            allRoomCats.map((roomCat) => {
                roomCat.bed_type_id = 1,
                roomCat.imagesList = room.imagesList || [],
                roomCat.categoryName = room.roomCategoryName,
                roomCat.hotel_room_type_id = room.roomCategory,
                roomCat.maximum_occupancy = room.maxOcc || 3,
                roomCat.minimum_occupancy = 1,
                roomCat.description = room.roomDesc || '',
                roomCat.attributes = JSON.stringify(priceObj)
                roomCat.single_price = room.price,
                roomCat.double_price = room.doubleOccupancyPrice,
                roomCat.triple_price = room.extraBedPrice
                roomCategoryCollection.push({...roomCat});
            });
        }
        else {
            roomCategoryCollection.push({
                bed_type_id:1,
                imagesList: room.imagesList || [],
                categoryName:room.roomCategoryName,
                hotel_room_type_id:room.roomCategory,
                hotel_room_type_category_code:ROOMONLY,
                maximum_occupancy:room.maxOcc || 3,
                minimum_occupancy:1,
                description:room.roomDesc || '',
                attributes:JSON.stringify(priceObj),
                single_price: room.price,
                double_price: room.doubleOccupancyPrice,
                triple_price: room.extraBedPrice
            });
        }
        return roomCategoryCollection;
    }

    useEffect(() => {
        if(selectedRoomInfo.imagesList)
            setImagesList([...selectedRoomInfo.imagesList])
    },[editRoomInfo])



    const onSanckbarClose = () => {
        setErrorObj((prevObj) => ({
            ...errorObj,
            open:false
        }));
    }

    const addRoom = () => {
        roomInfoRef.current.addRoomRef();
    }

    const cancelEditRoomInfo = () => {
        roomInfoRef.current.cancelEditRoomInfoRef();
    }

    return (
        <CDStyles.ParentBox>
            <CDStyles.DetailsBox>
                 <CDStyles.DetailHeader>
                    Room Details
                </CDStyles.DetailHeader>
                <RDetailStyle.RoomInfoBox>
                    <RoomInfo ref = { roomInfoRef } imagesList = { imagesList } selectedRoomInfo = { selectedRoomInfo } key = { selectedRoomInfo.roomCategory || (new Date().toDateString()) } editRoomInfo = { editRoomInfo } cancelEditRoomHandler = { cancelEditRoomHandler } existinRoomCats = { existinRoomCats } refData = { refData } 
                        setRoomDetailsInfo = { setRoomDetailsInfo } setImageListError = { setImageListError }/>
                    <RoomImages imageListError = { imageListError } imagesList = { imagesList } setImagesList={ setImagesList }/>
                </RDetailStyle.RoomInfoBox>
                <RDetailStyle.DetailRow>
                    <RDetailStyle.RoomButtonBar>
                        { !editRoomInfo && 
                            <RDetailStyle.AddRoom variant="contained" size = "small" onClick={ addRoom }>
                                + Add Room Category
                            </RDetailStyle.AddRoom>
                        }
                        { editRoomInfo && 
                            <>
                                <RDetailStyle.CancelRoom variant="contained" size = "small" onClick={ cancelEditRoomInfo }>
                                    Cancel
                                </RDetailStyle.CancelRoom>
                                <RDetailStyle.AddRoom variant="contained" size = "small" onClick={ addRoom }>
                                    Save
                                </RDetailStyle.AddRoom>
                            </>
                        }
                    </RDetailStyle.RoomButtonBar>
                </RDetailStyle.DetailRow>
                <AddedRooms  roomCategories = { refData.roomCategories } roomDetails = {roomDetails}/>
            </CDStyles.DetailsBox>
            
            <SaveDetails  showBackButton = {true} nextTabHandler = { moveToTabNextTab} prevTabHandler = {moveToPrevTab}/>
            <Snackbar open={errorObj.open} autoHideDuration={3000} 
                severity = { errorObj.severity } message = {errorObj.message}
                onClose={ onSanckbarClose } anchorOrigin={{ vertical: 'bottom', horizontal:'right' }}
            >
            </Snackbar>
        </CDStyles.ParentBox>
    )
}

export default RoomDetails;