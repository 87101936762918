import React, { useState } from 'react';
import { useSelector } from 'react-redux';

//Style and Material UI Imports
import * as BDStyle from '../../styles/AssignRoom/bookingDetailStyle';
import { TextField } from '@mui/material';

//Internal Imports 
import BDObj from '../../config/BookingDetailsObj.json';

const BookingDetails = () => {

    const [formState,setFormState] = useState({});
    const today = new Date();

    const roomsCat = useSelector(state => state.dashboardInfo.roomCategories);
    const vacantRooms = useSelector(state => state.dashboardInfo.vacantRooms);

    //Handle Change event for all input types.
    const handleInputChange = (event) => {
        setFormState((prevObj) => ({
            ...prevObj,
            [event.target.name]:[event.target.value]
        }))
    }

    //Handle chagne event for all date types.
    const handleDateChange = (event,newValue) => {
        setFormState(() => ({

        }));
    }

    //handle change event for all dropdown types.
    const handleDropdownChange = (name,newValue) => {
        setFormState((prevObj) => ({
            ...prevObj,
            [name]:newValue
        }))
    }

    //Set Room Category Options and SelectedValue
    let selectedRoomCat = ''
    let roomCatOptions = roomsCat.map(cat => {
        if(cat.code == formState['roomCategory'])
            selectedRoomCat = cat;
        return { ...cat };
    })

    //Set Room Number Options and SelectedValue
    let selectedRoomNumber = ''
    let roomNumOptions = [];
    if(selectedRoomCat != '') {
        vacantRooms.map(room => {
            if(room.code == formState['roomNumber'])
                selectedRoomNumber = room;
            if(room.room_type == formState['roomCategory'])
                roomNumOptions.push({...room});
        })
    }

    //Handler Adule and Kids change 
    const handleNumberChange = (event) => {
        setFormState((prevObj) => ({
            ...prevObj,
            [event.target.name]:newValue
        }))
    }

    const getDropDownSelectedObj = (name) => {
        switch(name) {
            case 'roomCategory':
                return selectedRoomCat;
            case 'roomNumber':
                return selectedRoomNumber;
        }
    }

    const getDropDownOptions = (name) => {
        switch(name) {
            case 'roomCategory':
                return roomCatOptions;
            case 'roomNumber':
                return roomNumOptions;
        }
    }

    const getItemtype = (obj) => {
        if(obj.type == 'input') {
            return (
                <BDStyle.BDTextBox name = { obj.name } InputLabelProps={{shrink: false}} placeholder = { obj.placeholder }
                    variant = "outlined" size = "small" onChange = { handleInputChange } value = { formState[obj.name] || '' }>
                </BDStyle.BDTextBox>
            );
        }
        else if(obj.type == 'number') {
            return (
                <BDStyle.BDTextBox name = { obj.name } InputLabelProps={{shrink: false}} placeholder = { obj.placeholder }
                    variant = "outlined" size = "small" onChange = { handleInputChange } value = { formState[obj.name] || '' }>
                </BDStyle.BDTextBox>
            );
        }
        else if(obj.type == 'date') {
            return (
                <BDStyle.BDDatePicker 
                    minDate = { today }
                    value = {formState[obj.name] || ''}
                    slotProps={{ textField: { size: 'small' } }}
                    onChange = { handleDateChange }
                    renderInput={(params) => <TextField size = "small" {...params} /> }
                >              
                </BDStyle.BDDatePicker>
            )
        }
        else if(obj.type == 'dropdown') {
            return (
                <BDStyle.BDAutoComplete
                    value = { getDropDownSelectedObj(obj.name) }
                    options = { getDropDownOptions(obj.name) }
                    onChange = {(event,newValue) => { handleDropdownChange(obj.name,newValue) }}
                    size = "small"
                    InputLabelProps={{shrink: false}} 
                    variant="outlined" 
                    renderInput={(params) => <TextField size = "small" {...params} placeholder= { obj.placeholder} /> } >
                </BDStyle.BDAutoComplete>
            )
        }
    }

    const generateGridItems = () => {
        let gridItems = BDObj.map(obj => {
            if(obj.type == 'guestcount') {
                return (
                    <BDStyle.BDGridItem key = { obj.name } item lg = {3} sm = {6} xs = {12}>
                        <BDStyle.GuestCountBox>
                            <BDStyle.AdultCountBox>
                                <BDStyle.BDLabelParent>
                                    <BDStyle.BDLabel>Adults</BDStyle.BDLabel>
                                    <BDStyle.BDLabelMandatory>*</BDStyle.BDLabelMandatory> 
                                </BDStyle.BDLabelParent>
                                <BDStyle.NumberStepper onChange = {() => {}} min = {1} max = {8} size = 'large' ></BDStyle.NumberStepper>
                            </BDStyle.AdultCountBox>
                            <BDStyle.KidCountBox>
                                <BDStyle.BDLabelParent>
                                    <BDStyle.BDLabel>Kids</BDStyle.BDLabel>
                                </BDStyle.BDLabelParent>
                                <BDStyle.NumberStepper onChange = {() => {}} min = {1} max = {8} size = 'large' ></BDStyle.NumberStepper>
                            </BDStyle.KidCountBox>
                        </BDStyle.GuestCountBox>
                    </BDStyle.BDGridItem>
                )
            }
            else {
                return (
                    <BDStyle.BDGridItem  key = { obj.name } item lg = {3} sm = {6} xs = {12}>
                        <BDStyle.BDLabelParent>
                            <BDStyle.BDLabel>{ obj.labelName }</BDStyle.BDLabel>
                            { obj.isMandatory && <BDStyle.BDLabelMandatory>*</BDStyle.BDLabelMandatory> }
                        </BDStyle.BDLabelParent>
                        { getItemtype(obj) }
                    </BDStyle.BDGridItem>
                )
            }
        })
        return gridItems
    }

    return (
        <BDStyle.BookingDetailsBox>
            <BDStyle.BDHeader>
                Enter Booking Details
            </BDStyle.BDHeader>
            <BDStyle.BDBody>
                <BDStyle.BDGrid container spacing={2}>
                    { generateGridItems() }
                </BDStyle.BDGrid>
            </BDStyle.BDBody>
        </BDStyle.BookingDetailsBox>
    )
}


export default BookingDetails;