import { createSlice } from '@reduxjs/toolkit';

//Internal Imports
import { DASHBOARDTAB } from '../config/constants';

const initialState = {
    hotelId:-1,
    roomCategories:[],
    vacantRooms:[],
    activeDashboardTab:DASHBOARDTAB.PENDING,
    roomSummary:{
        pendingCheckIns:0,
        checkIns:0,
        checkOuts:0,
        cancelledBookings:0,
        noShows:0
    }
}

const dashboardInfoSlice = createSlice({
    name:'dashboardInfo',
    initialState,
    reducers:{
        setVacantRoomsandCategories:(state,action) => {
            state.roomCategories = action.payload.roomCategories;
            state.vacantRooms = action.payload.vacantRooms;
        },
        setRoomSummary:(state,action) => {
            state.roomSummary = action.payload.roomSummary;
        },
        setHotelID:(state,action) => {
            state.hotelId = action.payload;
        },
        setDashboardTab:(state,action) => {
            state.activeDashboardTab = action.payload;
        }
    }
})

export const { 
    setVacantRoomsandCategories,
    setRoomSummary,
    setHotelID,
    setDashboardTab,

 } = dashboardInfoSlice.actions;

export default dashboardInfoSlice.reducer;