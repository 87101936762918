import styled from "styled-components"
import Box from '@mui/material/Box';

export const CalendarParent = styled(Box)`
&& {
    display:flex;
    flex-direction:column;
    height:100%;
}`

export const CalendarBody = styled(Box)`
&& {
    flex-grow:1;
    display:flex;
    padding-top:5px;
    overflow-y:auto;
}`

export const CalendarRightSection = styled(Box)`
&& {
    height:100%;
    display:flex;
    flex-grow:1;
    flex-direction:column;
}`

export const CalendarLeftSection = styled(Box)`
&& {
    width:170px;
    height:100%;
    display:flex;
}`

export const SchedularParent = styled(Box)`
&& {
    flex-grow:1;
}`

export const TemplateWrap = styled(Box)`
&& {
    display: flex;
    height: 100%;
    text-align: left;
}`

export const RoomNameCalendar = styled(Box)`
&& {
    border-right: 1px solid #0000001f;
    flex: 0 0 50%;
    font-weight: 500;
    line-height: 58px;
    overflow: hidden;
    padding-left: 5px;
    text-overflow: ellipsis;
}`

export const RoomTypeCalendar = styled(Box)`
&& {
    border-right: 1px solid #0000001f;
    flex: 0 0 50%;
    font-weight: 500;
    line-height: 58px;
    overflow: hidden;
    padding-left: 5px;
    text-overflow: ellipsis;
}`