import axios from "axios";

const API = axios.create({
  baseURL:'/api/restful'
});

export const AWSAPI = axios.create({
  baseURL:'/api/aws'
});

export const SNSAPI = axios.create({
  baseURL:'api/notification'
})

export const UtilsAPI = axios.create({
  baseURL:'api/utils'
})

export default API;


