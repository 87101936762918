import styled from 'styled-components';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';

export const DBGridParent = styled(Box)`
&& {
    padding:0 2rem;
    flex-grow:1;
    display:flex;
    flex-direction:column;
    padding-bottom:0.5rem;
}`

export const DBGridHeader = styled(Typography)`
&& {
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 23px;
    color: #052C65;
}`

export const DBGridOptions = styled(Box)`
&& {
    padding: 1rem 0;
}`

export const DataGridWrapper = styled(Box)`
&& {
    flex-grow:1;
}`

export const DBGridButtons = styled(Button)`
&& {
    text-transform: capitalize;
    margin-right: 1.5rem;
    font-size: 0.85rem;
    padding: 0.2rem 1.5rem;
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    background-color: #F3F3F3;
    color: #6C757D;
    box-shadow: none;
    border-radius: 5px;
    &:hover {
        color:white;
    }
}`
