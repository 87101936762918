import styled from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export const AssignRoomBox = styled(Box)`
&& {
    display:flex;
    height:100%;
    width:100%;
    flex-direction:column;
}`

export const AssignRoomHeader = styled(Box)`
&& {
    display:flex;
    border-bottom: 1px solid #D3D3D3;
    color: #052C65;
    height: 50px;
    margin: 0 0.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 23px;
    letter-spacing: 0.2px;
}`

export const ARFooter = styled(Box)`
&& {
    height:5rem;
    background-color:#F1F6FB;
    display:flex;
    justify-content:flex-end;
    align-items:center;
    padding:0 2rem;
}`

export const CreateBookingButton = styled(Button)`
&& {
    background: #198754;
    box-shadow: 1px 3px 8px rgba(50, 50, 71, 0.17);
    border-radius: 5px;
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 18px;
    color: #FFFFFF;
    height:2.5rem;
    padding:0 2rem;
    text-transform:capitalize;
}`