import React from 'react';
import { Route , Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

//Internal Imports
import { authenticate } from '../utils/auth';

const ProtectedRoute = ({ children, ...rest }) => {

    const userInfo = useSelector(state => state.hotelDetails.hotelInfo.userInfo);
    return (
      <Route
        {...rest}
        render={() => {
          // return (authenticate.isAuthenticated() === true) ? (
            return (userInfo != null) ? (
            children
          ) : (
            // <Redirect to="/signup" />
            children
          );
        }}
      />
    );
  }

  export default ProtectedRoute;