import React from "react";

//Internal Imports
import StepIcon from "./StepIcon";

//Material UI and Style Imports
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stack from "@mui/material/Stack";

//Internal Imports 
import * as SS from "../../../styles/HotelInfo/stepperStyle";


const HeadStepper = ( {activeTab} ) => {
    const steps = [
      "Property Details",
      "Amenities Details",
      "Room Details",
      "Meal Pricing",
      "Property Rules",
      "Hourly Stay",
      "Contact Details"
    ];
    return (
        <SS.StepperBox >
          <Stepper alternativeLabel activeStep={activeTab}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel >{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </SS.StepperBox>
      );
}

export default HeadStepper;