//No of Adults allowed per Room
export const AllowedAdults = 3;

//Desgination Codes
export const OWNER = 'OWNER';
export const MANAGER = 'MANAGER';
export const OTHER = 'OTHER';

//Room Categories
export const ROOMONLY = 'ROOM_ONLY';
export const ROOM_WITH_BREAKFAST = 'ROOM_WITH_BREAKFAST';
export const ROOM_WITH_BREAKFAST_PLUS_LUNCH_OR_DINNER = 'ROOM_WITH_BREAKFAST_PLUS_LUNCH_OR_DINNER';

export const PREURLIMG = 'https://partnerassets.hotelio.in/';


//Regex for Validations
export const PASSWORDREGEX = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/;
export const MOBILE_REGEX = /^[0-9-]+$/;
export const NEW_MOBILE_REGEX = /^\d{10}$/;
export const SUBDOMAIN_REGEX = /^[a-zA-Z0-9]+$/;
export const PRICE_REGEX = /^\d{0,8}(\.\d{1,2})?$/;
export const ROOM_COUNT_REGEX = /^[0-9]+$/;
export const ROOM_NUM_REGEX = /^[\d,]+$/;
export const NAME_REGEX = /^[a-zA-Z]+( [a-zA-Z]+)*$/;
export const NAME_REGEX_SEC = /^[a-zA-Z0-9\s]+$/;

export const DASHBOARDTAB = {
    PENDING:'PENDING',
    CHECKEDIN:'CHECKEDIN',
    CHECKEDOUT:'CHECKEDOUT',
    CANCELLED:'CANCELLED',
    NOSHOW:'NOSHOW'
}
