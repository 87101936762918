import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
const yup = require('yup');

//Material UI and Style Imports
import *  as SUStyle from '../../styles/Login/signUpStyle.js'
import { useState } from 'react';
import { useHistory } from "react-router-dom";

//Internal Imports
import API from '../../api/index.js';
import { SNSAPI } from '../../api/index.js';
import { authenticate } from '../../utils/auth.js';
import { setLoggedInUser } from '../../slices/hotelDetails.js'
import { MOBILE_REGEX } from '../../config/constants.js';

const LoginOTP = () => {

    const history = useHistory();

    const dispatch = useDispatch()

    const [errorObject , setErrorObj] = useState({});

    const [seconds,setSeconds] = useState(30);

    const [otp, setOtp] = useState('');

    const yupObject = yup.object().shape({
        mobile:yup.string().matches(MOBILE_REGEX,'Please enter a valid Phone Number.')
    })

    const [showOTP , setShowOTP] = useState(false);

    const [snackbarError, setSnackBarError] = useState({
        open:false,
        message:'',
        severity:'info'
    })

    const mandatoryProps = [
        'mobile'
    ];

    const [formState, setFormState] = useState({});

    // const generateOTPHandler = () => {
       
    // }

    const loginHandler = async () => {
        try {
            // if(!validateInfo()) {
            if(otp.length == 0) {
                return;
            }
            const response = await API.get(`/getOTP?mobile_number=${formState['mobile']}`);
            if(response.data.data.length == 0 || response.data.data[0]['OTP'] != otp) {
                let errObj = {
                    open:true,
                    message:'Incorrect OTP. Please try again.'
                }
                setSnackBarError((prevObj) => ({
                    ...prevObj,
                    ...errObj
                }));
                return ; 
            }
            const resp = await API.post('/getHotelEmployee',{
                mobile_number:formState.mobile,
                password:formState.password
            });
            if(resp.data.data.length > 0) {
                let userInfo = {
                    emailId : resp.data.data[0].email_id,
                    mobile:resp.data.data[0].mobile_number,
                    name:resp.data.data[0].employee_name,
                    employeeId:resp.data.data[0].employee_id,
                    //userName:resp.data.data[0]
                }
                let hotelId = -1;
                if(resp.data.data[0].hotel_id)
                    hotelId = resp.data.data[0].hotel_id;
                dispatch(setLoggedInUser({
                    userInfo,
                    hotelId
                }));
                authenticate.authenticate(userInfo.emailId);
                history.push('/onboarding');
            }
            else {
                let errObj = {
                    open:true,
                    message:'Incorrect OTP. Please try again.'
                }
                setSnackBarError((prevObj) => ({
                    ...prevObj,
                    ...errObj
                }));
            }
        }
        catch(ex) {

        }
    }

    const focusOutHandler = (event) => {
        setFormState((prevObj) => ({
            ...prevObj,
            [event.target.name]:event.target.value
        }))
    }

    const sendOTP = async () => {
        const otp = generateOTP();
        const response = await SNSAPI.post('/sendNotification',{
            notificationType:'SMS',
            messageParams:{
                PhoneNumber:`+91${formState['mobile']}`,
                Message:`Dear Client , ${otp} is your one time password(OTP) which is valid for 15 mins.Hotelio`
            }
        })
        if(response.data.length > 0 && response.data[0].indexOf('FAILURE') == -1) {
            await API.post('/saveOTP',{
                mobile_number:formState['mobile'],
                otp:otp
            }) 
            setShowOTP(true);
        }
    }

    const resendOPT = async () => {
        const otp = generateOTP();
        // const response = await SNSAPI.post('/sendNotification',{
        //     notificationType:'SMS',
        //     messageParams:{
        //         PhoneNumber:`+91${formState['mobile']}`,
        //         Message:`Dear Client , ${otp} is your one time password(OTP) which is valid for 15 mins.Hotelio`
        //     }
        // })
        //if(response.data.length > 0 && response.data[0].indexOf('FAILURE') == -1) {
            await API.post('/saveOTP',{
                mobile_number:formState['mobile'],
                otp:otp
            })
            setSeconds(30);
            setOtp(''); 
        //}
    }

    const generateOTPHandler = async () => {
        let errorObj = {};
        let hasError = false;
        mandatoryProps.map((prop) => {
            if(!formState[prop] || formState[prop] === ''){
                hasError = true;
                errorObj[prop] = true;
            }
        })
        if(hasError) {
            setErrorObj((prevObj) => ({
                ...errorObj  
            }));
            return hasError;
        }

        const empExists = await API.post('/checkIfHotelEmployeeExists',{
            mobile:formState.mobile
        });
        if(empExists.data.count == 0) {
            let obj = {
                mobile:'Mobile number does not exist'
            }
            setErrorObj((prevObj) => ({
                ...obj  
            }));
            return false;
        }

        yupObject.validate(formState,{ abortEarly:false})
        .then((res) => {
            sendOTP();   
        }).catch(err => {
            const errors = err.inner.reduce((acc, error) => {
                return {
                    ...acc,
                    [error.path]: true,
                }
                }, {});
            if(errors['mobile']) {
                hasError = true;
                obj['mobile'] = 'Please enter a valid Mobile Number.';
            }
            if(hasError) {
                setErrorObj((prevObj) => ({
                    ...obj  
                }));
            }
        })
    }

    const generateOTP = () => {
        var digits = '0123456789';
        let OTP = '';
        for (let i = 0; i < 4; i++ ) {
            OTP += digits[Math.floor(Math.random() * 10)];
        }
        return OTP;
    }

    const checkIfError = (name) => {
        if(errorObject[name])// && (!formState[name] || formState[name] == ""))
            return {
                error:true
            }
    }

    const getErrorMessage = () => {
        if(snackbarError.open)
            return snackbarError.message;
    }

    const getTime = () => {
        if(seconds > 0)
            return ` ${seconds}`;
        else 
            return ''
    }

    useEffect(() => {
        if(showOTP) {
            var intervalId = setInterval(() => {
                if(seconds > 0)
                    setSeconds(seconds - 1);
                if(seconds == 0)
                    clearInterval(intervalId);
            },1000);
        }
        return () => {
            clearInterval(intervalId);
        }
    },[seconds,showOTP]);

    return (
        <SUStyle.ParentBox>
            {/* <SUStyle.LeftPanel>

            </SUStyle.LeftPanel> */}
            <SUStyle.RightPanel>
                <SUStyle.LoginHeaderBox>
                    Welcome to Hotelio!
                </SUStyle.LoginHeaderBox>
                {
                    !showOTP ? 
                    <SUStyle.DataRow>
                        <SUStyle.Label>
                            Mobile Number<p>*</p>
                        </SUStyle.Label>
                        <SUStyle.InputField
                            { ...checkIfError("mobile")}
                            InputLabelProps={{shrink: false}} 
                            size="small"
                            variant="outlined" 
                            placeholder="Please Enter Mobile Number"
                            name="mobile"
                            value = { formState.mobile || ''}
                            onChange = { focusOutHandler}
                            helperText = { errorObject['mobile'] }
                        ></SUStyle.InputField>
                    </SUStyle.DataRow>
                    :
                    <SUStyle.DataRowOTP>
                        {/* <SUStyle.Label>
                            Password<p>*</p>
                        </SUStyle.Label> */}
                        <SUStyle.LoginOTP
                            autoComplete='off'
                            value={otp}
                            onChange={setOtp}
                            { ...checkIfError("password")}
                            InputLabelProps={{shrink: false}} 
                            numInputs={4}
                            // renderSeparator={<span>-</span>}
                            renderInput={(props) => <SUStyle.OTPInputText {...props} />}
                        ></SUStyle.LoginOTP>
                    </SUStyle.DataRowOTP>
                }
                {   !showOTP ? 
                    <SUStyle.DataRow>
                        <SUStyle.SingUPButton
                            variant="contained"
                            size="small"
                            onClick={generateOTPHandler}
                        >
                            Generate OTP
                        </SUStyle.SingUPButton>
                    </SUStyle.DataRow>
                    :
                    <SUStyle.DataRow>
                        <SUStyle.SingUPButton
                            variant="contained"
                            size="small"
                            onClick={loginHandler}
                        >
                            Log In
                        </SUStyle.SingUPButton>
                    </SUStyle.DataRow>
                }
                <SUStyle.OTPBoxRow>
                    <SUStyle.OTPBox>
                    </SUStyle.OTPBox>
                    { !showOTP ?
                        <SUStyle.TCBox>
                            <a href="/login">Log in with Password</a>
                        </SUStyle.TCBox>
                        :
                        <SUStyle.TCBox onClick= { resendOPT } >
                            <p>Resend OTP</p>
                            { seconds > 0 && <p> &nbsp;in</p> }
                            { seconds > 0 && <SUStyle.ResendTime> { getTime() }</SUStyle.ResendTime> }
                        </SUStyle.TCBox>
                    }
                </SUStyle.OTPBoxRow>
                <SUStyle.InvalidCreds>
                    { getErrorMessage() }
                </SUStyle.InvalidCreds>
            </SUStyle.RightPanel>
            {/* <Snackbar open={snackbarError.open} autoHideDuration={3000} severity = { snackbarError.severity } message = {snackbarError.message}>
            </Snackbar> */}
        </SUStyle.ParentBox>
    )
}

export default LoginOTP;