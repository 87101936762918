import styled from 'styled-components';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import OtpInput from 'react-otp-input';

export const ParentBox = styled(Box)`
&& {
    height:100vh;
    widht:100vw;
    display:flex;
}`

export const LeftPanel = styled(Box)`
&& {
    flex-basis:50%;
    background-color:#DBE5FD;
    background-image: url(styles/assets/hotel-booking.svg);
    background-repeat: no-repeat;
    background-position: 50%;
}`

export const RightPanel = styled(Box)`
&& {
    overflow:hidden auto;
    flex-basis:50%;
    background-color:white;
    padding:3rem 4rem 4rem 4rem;
    display:flex;
    flex-direction:column;
    margin:0 auto;
    @media (max-width: 576px) {
        flex-basis:100%;
        padding:3rem 1.5rem 4rem 1.5rem;
    }
}`

export const HeaderBox = styled(Box)`
&& {
    font-size:2rem;
    font-weight:500;
    color:black;
    font-family:'Red Hat Display';
    display: flex;
    justify-content: center;
    @media (max-width: 576px) {
        font-size:1.5rem;
    }
}`

export const LoginHeaderBox = styled(Box)`
&& {
    font-size: 2rem;
    font-weight: 500;
    color: black;
    font-family:'Red Hat Display';
    display:flex;
    justify-content:center;
    & span {
        text-align:center;
    }
}`

export const DataRow = styled(Box)`
&& {
    margin-top:2.5rem;  
    display:flex;
    flex-direction:column;
    align-items:center;
}`

export const Label = styled(Box)`
&& {
    width:90%;
    font-size:1rem;
    font-weight:500;
    color:black;
    font-family:'Red Hat Display';
    & p {
        color:red;
        padding-left:5px
    }
    display:flex;
    flex-direction:row;
    align-items:center;
    height:30px;
}`

export const InputField = styled(TextField)`
&& {
    height:40px;
    width:90%;
     & .MuiInputBase-input {
        color:#4D4C4C;
    }
}`

export const OTPBox = styled(Box)`
&& {
    display: flex;
    margin-top: 8px;
    & a {
        padding-left:5px;
        color:black !important;
    }
    align-items:center;
    justify-content: center;
    & a {
        text-decoration:none !important;
    }
}`

export const TCBox = styled(Box)`
&& {
    display: flex;
    margin-top: 8px;
    & a {
        padding-left:5px;
        color:black !important;
    }
    align-items:center;
    justify-content: center;
    & a {
        text-decoration:none !important;
    }
    & p {
        margin-top: 0px;
        margin-bottom: 0px;
        cursor:pointer;
    }
}`

export const SignInTCBox = styled(Box)`
&& {
    width: 90%;
    display: flex;
    margin-top: 8px;
    justify-content: space-between;
    & a {
        padding-left:5px;
        color:black !important;
    }
    font-family:'Red Hat Display';
}`

export const SignUPTCBox = styled(Box)`
&& {
    display: flex;
    margin-top: 8px;
    justify-content: flex-start;
    & a {
        padding-left:5px;
        color:#F31771;
    }
}`

export const CreateAccText = styled(Box)`
&& {
    height: 40px;
    line-height:40px;
    border-color: black;
    color: black;
    text-transform: capitalize;
    font-family: Red Hat Display;
}`

export const CreateAccBtn = styled(Button)`
&& {
    height: 40px;
    border-color: black;
    color: black;
    text-transform: capitalize;
    font-family: Red Hat Display;
    font-weight: 400;
    text-decoration:underline;
    &:hover{
        background-color:white;
        text-decoration:underline;
    }
}`

export const SignUPBoxRow = styled(Box)`
&& {
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    & a {
        padding-left:5px;
        color:#F31771;
    }
    padding: 0 5%;
}`

export const OTPBoxRow = styled(Box)`
&& {
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
    & a {
        padding-left:5px;
        color:#F31771;
    }
    padding: 0 5%;
}`

export const TCBoxRow = styled(Box)`
&& {
    display: flex;
    justify-content: center;
}`

export const SingUPButton = styled(Button)`
&& {
    background: black;
    border-radius: 5px;
    width: 90%;
    height: 3rem;
    font-family: 'Red Hat Display';
    text-transform: capitalize;
    font-size: 1rem;
    &:hover {
        background-color:black !important;
    }
}`

export const InvalidCreds = styled(Typography)`
&& {
    
    color: #F31771;
    bottom: 4rem;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    font-family: 'Red Hat Display';
}`

/* OTP Start */
export const LoginOTP = styled(OtpInput)`
&& {
    
}`

export const OTPInputText = styled.input`
&& {
    height:100%;
    width:40px !important;
    border:none;
    border-bottom:1px solid;
    margin-left: 1rem;
    outline:none !important;
}`

export const DataRowOTP = styled(Box)`
&& {
    margin-top:2.5rem;  
    display:flex;
    flex-direction:column;
    align-items:center;
    & div {
        height:40px !important;
    }
}`

export const ResendTime = styled.p`
&& {
   width:25px;
   text-align:right;
}`
/* OTP End */