import React , { useRef, useEffect } from "react";

//Material UI and Style Imports
import * as CDStyles from '../../../styles/HotelInfo/contactDetailsStyle';
import * as PropertyDetailStyle from '../../../styles/HotelInfo/propertyDetailsStyle';
import { MANAGER } from "../../../config/constants";

const ManagerDetails  = ({ errorObject, focusOutHandler,  contactDetails }) => {

    const managerNameRef = useRef();
    const managerEmailRef = useRef();
    const managerNumberRef = useRef();

    const mangerContactDetails = contactDetails.find(contact => contact.designation_code == MANAGER);

    useEffect(() => {
        managerNameRef.current.value = mangerContactDetails?.employee_name || '';
        managerEmailRef.current.value = mangerContactDetails?.email_id || '';
        managerNumberRef.current.value = mangerContactDetails?.mobile_number || '';
        return () => {

        }
    })

    const checkIfError = (name) => {
        if(errorObject[name]) {
            return {
                error:true
            }
        }
    }

    return (
        <>
            <CDStyles.OwnerDetailsLable>
                Contact details to be used on website
            </CDStyles.OwnerDetailsLable>
            <PropertyDetailStyle.DetailRow>
                <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                        Name <p>*</p>
                    </PropertyDetailStyle.DetailColumnLabel>
                    <PropertyDetailStyle.InputField 
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Please Enter Name"
                        name = "employee_name"
                        onBlur = { (event) => focusOutHandler(event,MANAGER) }
                        inputRef = { managerNameRef }
                        { ...checkIfError("employee_name")}
                        helperText = { errorObject['employee_name'] }
                        size="small"
                    >
                    </PropertyDetailStyle.InputField>
                </PropertyDetailStyle.DetialColumn>
                <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                        Email <p>*</p>
                    </PropertyDetailStyle.DetailColumnLabel>
                    <PropertyDetailStyle.InputField 
                        type="email"
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        placeholder="Please Enter Email"
                        name = "email_id"
                        onBlur = { (event) => focusOutHandler(event,MANAGER) }
                        inputRef = { managerEmailRef }
                        size="small"
                        { ...checkIfError("email_id")}
                        helperText = { errorObject['email_id'] }
                    >
                        
                    </PropertyDetailStyle.InputField>
                </PropertyDetailStyle.DetialColumn>
            </PropertyDetailStyle.DetailRow>
            <PropertyDetailStyle.DetailRow>
                <PropertyDetailStyle.DetialColumn>
                    <PropertyDetailStyle.DetailColumnLabel>
                        Phone Number <p>*</p>
                    </PropertyDetailStyle.DetailColumnLabel>
                    <PropertyDetailStyle.InputField 
                        InputLabelProps={{shrink: false}} 
                        variant="outlined" 
                        autoComplete='off'
                        placeholder="Please Enter Phone Number"
                        type="tel"
                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                        name = "mobile_number"
                        onBlur = { (event) => focusOutHandler(event,MANAGER) }
                        size="small"
                        inputRef = { managerNumberRef }
                        { ...checkIfError("mobile_number")}
                        helperText = { errorObject['mobile_number'] }
                    >
                    </PropertyDetailStyle.InputField>
                </PropertyDetailStyle.DetialColumn>
                <PropertyDetailStyle.DetialColumn>
                </PropertyDetailStyle.DetialColumn>
            </PropertyDetailStyle.DetailRow>
        </>
    )
}

export default ManagerDetails