import React , { useState } from 'react';

//Style and Material UI Imports
import * as GDStyle from '../../styles/AssignRoom/guestDetailStyle';
import { TextField,Button } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';


//Internal Imports 
import GDObj from '../../config/GuestDetialsObj.json';

const GuestDetails = ({}) => {

    const [formState, setFormState] = useState({});

    //Handle input chagne event
    const handleInputChange = (event) => {
        setFormState((prevObj) => ({
            ...prevObj,
            [event.target.name]:event.target.value
        }))
    }

    //Handle dropdown chagne event
    const handleDropdownChange = (event,newValue) => {
        setFormState((prevObj) => ({
            ...prevObj,
            [event.target.name]:newValue
        }))
    }

    const getItemtype = (obj) => {
        if(obj.type == 'input') {
            return (<GDStyle.GDTextBox InputLabelProps={{shrink: false}} placeholder = { obj.placeholder }
                variant = "outlined" size = "small">
            </GDStyle.GDTextBox>);
        }
        else if(obj.type == 'dropdown') {
            return (
                <GDStyle.GDAutoComplete
                    size = "small"
                    InputLabelProps={{shrink: false}} 
                    variant="outlined" 
                    renderInput={(params) => <TextField {...params} placeholder= { obj.placeholder} /> } >
                </GDStyle.GDAutoComplete>
            )
        }
        else if(obj.type == 'fileupload') {
            return (
                <>
                    <GDStyle.GDFileUpload type = 'file' placeholder = 'Upload'>
                    </GDStyle.GDFileUpload>
                    <GDStyle.GDMatFileUploadDummy></GDStyle.GDMatFileUploadDummy>
                </>
            )
        }
        else if(obj.type == 'contact') {
            return (
                <>
                    <GDStyle.GDIndiaCode>+91</GDStyle.GDIndiaCode>
                    <GDStyle.GDContactTextBox InputLabelProps={{shrink: false}} placeholder = { obj.placeholder }
                        variant = "outlined" size = "small">
                    </GDStyle.GDContactTextBox>
                </>
            );
        }
        // else if(obj.type == 'fileupload') {
        //     return (
        //         <GDStyle.GDFileUpload type = 'file' placeholder = 'Upload'>
        //         </GDStyle.GDFileUpload>
        //     )
        // }
    }

    const generateGridItems = () => {
        let gridItems = GDObj.map(obj => {
            return (
                <GDStyle.GDGridItem key = { obj.labelName } item lg = {3} sm = {6} xs = {12}>
                    <GDStyle.GDLabelParent>
                        <GDStyle.GDLabel>{ obj.labelName }</GDStyle.GDLabel>
                        { obj.isMandatory && <GDStyle.GDLabelMandatory>*</GDStyle.GDLabelMandatory> }
                    </GDStyle.GDLabelParent>
                    <GDStyle.GDComponentParent>
                        { getItemtype(obj) }
                    </GDStyle.GDComponentParent>
                </GDStyle.GDGridItem>
            )
        })
        return gridItems;
    }
    
    return (
        <GDStyle.GuestDetialBox>
            <GDStyle.GuestHeader>
                Enter Guest Details
            </GDStyle.GuestHeader>
            <GDStyle.GuestBody>
                <GDStyle.GDGrid container spacing={2}>
                    { generateGridItems() }
                </GDStyle.GDGrid>
            </GDStyle.GuestBody>
            <GDStyle.GuestFooter>
                <GDStyle.BtnAddGuest variant = "contained" size = "small">
                    + &nbsp; Add Another Guest
                </GDStyle.BtnAddGuest>
            </GDStyle.GuestFooter>
        </GDStyle.GuestDetialBox>
    )
}

export default GuestDetails;