import styled from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const LeftMenuParent = styled(Box)`
&& {
    width:170px;
    height:100%;
    background-color:white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    padding-top:2rem;
}`

export const LMHeader = styled(Box)`
&& {
    color:#000000;
    font-weight:700;
    font-family:'Red Hat Display';
    font-size:2rem;
    display: flex;
    justify-content: center;
    margin: 1rem 0 0.5rem 0;
    letter-spacing:2.5px;
}`

export const LMDate = styled(Typography)` 
&& {
    color:#495057;
    font-size:0.8rem;
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    display: flex;
    justify-content: center;
    margin-bottom:2rem;
}`

export const LMTime = styled(Typography)`
&& {
    color:#495057;
    font-size:0.8rem;
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 500;
}`

export const LMMenuBox = styled(Box)`
&& {
    height:40px;
    display:flex;
    align-items:center;
    margin-bottom:1.5rem;
    background-color:${(props) => {
        if(props.isActive)
            return '#052C65'
        else
            return 'white';
    }};
   
    cursor:pointer;
}`

export const LMMenuText = styled(Typography)`
&& {
    color:${(props) => {
        if(props.isActive)
            return 'white'
        else
            return '#052C65';
    }};
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    letter-spacing: 0.2px;
    opacity: 0.8;
}`

export const IconBox = styled(Box)`
&& {
    height:40px;
    width:30px;
    display:flex;
    justify-content:center;
    align-items:center;
    & svg {
        font-size:1rem;
    }
}`

export const IconCal = styled(Box)`
&& {
    height:100%;
    width:100%;
    background-image: url(styles/assets/calendar.svg);
    background-repeat:no-repeat;
    background-position:50%;
}`

export const IconsDB = styled(Box)`
&& {
    height:100%;
    width:100%;
    background-image: url(styles/assets/Dashboard.png);
    background-repeat:no-repeat;
    background-position:50%;
}`

export const IconsRoom = styled(Box)`
&& {
    height:100%;
    width:100%;
    background-image: url(styles/assets/Rooms.svg);
    background-repeat:no-repeat;
    background-position:50%;
}`

export const IconsSettings = styled(Box)`
&& {
    height:100%;
    width:100%;
    background-image: url(styles/assets/settings.svg);
    background-repeat:no-repeat;
    background-position:50%;
}`