import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    hotelRooms:[],
    hotelRoomPrices:[],
}

const calendarInfoSlice = createSlice({
    name:'calendarInfo',
    initialState,
    reducers: {
        setResourcesForCalendar:(state,action) => {
            state.hotelRooms = action.payload.hotelRooms;
            state.hotelRoomPrices = action.payload.hotelRoomPrices;
        }
    }
});

export const {
    setResourcesForCalendar
} = calendarInfoSlice.actions;

export default calendarInfoSlice.reducer;