import React from 'react';

//Style Imports
import * as BCStyle from '../../styles/HotelInfo/confirmationStyle';

const SetUpMessagePage = (props) => {

    return (
        <BCStyle.BCContainer>
            <BCStyle.CircleOutline></BCStyle.CircleOutline>
            <BCStyle.BookingConfSubHeader>
                We are setting up your property and an activation email will be sent to your registered Email ID.
            </BCStyle.BookingConfSubHeader>
        </BCStyle.BCContainer>
    )
}

export default SetUpMessagePage;