import styled from "styled-components";
import Box from '@mui/material/Box';


export const ParentBox = styled(Box)`
&& {
    display:flex;
    flex-grow:1;
    flex-direction:column;
    overflow-x:hidden;
}`

export const DetailsBox = styled(Box)`
&& {
    margin:1rem 10rem;
    flex-grow:1;
    border:1px solid black;
    border-radius:8px;
    display: flex;
    flex-direction: column;
    padding-bottom:2rem;
    margin-bottom:100px;
    @media (max-width: 576px) {
        margin:1rem 2rem;
    }
}`


export const CBBox = styled(Box)`
&& {
    width: 25%;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    & span{
        color:black;
        font-size:1rem;
    };
    @media (max-width: 576px) {
        width:45%
    }
}
`

export const FacilityBox = styled(Box)`
&& {
    height: 80px;
    width: 100%;
    max-width: 200px;
    border: 1px solid #DDDDDD;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    cursor:pointer;
    align-items:center;
    @media (max-width: 576px) {
        padding: 0.5rem 0.5rem;
    }
}`

export const FacilityBoxSelected = styled(Box)`
&& {
    height: 80px;
    width: 100%;
    max-width: 200px;
    border: 1px solid #DDDDDD;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    cursor:pointer;
    background: #F7F7F7;
    box-shadow: 0 0 0 2px #000000;
    border: none;
    align-items:center;
    @media (max-width: 576px) {
        padding: 0.5rem 0.5rem;
    }
}`

export const ListParent = styled(Box)`
&& {
    margin: 2rem 2rem 0.5rem 2rem;
    display: flex;
    flex-wrap: wrap;
    flex-grow:1;
    @media (max-width: 576px) {
        gap:1rem;
        margin:2rem 1rem 0.5rem 1rem;
        justify-content:center;
    }
}`