import styled from 'styled-components';
import Box from '@mui/material/Box';
import Typography from 'antd/es/typography/Typography';


export const GridHeaderWrapper = styled(Box) `
&& {
    height:40px;
    background: #F0F0F0;
    padding:0 0.5rem;
    border-radius: 10px 10px 0px 0px;
}`
export const GridHeaderRow = styled(Box)`
&& {
    display:flex;
    width:100%;
    height:100%;
}`

export const GridHeaderColumn = styled(Box)`
&& {
    width:20%;
    color:#495057;
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;
    display:flex;
    padding-left:1rem;
    align-items:center;
}`