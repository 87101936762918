//External Imports
import React from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import { Provider } from "react-redux";

//Material UI Imports
import * as AppStyle from '../styles/appStyle.js';

//Interal Imports
import HotelInfo from './HotelInfo';
import SignUp from './login/SignUp';
import store from '../slices';
import Dashboard from './Dashboard'
import AssignRoom from './AssignRoom';
import ProtectedRoute from './ProtectedRoute';
import Login from './login/Login.js';
import LoginOTP from './login/LoginOTP.js';
import ForgotPassword from './login/ForgotPassword.js';
import GetStarted from '../components/HotelInfo/GetStarted';
import Calendar from './Calendar/Calendar.js';

const App = () => {

    return (
        <Provider store={store}>
            <Router>
                <AppStyle.AppBox>
                    <Switch>
                        <Route path= '/signup' component = { SignUp } />
                    </Switch>
                    <Switch>
                        <Route path = '/login' component = {Login} />
                    </Switch>
                    <Switch>
                        <Route path = '/dashboardlogin' component = {Login} />
                    </Switch>
                    <Switch>
                        <Route path = '/login' component = {Login} />
                    </Switch>
                    <Switch>
                        <Route path = '/loginotp' component = {LoginOTP} />
                    </Switch>
                    <Switch>
                        <Route path = '/forgotpassword' component = {ForgotPassword} />
                    </Switch>
                    <Switch>
                        <ProtectedRoute path='/onboarding'>
                            <HotelInfo />
                        </ProtectedRoute>
                    </Switch>
                    <Switch>
                        {/* <Route path = '/dashboard' component = { Dashboard } /> */}
                        <ProtectedRoute path='/dashboard'>
                            <Dashboard />
                        </ProtectedRoute>
                    </Switch>
                    <Switch>
                        <ProtectedRoute path='/calendar'>
                            <Calendar />
                        </ProtectedRoute>
                        {/* <Route path = '/dashboard' component = { Dashboard } /> */}
                        {/* <Route path = '/calendar' component = {Calendar} /> */}
                        {/* <ProtectedRoute path='/calendar'>
                            <Calendar />
                        </ProtectedRoute> */}
                    </Switch>
                    <Switch>
                        <ProtectedRoute path='/createBooking'>
                            <AssignRoom />
                        </ProtectedRoute>
                    </Switch>
                    <Switch>
                        {/* <Route exact path = '/' component = { SignUp } /> */}
                        <Route exact path = '/' component = { GetStarted } />
                    </Switch>
                </AppStyle.AppBox>
            </Router>
        </Provider>
    )
}

export default App;