import React from 'react';
import { useSelector } from 'react-redux';

//Style and Material Imports
import * as DHeader from '../../styles/DashboardHeader/dashboardHeaderStyle'
import SearchIcon from '@mui/icons-material/Search';

const DashboardHeader = () => {

    const propDetails = useSelector(state => state.hotelDetails.hotelInfo.propertyDetails);
    
    return(
        <DHeader.DHeaderParent>
            <DHeader.HotelNameHeader>
                { propDetails?.name }
            </DHeader.HotelNameHeader>
            <DHeader.SearchBarParent>
                <DHeader.SearchBarWrapper>
                    {/* <SearchIcon></SearchIcon>
                    <DHeader.SearchBar placeholder="Search here" size='small'></DHeader.SearchBar> */}
                </DHeader.SearchBarWrapper>
                {/* <DHeader.NewReservation size = "small" variant = 'contained'>+  &nbsp;&nbsp;&nbsp; New Reservation</DHeader.NewReservation> */}
            </DHeader.SearchBarParent>
        </DHeader.DHeaderParent>
    )
}

export default DashboardHeader;