import styled from 'styled-components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';

export const UploadBox = styled(Box)`
&& {
    width:90%;
    position:relative;
}`

export const AmenititesUploadBox = styled(Box)`
&& {
    width:80%;
    margin-top:0.5rem;
    position:relative;
    & .MuiButtonBase-root {
        height: 135px;
        width: 100%;
        position: absolute;
    }
    @media (max-width: 576px) {
        width:100%;
    }
}`

export const DescriptionLabel = styled(Box)`
&& {
    color:#2c2c2e;
    font-size:0.85rem;
    margin-top:0.5rem;
}`

export const DetailColumnLabel = styled(Box)`
&& {
    margin-top:1rem;
    display: flex;
    font-size: 1.1rem;
    height: 34px;
    align-items: center;
    color:black;
    & p {
        color:red;
        font-size: 0.8rem;
    }
    & span {
        font-size:0.8rem;
    }
}`

export const TCBox = styled(Box)`
&& {
    color:#2C2C2E;
    font-size:0.8rem;
}`

export const DetialColumn = styled(Box)`
&& {
    flex-basis:50%;
    padding-left:2rem;
    display:flex;
    align-items:center;
    & .MuiCheckbox-root {
        padding-left:0px;
    }
    
}`

export const RoomImageBox = styled(Grid)`
&& {
    display:flex;
    flex-direction:column;
    padding:0 2rem 0 3rem !important;
}`

export const RoomImageBoxParent = styled(Grid)`
&& {
    display:flex;
}`

export const DetailRow = styled(Box) `
&& {
    display:flex;
    flex-direction:column;
}`

export const DetailRowParent = styled(Box)`
&& {
    display:flex;
    flex-direction:column;
    position:relative;
}`

export const RoomImagePic = styled(Box)`
&& {
    height: 100%;
    width: 100%;
    border: 1px dashed rgb(176,176,176) !important;
    background-image: url(styles/assets/photo.png);
    background-repeat: no-repeat;
    background-position: 50% 20px;
    position: absolute;
    top: 0px;
    z-index: 1;
    border-radius: 5px;
    background-size: 15%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 1.5rem;
    & span {
        padding-left: 5px;
    }
}
`

export const FacilityImagesUploaded = styled(Box)`
&& {
    padding-top:1rem;
    width:100%;
}`

export const FacilityImageGrid = styled(Grid)`
&& {
    height:100%;
    overflow-y:auto;
}`

export const FacilityImageGridItem = styled(Grid)`
&& {
    position:relative;
}`

export const HeroImageUploaded = styled(Box)`
&& {
    width:80%;
    padding-top:1rem;
    position:relative;
}`

export const RoomImageUploaded = styled(Box)`
&& {
    width:80%;
    padding-top:1rem;
    position:relative;
    max-height: 375px;
    overflow: hidden;
    padding-bottom: 10px;
}`

export const FacilityHeroImg = styled.img`
&& {
    width: 80%;
    height: 150px;
    border-radius:5px;
}`

export const FacilityImg = styled.img`
&& {
    width:100%;
    height:100px;
    border-radius:5px;
}`

export const ErrorMessage = styled(Typography)`
&& {
    font-family:'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 18px;
    height: 25px;
    display: flex;
    align-items: flex-end;
    color: #D0105F;
}
`

export const DelHeroImage = styled(DeleteIcon)`
&& {
    position: absolute;
    top: 20px;
    cursor: pointer;
    left: calc(80% - 40px);
    color: black;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;

    font-size:1.25rem;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}`

export const DelImage = styled(DeleteIcon)`
&& {
    position: absolute;
    top: 20px;
    cursor: pointer;
    left: calc(100% - 40px);
    color: black;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    font-size:1.25rem;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}`

export const DelImageRoom = styled(DeleteIcon)`
&& {
    position: absolute;
    top: 20px;
    cursor: pointer;
    left: calc(80% - 5px);
    color: black;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    font-size:1.25rem;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}`

export const ImgUpload = styled.input`
&& {
    margin-top:0.5rem;
    width:90%;
    border:1px dashed #A6BCDA;
    background: #F0F6FF;
    height:135px;
    border-radius:5px;
    position:relative;
    z-index:2;
    opacity:0;
    cursor:pointer;
}`