import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//Style Imports
import * as BCStyle from '../../styles/HotelInfo/confirmationStyle';

//Internal Imports 
import API from '../../api';
import { 
    setActiveTab
} from '../../slices/hotelDetails' ;
import { SNSAPI } from '../../api';
import { SUBDOMAIN_REGEX } from '../../config/constants';

const Confirmation = (props) => {

    const dispatch = useDispatch();

    const [err,setErr] = useState(false);
    
    const activeTab = useSelector(state => {
        return state.hotelDetails.activeTab;
    });

    const userInfo = useSelector(state => state.hotelDetails.hotelInfo.userInfo);

    const propDetails = useSelector(state => state.hotelDetails.hotelInfo.propertyDetails);

    const hotelId = useSelector(state => state.hotelDetails.hotelInfo.hotelId);

    const [subDomain,setSubDomain] = useState(propDetails?.nickName ? propDetails.nickName.replaceAll(' ','') : '');

    const contactDetails = useSelector(state => {
        return state.hotelDetails.hotelInfo.contactDetails.find(contact => contact.designation_code == 'OWNER');
    });

    const handleSubDomainChange = (event) => {
        setSubDomain(event.target.value);
    }

    const validateSubDomain = () => {

    }

    const submitSubDomainName = () => {
        if(!SUBDOMAIN_REGEX.test(subDomain)) {
            setErr(true);
            return;
        }
        let subdomainname = subDomain.toLowerCase();
        API.post('/setSubDomain',{
            sub_domain:(`${subdomainname}`),
            hotel_id:hotelId
        }).then((res) => {
            API.post('/updateHotelSetupState',{
                setup_state:(activeTab + 1),
                hotel_id:hotelId
            }).then((resp) => {
                SNSAPI.post('/sendNotification',{
                    notificationType:'HOTELCONFIRMATION',
                    templateType:'HOTELCONFIRMATION',
                    mailParams:{
                        websiteLink:`https://${subDomain}.hotelio.in`,
                        hotelOwner:contactDetails.employee_name,
                        email:contactDetails.email_id
                    }
                }).then(response => {

                });
                dispatch(setActiveTab(activeTab + 1));
            });
        }).catch(res => {

        });
    }

    const checkIfErr = () => {
        if(err) {
            return {
                error:true
            }
        }
    }

    return (
        <BCStyle.BCContainer>
            <BCStyle.CircleOutline></BCStyle.CircleOutline>
            <BCStyle.BookingConfHeader>
                { `Thank you for submitting your property details and choosing Hotelio !! ` }
            </BCStyle.BookingConfHeader>
            <BCStyle.AlternateEmailBox>
                <BCStyle.AlterEmailLabel>
                    Pick your hotel website link now!
                </BCStyle.AlterEmailLabel>
                <BCStyle.DomainParent>
                    <BCStyle.InputField { ...checkIfErr() } helperText = { err ? 'Only alphabets and numbers are allowed' : '' } onChange = { handleSubDomainChange } size="small" value = { subDomain }/>
                    <BCStyle.HotelioDomain>.hotelio.in</BCStyle.HotelioDomain>
                </BCStyle.DomainParent>
                <BCStyle.SubmitButton onClick = { submitSubDomainName } variant="contained" size = "small"> Submit</BCStyle.SubmitButton>
            </BCStyle.AlternateEmailBox>

        </BCStyle.BCContainer>
    )
}

export default Confirmation;