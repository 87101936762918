import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
const yup = require('yup');
import { useState } from 'react';
import { useHistory } from "react-router-dom";

//Material UI and Style Imports
import *  as SUStyle from '../../styles/Login/signUpStyle.js'
import { InputAdornment, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

//Internal Imports
import API from '../../api/index.js';
import { SNSAPI } from '../../api/index.js';
import { authenticate } from '../../utils/auth.js';
import { setLoggedInUser } from '../../slices/hotelDetails.js'
import { MOBILE_REGEX,PASSWORDREGEX } from '../../config/constants.js';

const LoginOTP = () => {

    const history = useHistory();

    const dispatch = useDispatch()

    const [errorObject , setErrorObj] = useState({});

    const [seconds,setSeconds] = useState(30);

    const [showResetPwd, setShowResetPwd] = useState(false);

    const [showPassword , setShowPassword] = useState(false);

    const [confirmshowPassword , setConfirmShowPassword] = useState(false);

    const [otp, setOtp] = useState('');

    const yupObject = yup.object().shape({
        mobile:yup.string().matches(MOBILE_REGEX,'Please enter a valid Phone Number.')
    })

    const pwdYupObject = yup.object().shape({
        password:yup.string().required().matches(PASSWORDREGEX,'Password is not valid')
    })

    const [showOTP , setShowOTP] = useState(false);

    const [snackbarError, setSnackBarError] = useState({
        open:false,
        message:'',
        severity:'info'
    })

    const mandatoryProps = [
        'password',
        'confirmPassword'
    ];

    const [formState, setFormState] = useState({});

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const handleToggleConfirmPasswordVisibility = () => {
        setConfirmShowPassword(!confirmshowPassword);
    }

    const loginHandler = async () => {
        try {
            if(otp.length == 0) {
                return;
            }
            const response = await API.get(`/getOTP?mobile_number=${formState['mobile']}`);
            if(response.data.data.length == 0 || response.data.data[0]['OTP'] != otp) {
                let errObj = {
                    open:true,
                    message:'Incorrect OTP. Please try again.'
                }
                setSnackBarError((prevObj) => ({
                    ...prevObj,
                    ...errObj
                }));
                return ; 
            }
            setShowResetPwd(true);
            setShowOTP(false);
        }
        catch(ex) {

        }
    }

    const focusOutHandler = (event) => {
        setFormState((prevObj) => ({
            ...prevObj,
            [event.target.name]:event.target.value
        }))
    }

    const sendOTP = async () => {
        const otp = generateOTP();
        const response = await SNSAPI.post('/sendNotification',{
            notificationType:'SMS',
            messageParams:{
                PhoneNumber:`+91${formState['mobile']}`,
                Message:`Dear Client , ${otp} is your one time password(OTP) which is valid for 15 mins.Hotelio`
            }
        })
        if(response.data.length > 0 && response.data[0].indexOf('FAILURE') == -1) {
            await API.post('/saveOTP',{
                mobile_number:formState['mobile'],
                otp:otp
            }) 
            setShowOTP(true);
        }
    }

    const resendOPT = async () => {
        const otp = generateOTP();
        // const response = await SNSAPI.post('/sendNotification',{
        //     notificationType:'SMS',
        //     messageParams:{
        //         PhoneNumber:`+91${formState['mobile']}`,
        //         Message:`Dear Client , ${otp} is your one time password(OTP) which is valid for 15 mins.Hotelio`
        //     }
        // })
        //if(response.data.length > 0 && response.data[0].indexOf('FAILURE') == -1) {
            await API.post('/saveOTP',{
                mobile_number:formState['mobile'],
                otp:otp
            })
            setSeconds(30) 
            setOtp('');
        //}
    }

    const generateOTPHandler = async () => {
        let errorObj = {};
        let hasError = false;
        if(!formState['mobile'] || formState['mobile'] === ''){
            hasError = true;
            errorObj['mobile'] = true;
        }
        if(hasError) {
            setErrorObj((prevObj) => ({
                ...errorObj  
            }));
            return hasError;
        }

        const empExists = await API.post('/checkIfHotelEmployeeExists',{
            mobile:formState.mobile
        });
        if(empExists.data.count == 0) {
            let obj = {
                mobile:'Mobile number does not exist'
            }
            setErrorObj((prevObj) => ({
                ...obj  
            }));
            return false;
        }

        yupObject.validate(formState,{ abortEarly:false})
        .then((res) => {
            sendOTP();   
        }).catch(err => {
            const errors = err.inner.reduce((acc, error) => {
                return {
                    ...acc,
                    [error.path]: true,
                }
                }, {});
            if(errors['mobile']) {
                hasError = true;
                obj['mobile'] = 'Please enter a valid Mobile Number.';
            }
            if(hasError) {
                setErrorObj((prevObj) => ({
                    ...obj  
                }));
            }
        })
    }

    const generateOTP = () => {
        var digits = '0123456789';
        let OTP = '';
        for (let i = 0; i < 4; i++ ) {
            OTP += digits[Math.floor(Math.random() * 10)];
        }
        return OTP;
    }

    const checkIfError = (name) => {
        if(errorObject[name])// && (!formState[name] || formState[name] == ""))
            return {
                error:true
            }
    }

    const getErrorMessage = () => {
        if(snackbarError.open)
            return snackbarError.message;
    }

    const getTime = () => {
        if(seconds > 0)
            return ` ${seconds}`;
        else 
            return ''
    }

    const resetPassword = async () => {
        try {
            const resp = await API.post('/resetPassword',{
                mobile_number:formState['mobile'],
                password:formState['password']
            });
            // history.push('/login');
            history.push({ 
                pathname: '/login',
                state: 'pwd changed'
            });
        }
        catch(ex) {

        }
    }

    const validateInfo = () => {
        let errorObj = {};
        let hasError = false;
        mandatoryProps.map((prop) => {
            if(!formState[prop] || formState[prop] === ''){
                hasError = true;
                errorObj[prop] = '';
            }
        })
        if(formState['password'] && formState['confirmPassword'] && (formState['password'] != formState['confirmPassword'])) {
            errorObj['password'] = "Passwords don't match. Please re-enter correctly.";
            hasError = true;
        }
        if(hasError) {
            setErrorObj((prevObj) => ({
                ...errorObj  
            }));
            return true;
        }
        //if(!hasError) {
        pwdYupObject.validate(formState,{ abortEarly:false})
        .then((res) => {
            resetPassword();
        }).catch((err) => {
            let obj = {};
            const errors = err.inner.reduce((acc, error) => {
                return {
                    ...acc,
                    [error.path]: true,
                }
                }, {});
            if(errors['password']) {
                hasError = true;
                obj['password'] = 'Password must be at least 8 characters long and should include at least one uppercase letter, one special character (!@#$%^&*), and one digit.';
            }
            if(hasError) {
                setErrorObj((prevObj) => ({
                    ...obj  
                }));
            }
        })
    }

    useEffect(() => {
        if(showOTP) {
            var intervalId = setInterval(() => {
                if(seconds > 0)
                    setSeconds(seconds - 1);
                if(seconds == 0)
                    clearInterval(intervalId);
            },1000);
        }
        return () => {
            clearInterval(intervalId);
        }
    },[seconds,showOTP]);

    const getButtonText = () => {
        if(!showResetPwd && !showOTP) {
            return 'Send OTP'
        }
        else if(!showResetPwd && showOTP)
            return 'Continue'
        else if(showResetPwd)
            return 'Set Password'
    }

    const getHeaderText = () => {
        if(!showResetPwd && !showOTP) {
            return 'Reset Password'
        }
        else if(!showResetPwd && showOTP)
            return 'Enter OTP'
        else if(showResetPwd)
            return 'Set Password'
    }

    const buttonClickHandler = () => {
        if(!showResetPwd && !showOTP) 
            generateOTPHandler();
        else if(!showResetPwd && showOTP) {
            loginHandler();
        }
        else if(showResetPwd) 
            validateInfo();
    }

    return (
        <SUStyle.ParentBox>
            {/* <SUStyle.LeftPanel>

            </SUStyle.LeftPanel> */}
            <SUStyle.RightPanel>
                <SUStyle.LoginHeaderBox>
                    {getHeaderText()}
                </SUStyle.LoginHeaderBox>
                {
                    (!showResetPwd && !showOTP ) &&
                    <SUStyle.DataRow>
                        <SUStyle.Label>
                            Mobile Number<p>*</p>
                        </SUStyle.Label>
                        <SUStyle.InputField
                            { ...checkIfError("mobile")} InputLabelProps={{shrink: false}} size="small"
                            variant="outlined" placeholder="Please Enter Mobile Number" name="mobile"  value = { formState.mobile || ''}
                            onChange = { focusOutHandler}  helperText = { errorObject['mobile'] }
                        ></SUStyle.InputField>
                    </SUStyle.DataRow>
                }
                { (!showResetPwd && showOTP )  && <SUStyle.DataRowOTP>
                        <SUStyle.LoginOTP
                            autoComplete='off' value={otp} onChange={setOtp} { ...checkIfError("password")} InputLabelProps={{shrink: false}} 
                            numInputs={4}  renderInput={(props) => <SUStyle.OTPInputText {...props} />}
                        ></SUStyle.LoginOTP>
                    </SUStyle.DataRowOTP>
                }
                {   showResetPwd &&
                    <>
                        <SUStyle.DataRow>
                            <SUStyle.Label>
                                Password<p>*</p>
                            </SUStyle.Label>
                            <SUStyle.InputField
                                autoComplete='off'
                                { ...checkIfError("password")}
                                InputLabelProps={{shrink: false}} 
                                size="small"
                                variant="outlined" 
                                type={ showPassword ? 'text' : "password" }
                                placeholder="Please Enter Password"
                                name="password"
                                value = { formState.password || ''}
                                onChange = { focusOutHandler}
                                helperText = { errorObject['password'] }
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleTogglePasswordVisibility}>
                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                    ),
                                }}
                            ></SUStyle.InputField>
                        </SUStyle.DataRow>
                        <SUStyle.DataRow>
                            <SUStyle.Label>
                                Confirm Password<p>*</p>
                            </SUStyle.Label>
                            <SUStyle.InputField
                                autoComplete='off'
                                { ...checkIfError("confirmPassword")}
                                InputLabelProps={{shrink: false}} 
                                size="small"
                                variant="outlined" 
                                type={ confirmshowPassword ? 'text' : "password" }
                                placeholder="Please Re-enter Password"
                                name="confirmPassword"
                                value = { formState.confirmPassword || ''}
                                onChange = { focusOutHandler}
                                helperText = { errorObject['confirmPassword'] }
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleToggleConfirmPasswordVisibility}>
                                        {confirmshowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                    ),
                                }}
                            ></SUStyle.InputField>
                        </SUStyle.DataRow>
                    </>
                }
                <SUStyle.DataRow>
                    <SUStyle.SingUPButton
                        variant="contained"
                        size="small"
                        onClick={buttonClickHandler}
                    >
                        {getButtonText()}
                    </SUStyle.SingUPButton>
                </SUStyle.DataRow>
                <SUStyle.OTPBoxRow>
                    <SUStyle.OTPBox>
                    </SUStyle.OTPBox>
                    { !showOTP ?
                        <SUStyle.TCBox>
                            <a href="/login">Log in with Password</a>
                        </SUStyle.TCBox>
                        :
                        <SUStyle.TCBox onClick= { resendOPT } >
                            <p>Resend OTP</p>
                            { seconds > 0 && <p> &nbsp;in</p> }
                            { seconds > 0 && <SUStyle.ResendTime> { getTime() }</SUStyle.ResendTime> }
                        </SUStyle.TCBox>
                    }
                </SUStyle.OTPBoxRow>
                <SUStyle.InvalidCreds>
                    { getErrorMessage() }
                </SUStyle.InvalidCreds>
            </SUStyle.RightPanel>
            {/* <Snackbar open={snackbarError.open} autoHideDuration={3000} severity = { snackbarError.severity } message = {snackbarError.message}>
            </Snackbar> */}
        </SUStyle.ParentBox>
    )
}

export default LoginOTP;