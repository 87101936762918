import React , { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

//Style and Material UI Imports
import * as DashboardStyle from '../../styles/Dashboard/dashboardStyle';

//Internal Imports 
import LeftMenu from '../LeftMenu';
import DashboardHeader from '../DashboardHeader';
import API from '../../api';
import { setHotelPropInfo } from '../../slices/hotelDetails';
import DashboardCards from './DashboardCards';
import DashboardGrid from './DashboardGrid';
import CurrentBookingInfo from './CurrentBookingInfo';
import {
    setRoomSummary
} from '../../slices/dashboardInfo';
import { useHistory } from 'react-router';

const Dashboard = () => {

    const hotelId = useSelector((state) => state.hotelDetails.hotelInfo.hotelId);
    const dispatch = useDispatch();

    const history = useHistory();

    let date = dayjs(new Date().toISOString()).format("YYYY-MM-DD");

    useEffect(() => {
        if(hotelId == -1){
            history.push('/dashboardlogin');
            return;
        }

        let promiseArr = [];
        promiseArr.push(API.get(`/getHotelDetailsById?hotel_id=${hotelId}`));
        promiseArr.push(API.get(`/getPendingCheckIn?hotel_id=${hotelId}&date=${date}`));
        promiseArr.push(API.get(`/getCheckedInInfo?hotel_id=${hotelId}&date=${date}`));
        promiseArr.push(API.get(`/getCheckoutInfo?hotel_id=${hotelId}&date=${date}`));
        promiseArr.push(API.get(`/getCancelledInfo?hotel_id=${hotelId}&date=${date}`));
        promiseArr.push(API.get(`/getNoShowInfo?hotel_id=${hotelId}&date=${date}`));
       
        Promise.all(promiseArr).then((res) => {
            let propertyDetails = res[0].data.data[0];
            let pendingCheckIns = res[1].data.data;
            let checkIns = res[2].data.data;
            let checkOuts = res[3].data.data;
            let cancelledBookings = res[4].data.data;
            let noShows = res[5].data.data;
            dispatch(setHotelPropInfo({
                propertyDetails
            }));
            dispatch(setRoomSummary({
                roomSummary:{
                    pendingCheckIns,
                    checkIns,
                    checkOuts,
                    cancelledBookings,
                    noShows
                }
            }));
        });
    },[])

    return (
        <DashboardStyle.DashboardParent>
             <DashboardHeader />
             <DashboardStyle.DashboardBody>
                <DashboardStyle.DashboardLeftSection>
                    <LeftMenu />
                </DashboardStyle.DashboardLeftSection>
                <DashboardStyle.DashboardRightSection>
                    <CurrentBookingInfo />
                    {/* <DashboardCards /> */}
                    <DashboardGrid />
                </DashboardStyle.DashboardRightSection>
            </DashboardStyle.DashboardBody>
        </DashboardStyle.DashboardParent>
    )
}

export default Dashboard;