import React from 'react';
import { useDispatch } from 'react-redux';

//Style and MUI Imports;
import * as RDetailStyle from '../../../styles/HotelInfo/roomDetailsStyle';

//internal Imports
import { deleteHotelRoom, setEditRoomInfo } from '../../../slices/hotelDetails';

const AddedRooms = ({ roomDetails,roomCategories }) => {

    const dispatch = useDispatch()

    const generateAddedRooms = () => {
        let addedRooms = [];
        let roomCatName = '';
        for(var o in roomDetails) {
            let currentRoomInfo = roomDetails[o];
            //roomCatName = roomCategories.find(room => room.code == currentRoomInfo.roomCategory).label;
            addedRooms.push(
                <RDetailStyle.ARGridItem item sm = {4} xs = {12}>
                    <RDetailStyle.GridItemBox>
                        <RDetailStyle.ARName>
                            { currentRoomInfo.roomCategoryName }
                        </RDetailStyle.ARName>
                        <RDetailStyle.AREditIcon onClick = { event => editRoomInfoHandler(currentRoomInfo) }></RDetailStyle.AREditIcon>
                        <RDetailStyle.ARDeleteIcon onClick = {(event) => deleteRoomHandler(o) }></RDetailStyle.ARDeleteIcon>
                    </RDetailStyle.GridItemBox> 
                </RDetailStyle.ARGridItem>
            )
        }
        return addedRooms;
    }

    const deleteRoomHandler = (roomCategory) => {
        let newRoomDetails = structuredClone(roomDetails);
        delete newRoomDetails[roomCategory];
        dispatch(deleteHotelRoom(newRoomDetails));
    }

    const editRoomInfoHandler = (roomInfo) => {
        let editRoomInfo = { ...roomInfo };
        dispatch(setEditRoomInfo(editRoomInfo));
    }

    return(
        <RDetailStyle.AddedRoomsContainer>
            {   Object.keys(roomDetails).length > 0 &&
                <RDetailStyle.AddedRoomsLabel>Rooms Added</RDetailStyle.AddedRoomsLabel>
            }
            <RDetailStyle.ARGridContainer>
                <RDetailStyle.ARGrid container spacing={2}>
                    { generateAddedRooms() }
                </RDetailStyle.ARGrid>
            </RDetailStyle.ARGridContainer>
        </RDetailStyle.AddedRoomsContainer>
    )
}

export default AddedRooms;