import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

// Mui and Sytle js
import * as CDStyles from '../../../styles/HotelInfo/contactDetailsStyle';
import * as RuleStyle from '../../../styles/HotelInfo/hotelRulesStyle';
import * as HSStyles from '../../../styles/HotelInfo/hourlyStayStyle';

//Internal Imports
import SaveDetails from '../SaveDetails';
import HourlyStayInfo from './HourlyStayInfo';
import { 
    setActiveTab,
    setActiveTabAndRoomDetails
 } from '../../../slices/hotelDetails';
 import { 
    ROOMONLY
 } from '../../../config/constants';
 import API from '../../../api';

const HourlyStay = () => {

    const dispatch = useDispatch();

    const hotelStayRef = useRef();

    const activeTab = useSelector(state => {
        return state.hotelDetails.activeTab;
    })

    let hotelId = useSelector(state => state.hotelDetails.hotelInfo.hotelId);

    const moveToPrevTab = () => {
        dispatch(setActiveTab(activeTab - 1))
    }

    const roomDetails = useSelector(state => state.hotelDetails.hotelInfo.roomDetails);

    const validateInfo = (tarrifObj,hasHourlyStay,duration) => {
        let errorObj = {};
        if(tarrifObj.stay3) {
            if(tarrifObj.tarrif3 == null || tarrifObj.tarrif3?.singleTarrif == null || tarrifObj.tarrif3?.singleTarrif == undefined)
                errorObj['singleTarrif3'] = 'Please enter Room Tarrif';
            if(tarrifObj.tarrif3 == null || tarrifObj.tarrif3?.doubleTarrif == null || tarrifObj.tarrif3?.doubleTarrif == undefined)
                errorObj['doubleTarrif3'] = 'Please enter Room Tarrif';
            if(tarrifObj.tarrif3 == null || tarrifObj.tarrif3?.tripleTarrif == null || tarrifObj.tarrif3?.tripleTarrif == undefined)
                errorObj['tripleTarrif3'] = 'Please enter Room Tarrif';
        }
        if(tarrifObj.stay6) {
            if(tarrifObj.tarrif6 == null || tarrifObj.tarrif6?.singleTarrif == null || tarrifObj.tarrif6?.singleTarrif == undefined)
                errorObj['singleTarrif6'] = 'Please enter Room Tarrif';
            if(tarrifObj.tarrif6 == null || tarrifObj.tarrif6?.doubleTarrif == null || tarrifObj.tarrif6?.doubleTarrif == undefined)
                errorObj['doubleTarrif6'] = 'Please enter Room Tarrif';
            if(tarrifObj.tarrif6 == null || tarrifObj.tarrif6?.tripleTarrif == null || tarrifObj.tarrif6?.tripleTarrif == undefined)
                errorObj['tripleTarrif6'] = 'Please enter Room Tarrif';
        }

        if(tarrifObj.stay9) {
            if(tarrifObj.tarrif9 == null || tarrifObj.tarrif9?.singleTarrif == null || tarrifObj.tarrif9?.singleTarrif == undefined)
                errorObj['singleTarrif9'] = 'Please enter Room Tarrif';
            if(tarrifObj.tarrif9 == null || tarrifObj.tarrif9?.doubleTarrif == null || tarrifObj.tarrif9?.doubleTarrif == undefined)
                errorObj['doubleTarrif9'] = 'Please enter Room Tarrif';
            if(tarrifObj.tarrif9 == null || tarrifObj.tarrif9?.tripleTarrif == null || tarrifObj.tarrif9?.tripleTarrif == undefined)
                errorObj['tripleTarrif9'] = 'Please enter Room Tarrif';
        }
        hotelStayRef.current.setErrorObj(errorObj);
        if(Object.keys(errorObj).length == 0) 
            saveData(tarrifObj,hasHourlyStay);
    }

    const saveData = (tarrifObj,hasHourlyStay,duration) => {
        try {
            let roomCategoryInfo = [];
            for(var o in roomDetails) {
                let room = roomDetails[o];
                let priceObj = {...room.attributes};
                let hourlyStayObj = {
                    hasHourlyStay,
                    tarrifObj,
                    duration
                };
                priceObj.hourlyStayObj = hourlyStayObj; 
                roomCategoryInfo.push({
                    bed_type_id:1,
                    categoryName:room.roomCategoryName,
                    hotel_room_type_id:room.roomCategory,
                    hotel_room_type_category_code:ROOMONLY,
                    maximum_occupancy:room.maxOcc || 1,
                    minimum_occupancy:room.minOcc || 1,
                    description:room.roomDesc || '',
                    imagesList: room.imagesList || [],
                    description: room.roomDesc || '',
                    single_price: room.price,
                    double_price: room.doubleOccupancyPrice,
                    triple_price: room.extraBedPrice,
                    attributes:JSON.stringify(priceObj)
                });   
            }
            let promiseArr = [];
            roomCategoryInfo.map(roomCat => {
                promiseArr.push(API.post('/addHotelRoomCategories',roomCat))
            });
            if(promiseArr.length > 0){
                Promise.all(promiseArr).then(() => {
                    API.post('/updateHotelSetupState',{
                        setup_state:(activeTab + 1),
                        hotel_id:hotelId
                    }).then((resp) => {
                        let newRoomDetails = structuredClone(roomDetails);
                        for(var o in roomCategoryInfo) {
                            newRoomDetails[roomCategoryInfo[o].categoryName].attributes = JSON.parse(roomCategoryInfo[o].attributes);
                        }
                        dispatch(setActiveTabAndRoomDetails({
                            activeTab:activeTab + 1,
                            roomDetails:newRoomDetails
                        }));

                    });
                    
                })
            }
        }
        catch(ex) {
            console.log(ex);
        }
    }

    const moveToTabNextTab = async () => {
        let childState = { ...hotelStayRef.current.getTarrifObj() };
        validateInfo(childState.tarrifObj, childState.hasHourlyStay);
    }

    return (
        <>
           <RuleStyle.RuleParent>
            <HSStyles.RulesBox>
                <CDStyles.DetailHeader>Hourly Stays</CDStyles.DetailHeader>
                <HourlyStayInfo ref = { hotelStayRef } />
            </HSStyles.RulesBox>
            <SaveDetails showBackButton = {true} nextTabHandler = { moveToTabNextTab} prevTabHandler = {moveToPrevTab}/>
        </RuleStyle.RuleParent>
        </>
    )
}

export default HourlyStay;