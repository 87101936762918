import styled from 'styled-components';
import Box from '@mui/material/Box';
import StepConnector, {  stepConnectorClasses } from "@mui/material/StepConnector";

export const StepIconConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 15,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)"
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#28A745"
      }
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#28A745"
      }
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:"#D1D5DB",
      borderTopWidth: 3,
      borderRadius: 1
    }
  }));
  
  export const StepIconRoot = styled("div")(
    ({ theme, ownerState }) => ({
      color: "#eaeaf0",
      display: "flex",
      height: 32,
      width: 32,
      alignItems: "center",
      ...(ownerState.active && {
        color: "#784af4"
      }),
      "& .QontoStepIcon-completedIcon": {
        color: "#white",
        zIndex: 1,
        fontSize: 18,
        backgroundColor: "#28A745",
        borderRadius: "50%",
        padding: "5px"
      },
      "& .QontoStepIcon-circle": {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "#D1D5DB"
      },
      "& .QontoStepIcon-circle-parent": {
        border: "1px solid #D1D5DB",
        borderRadius: "50%",
        padding: "10px"
      },
      "& .QontoStepIcon-activecircle": {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "#084298"
      },
      "& .QontoStepIcon-activecircle-parent": {
        border: "1px solid #084298",
        borderRadius: "50%",
        padding: "10px"
      }
    })
  );


  export const StepperBox = styled(Box)`
  && {
    width:100%;
    & .Mui-completed {
      background:black;
    }
    & .MuiStepConnector-root {
      display:none;
    }
    & .MuiStepLabel-root {
      display:none;
    }
    & .MuiStep-root {
      height:6px;
    }
    & .MuiStepper-root {
      height:6px;
      background:rgb(221, 221, 221) !important;
    }
  }`