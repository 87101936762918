import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

//MUI and style imports
import * as RuleStyle from '../../../styles/HotelInfo/hotelRulesStyle';
import * as CDStyles from '../../../styles/HotelInfo/contactDetailsStyle';

//Internal Imports
import HotelRulesList from './HotelRulesList';
import SaveDetails from '../SaveDetails';
import { 
    setActiveTab
 } from '../../../slices/hotelDetails';
 import API from '../../../api';

const HotelRules = () => {

    const hotelRulesInfo = useSelector(state => {
        return {
            rulesMaster:state.hotelDetails.hotelInfo.hotelRulesMaster,
            rules:state.hotelDetails.hotelInfo.hotelRules
        }
    });

    const checkOutAttrs = useSelector(state => {
        return { ...state.hotelDetails.hotelInfo.propertyDetails.attributes }
    });

    const hotelId = useSelector(state => state.hotelDetails.hotelInfo.hotelId);

    const dispatch = useDispatch();

    const activeTab = useSelector(state => {
        return state.hotelDetails.activeTab;
    })

    const moveToPrevTab = () => {
        dispatch(setActiveTab(activeTab - 1))
    }

    const moveToTabNextTab = async () => {
        console.log('test');
        try {
            if(hotelRulesInfo.rules.length > 0) {
                let rulesList = []
                hotelRulesInfo.rules.map(rule => {
                    rulesList.push({
                        ...rule,
                        hotel_id:hotelId
                    })
                })
                const resp = await API.post('/addRulesForHotel',rulesList);
            }

            if(checkOutAttrs['check-in'] == null) {
                const defaultNoonTime = new Date();
                defaultNoonTime.setHours(12);
                defaultNoonTime.setMinutes(0);
                defaultNoonTime.setSeconds(0);
                defaultNoonTime.setMilliseconds(0);
                checkOutAttrs['check-in'] = defaultNoonTime;
            }
            if(checkOutAttrs['check-out'] == null) {
                const defaultNoonTime = new Date();
                defaultNoonTime.setHours(12);
                defaultNoonTime.setMinutes(0);
                defaultNoonTime.setSeconds(0);
                defaultNoonTime.setMilliseconds(0);
                checkOutAttrs['check-out'] = defaultNoonTime;
            }
            const response = await API.post('/updateCheckoutTime',{
                attributes:JSON.stringify(checkOutAttrs),
                hotel_id:hotelId
            });
            await API.post('/updateHotelSetupState',{
                setup_state:(activeTab + 1),
                hotel_id:hotelId
            });
            dispatch(setActiveTab(activeTab + 1));
        }
        catch(ex) {
            console.log(ex);
            var a = 1;
        }
    }

    return (
        <RuleStyle.RuleParent>
            <RuleStyle.RulesBox>
                <CDStyles.DetailHeader>Property Rules</CDStyles.DetailHeader>
                <HotelRulesList ruleMaster = { hotelRulesInfo.rulesMaster } rules = { hotelRulesInfo.rules } />
            </RuleStyle.RulesBox>
            <SaveDetails showBackButton = {true} nextTabHandler = { moveToTabNextTab} prevTabHandler = {moveToPrevTab}/>
        </RuleStyle.RuleParent>
    )
}

export default HotelRules;