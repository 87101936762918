import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

//MUI and style Imports
import * as RuleStyle from '../../../styles/HotelInfo/hotelRulesStyle';

//Internal Imports
import {
    setHotelRules,
    setHotelCheckOutTime
} from '../../../slices/hotelDetails';


const HotelRulesList = (props) => {

    const dispatch = useDispatch();

    const existingRules = props.rules.map(rule => rule.rule_description);

    const hotelId = useSelector(state => state.hotelDetails.hotelInfo.hotelId);

    const checkOutAttrs = useSelector(state => state.hotelDetails.hotelInfo.propertyDetails.attributes);

    const getCheckInTIme = () => {
        const defaultNoonTime = new Date();
        defaultNoonTime.setHours(12);
        defaultNoonTime.setMinutes(0);
        defaultNoonTime.setSeconds(0);
        defaultNoonTime.setMilliseconds(0);
        if(checkOutAttrs) {
            return dayjs(checkOutAttrs['check-in'] || defaultNoonTime);
        }
        else 
            return dayjs(defaultNoonTime);
    }

    const getCheckOutTIme = () => {
        const defaultNoonTime = new Date();
        defaultNoonTime.setHours(11);
        defaultNoonTime.setMinutes(0);
        defaultNoonTime.setSeconds(0);
        defaultNoonTime.setMilliseconds(0);
        if(checkOutAttrs) {
            return dayjs(checkOutAttrs['check-out'] || defaultNoonTime);
        }
        else 
            return dayjs(defaultNoonTime);
    }

    const toggleHandler = (checked,rule) => {
        let obj = {
            hotel_id:hotelId,
            rule_description:rule.rule_desc
        }
        dispatch(setHotelRules({
            checked,
            obj
        }))
    }

    const checkInTimeHandler = (newValue) => {
        dispatch(setHotelCheckOutTime({
            name:'check-in',
            value:dayjs(newValue).format('YYYY-MM-DD hh:mm A')
        }));
    }

    const checkOutTimeHandler = (newValue) => {
        dispatch(setHotelCheckOutTime({
            name:'check-out',            
            value:dayjs(newValue).format('YYYY-MM-DD hh:mm A')
        }));
    }

    const getRulesList = () => {
        let list = [];
        props.ruleMaster.map(rule => {
            if(existingRules.indexOf(rule.rule_desc) > -1 ) {
                list.push(
                    <RuleStyle.RuleIDParent item xs = {12} lg = {4}>
                        <RuleStyle.RuleIDBox>
                            <RuleStyle.RuleLabel > { rule.rule_desc }</RuleStyle.RuleLabel>
                            <RuleStyle.RuleSwitch onChange = {(checked) => { toggleHandler(checked,rule) }} checked = { true } ></RuleStyle.RuleSwitch>
                        </RuleStyle.RuleIDBox>
                    </RuleStyle.RuleIDParent>
                )
            }
            else {
                list.push(
                    <RuleStyle.RuleIDParent item xs = {12} lg = {4}>
                        <RuleStyle.RuleIDBox>
                            <RuleStyle.RuleLabel> { rule.rule_desc }</RuleStyle.RuleLabel>
                            <RuleStyle.RuleSwitch onChange = {(checked) => { toggleHandler(checked,rule) }} checked = { false } ></RuleStyle.RuleSwitch>
                        </RuleStyle.RuleIDBox>
                    </RuleStyle.RuleIDParent>
                )
            }
        })
        return list;
    }

    return (
        <RuleStyle.RulesListBox>
            <RuleStyle.CheckInOutTime>
                <RuleStyle.CheckOutLabel>Enter standard check in - check out time</RuleStyle.CheckOutLabel>
                <RuleStyle.CheckTimePicker views={['hours']} ampm = 'true' onChange={ checkInTimeHandler } value = { getCheckInTIme() } size = 'small'></RuleStyle.CheckTimePicker>
                <RuleStyle.CheckTimePicker views={['hours']} ampm = 'true' onChange={ checkOutTimeHandler } value = { getCheckOutTIme() } size = 'small'></RuleStyle.CheckTimePicker>
            </RuleStyle.CheckInOutTime>
            <RuleStyle.RuleListParent container spacing = {2}>
                { getRulesList() }
            </RuleStyle.RuleListParent>
        </RuleStyle.RulesListBox>
    )
}

export default HotelRulesList;