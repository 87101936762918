import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useState } from 'react';

//Mui and Style Imports 
import * as AGGridStyle from '../../styles/DataGrid/aggridStyle.js';

const AGGridWrapper = ({rowData,colDefs}) => {
    return (
        <AGGridStyle.AGGridWrapperBox className = {
            "ag-theme-quartz"
          }
        >
          <AgGridReact rowData={rowData} columnDefs={colDefs} />
        </AGGridStyle.AGGridWrapperBox>
      );
}

export default AGGridWrapper;