import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import './styles/appStyle.css';


ReactDOM.render(
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <App/>
    </LocalizationProvider>,
    document.querySelector("#root")
);
  